<template>
  <div class="video_left">
    <div class="left_content scroll_bar">
      <div class="video_nav">
        <div class="video_nav_item" :class="{ active: navInfo.value == item.value }" v-for="item in navList"
          :key="item.value" @click="navChange(item)">
          <div class="img">
            <img :src="item.icon" alt="">
          </div>
          <div class="title">{{ item.label }}</div>
        </div>
      </div>
    </div>
    <templates-list :navInfo="navInfo" v-if="navInfo.value == 'template'"></templates-list>
    <models-list :navInfo="navInfo" v-if="navInfo.value == 'model'"></models-list>
    <powerpoint-list :navInfo="navInfo" v-if="navInfo.value == 'ppt'"></powerpoint-list>
    <word-list :navInfo="navInfo" v-if="navInfo.value == 'word'"></word-list>

  </div>
</template>

<script>
import { mapState } from "vuex"
import TemplatesList from './template'
import ModelsList from './model'
import PowerpointList from './powerpoint'
import WordList from './worddoc'

export default {
  components: {
    TemplatesList,
    ModelsList,
    PowerpointList,
    WordList,
  },
  data() {
    return {
      navList: [{
        label: '模版',
        value: 'template',
        navFlag: true,
        icon: require('@/assets/images/createvideo/left_nav_(1).png'),
      }, {
        label: '模特',
        value: 'model',
        navFlag: true,
        icon: require('@/assets/images/createvideo/left_nav_(2).png'),
      }, {
        label: 'PPT',
        value: 'ppt',
        navFlag: true,
        icon: require('@/assets/images/createvideo/left_nav_(3).png'),
      }, {
        label: 'Word',
        value: 'word',
        navFlag: true,

        icon: require('@/assets/images/createvideo/left_nav_(4).png'),
      }
      ],
    }
  },
  computed: {
    ...mapState({
      navInfo: state => state.videoCreate.leftNavInfo,
    }),
  },
  methods: {
    navChange(item) {
      this.$store.commit('videoCreate/SET_LEFT_NAV_INFO', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.video_left {
  width: 100%;
  height: 100%;
}

.left_content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  // width: 100%;
  width: 70px;
  height: 100%;
  background: #1B1D1F;
  overflow: auto;

  .video_nav {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    background: #1B1D1F;

    .video_nav_item {
      width: 70px;
      height: 80px;
      text-align: center;
      cursor: pointer;
      padding: 15px 0;

      .title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 3px;
      }

      .img {
        width: 36px;
        height: 36px;
        margin: 0 auto;

        img {
          width: 100%;
        }
      }
    }

    .video_nav_item.active {
      background: #283038;
    }
  }
}
</style>
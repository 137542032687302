<template>
    <div class="model nav_left_table_wrap">
        <div class="video_table" :class="!navInfo.value == 'model' ? 'video_table_close' : ''">
            <!-- 标题 -->
            <div class="table_title">
                <div class="title">{{ navInfo.label }}</div>
                <i class="el-icon-close" @click="close"></i>
            </div>
            <div class="table_content">
                <!-- 下方的筛选项目连带搜索 -->
                <div class="search-box">
                    <div class="select_box">
                        <el-select popper-class="select_popper_dark" v-model="category" placeholder="请选择" size="small" @change="selectBtn">
                            <el-option v-for="item in category_list" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search_inp">
                        <el-input placeholder="请输入关键字" prefix-icon="el-icon-search" size="small" v-model="name" clearable @clear="reset" @keyup.enter.native="reset">
                        </el-input>
                    </div>

                </div>
                <!-- tab切换 -->
                <div class="tabs mt20">
                    <div class="item" :class="{ 'active': tabValue == item.value }" v-for="(item, idx) in tabs" :key="idx" @click="tabsChange(item)">
                        <div class="txt">{{ item.label }}</div>
                        <div class="bar"></div>
                    </div>
                </div>
                <!-- 筛选项 -->
                <div class="filter_box mt10" v-if="tabValue == 3">
                    <div class="left">
                        <div class="item" :class="{ 'active': type == item.value }" v-for="(item, i) in type_list" :key="i" @click="typeChange(item.value)">
                            {{ item.label }}
                        </div>
                    </div>
                </div>
                <!-- 列表 -->
                <div class="model_list">
                    <div class="contentbox scroll_bar" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="list">
                            <div class="item" :class="{ 'active': item.id == currentModel }" v-for="(item, x) in list" :key="x" @click="selectModel(item)">
                                <div class="item-pic">
                                    <div class="picbox">
                                        <el-image style="width: 360px; height: 190px; border-radius: 2px;" :src='item.thumbnail' fit="contain">
                                            <!-- <div slot="error" class="image-slot">
                                                <img src="@/assets/images/icon/img.png" alt="">
                                            </div> -->
                                        </el-image>
                                    </div>
                                    <!-- 角标-推荐时显示 -->
                                    <div class="tag-box" v-if="tabValue == 1">
                                        <!-- vip -->
                                        <img v-if="item.is_vip == 1" src="@/assets/images/createvideo/model_(1).png" alt="">
                                        <!-- 付费 -->
                                        <img v-else-if="item.is_free == 1" src="@/assets/images/createvideo/model_(2).png" alt="">
                                    </div>
                                    <!-- 角标-我的时显示 -->
                                    <div class="tag-box" v-if="tabValue == 3">
                                        <!-- vip -->
                                        <img v-if="item.is_vip == 1" src="@/assets/images/createvideo/model_(1).png" alt="">
                                    </div>
                                    <div class="bottom-box">
                                        <div class="name">{{ item.name }}</div>
                                        <!-- 推荐-显示 -->
                                        <div class="insert" v-if="tabValue == 1">
                                            <div class="button" @click="selectTemp(item)">购买</div>
                                        </div>
                                        <!-- 最近使用-显示 -->
                                        <div class="des" v-if="tabValue == 2">限时免费</div>
                                        <!-- 我的-显示 -->
                                        <div class="des" v-if="tabValue == 3">{{item.expiry_date}}到期</div>

                                    </div>
                                </div>
                                <div class="item-scene" v-if="item.id == currentModel">
                                    <el-carousel trigger="click" height="160px" :autoplay="false" indicator-position="none">
                                        <el-carousel-item class="carousel" v-for="(item, index) in returnSwiper" :key="index">
                                            <div class="item-img" v-for="(ite, i) in item" :key="i" @click="selectDthuman(ite)">
                                                <div class="item-box">
                                                    <img class="img" :src="ite.thumbnail" alt="">
                                                    <div class="title">{{ ite.scene_name }}</div>
                                                </div>
                                            </div>
                                        </el-carousel-item>
                                    </el-carousel>
                                </div>
                            </div>
                        </div>
                        <!-- 没有更多 -->
                        <Loading :loading="loading" :list="list" :page="page" :totalPage="last_page">
                        </Loading>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    props: {
        navInfo: {
            type: Object,
            default: () => { }
        },
    },
    components: {
        PointOut: resolve => require(['@/components/point_out/point_out'], resolve),
        Loading: (resolve) => require(['@/components/loading/index'], resolve)
    },
    data() {
        return {
            tabs: [
                {
                    label: '推荐',
                    value: '1'
                },
                {
                    label: '最近使用',
                    value: '2'
                },
                {
                    label: '我的',
                    value: '3'
                },
            ],
            tabValue: '1',
            name: '',
            category: '', // 下拉框选定的值
            category_list: [], // 分类列表
            type: 3, // 下拉框选定的值
            type_list: [
                {
                    label: 'VIP',
                    value: 1,
                },
                {
                    label: '定制',
                    value: 2,
                },
                {
                    label: '免费',
                    value: 3,
                },
            ], // 类型列表
            loading: false,
            page: 0,
            last_page: 1, // 总页数
            limit: 20,
            list: [],
            currentModel: '',
            modelScene: [], // 场景
            modelName: '', //选中模特名称
        }
    },
    created() {
        this.getThemeType()
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            sizeRatio: state => state.videoCreate.sizeRatio,//宽高比2=9:16 1=16:9
        }),
        // 处理返回数据的格式，如果长度是偶数的话就/2,奇数的话就取余+1
        returnSwiper() {
            return this.group(
                this.modelScene,
                this.modelScene.length % 2 == 0 ? this.modelScene.length / 2 : (this.modelScene.length % 2) + 1
            )
        }
    },
    methods: {
        // 关闭
        close() {
            let obj = {}
            this.$store.commit('videoCreate/SET_LEFT_NAV_INFO', obj)
        },
        // 分类
        selectBtn() {
            this.reset()
        },
        // tab切换-分类
        tabsChange(item) {
            this.tabValue = item.value
            this.reset()
        },
        // 类型
        typeChange(val) {
            this.type = val
            this.reset()
        },
        // 加载更多
        load() {
            if (this.loading || this.page === this.last_page || this.last_page === 0) return
            this.page++
            this.getList()
        },
        // 重置
        reset() {
            this.list = []
            this.page = 1
            this.getList()
        },
        // 获取主题分类
        getThemeType() {
            this.$digitalApi.modelParams().then(res => {
                if (res.code == 1000) {
                    this.category_list = res.result.category
                    this.category_list.unshift({
                        value: '',
                        label: '全部'
                    })
                }
            })
        },
        // 列表
        getList() {

            let obj = {
                page: this.page,
                limit: this.limit,
                category: this.category,
                name: this.name,
            }

            if (this.tabValue == 1) {
                // 推荐
                this.loading = true

                this.$workbenApi.videoModelIndex(obj).then(res => {
                    this.loading = false
                    if (res.code == 1000) {
                        this.list = [...this.list, ...res?.result?.data] || []
                        this.last_page = res?.result?.last_page
                        this.page = res?.result?.current_page ?? 1
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }
            if (this.tabValue == 2) {
                // 最近使用
                this.loading = true
                this.$workbenApi.videoModelRecently(obj).then(res => {
                    this.loading = false
                    if (res.code == 1000) {
                        this.list = res.result
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }
            if (this.tabValue == 3) {
                // 我的
                this.loading = true
                obj.cvf = this.type
                this.$workbenApi.videoModelMine(obj).then(res => {
                    this.loading = false
                    if (res.code == 1000) {
                        this.list = [...this.list, ...res?.result?.data] || []
                        this.last_page = res?.result?.last_page
                        this.page = res?.result?.current_page ?? 1
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }
        },
        // 替换
        selectTemp(item) {
            // this.$refs.point.openDialogBtn()
        },
        // 确认替换回调
        confirmBtn() { },
        // 选择模特
        selectModel(item) {
            this.currentModel = item.id
            this.modelName = item.name
            if (this.tabValue == 2) {
                this.$workbenApi.modelListScence(
                    {
                        type: item.type,
                        model_id: item.id,
                    }
                ).then(res => {
                    if (res.code == 1000) {
                        this.modelScene = res.result.data
                    }
                })
            } else {
                this.modelScene = item.modelScene
            }
        },
        // 将数组处理成嵌套数组的形式
        group(array, subGroupLength) {
            let index = 0;
            let newArray = [];
            if (array.length == 2) {
                newArray.push(array.slice(index, 2));
            } else {
                while (index < array.length) {
                    newArray.push(array.slice(index, (index += subGroupLength)));
                }
            }
            return newArray;
        },
        selectDthuman(info) {
            var arr = JSON.parse(JSON.stringify(this.sceneList))
            var idx = this.sceneIndex
            var scenes = arr[idx].scenes_conf || []
            var obj = {
                width: this.sizeRatio == 1 ? 594 : 1080,
                height: this.sizeRatio == 1 ? 1056 : 1920,
                left: this.sizeRatio == 1 ? 663 : 0,
                top: this.sizeRatio == 1 ? 24 : 0,
            }
            var str = {
                id: new Date().getTime(),
                type: 'dthuman',
                name: this.modelName,
                video_url: info.video_url,
                url: info.thumbnail,
                model_id: info.model_id,
                lock: false,
                style: obj
            }
            var imgDthuman = scenes.find(item => item.type == 'dthuman')
            var anchorIndex = scenes.findIndex(item => item.type == 'dthuman')
            if (scenes.length) {
                if (imgDthuman) {
                    scenes[anchorIndex] = str
                } else {
                    scenes.unshift(str)
                }
            } else {
                scenes.push(str)
            }
            var params = {
                type: 'conf',
                info: scenes
            }
            this.$store.dispatch('videoData/modifyProduct', params)
        },
    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";

.contentbox {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 360px);
    // margin-right: -8px;
}

// 更多模板
.search-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .select_box {
        width: 88px;
        background: #5c6470;
        border-radius: 4px 0px 0px 4px;

        ::v-deep .el-select {
            .el-input__inner {
                border-radius: 4px 0px 0px 4px;
                background: #5c6470;
                border-color: transparent;
                color: #ffffff;
                padding: 0 0 0 6px;
            }

            .el-input__suffix {
                right: 0px;
            }
        }
    }

    .search_inp {
        flex: 1;

        ::v-deep .el-input__inner {
            color: #ffffff;
            background: #272c32;
            border-radius: 0px 4px 4px 0px;
            border-color: transparent;
        }
    }
}

.tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #454a50;

    .item {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .txt {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 400;
            color: #ffffff;
        }

        .bar {
            width: 28px;
            height: 2px;
            background: transparent;
            border-radius: 1px;
            margin-top: 5px;
        }
    }

    .active {
        .txt {
            font-weight: 500;
        }

        .bar {
            background: #ffffff;
        }
    }
}

.filter_box {
    display: flex;
    justify-content: space-between;

    .left {
        display: flex;

        .item {
            margin-right: 10px;
            width: 46px;
            line-height: 24px;
            background: #3c4148;
            border-radius: 2px;
            text-align: center;
            cursor: pointer;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
        }

        .active {
            background: #252f69;
            border: 1px solid #2e4bf2;
        }
    }
}

/deep/ .el-carousel__arrow {
    width: 20px;
    height: 20px;
    background: rgba($color: #000000, $alpha: 0.2);
}

/deep/ .el-carousel__arrow--left {
    left: 10px;
}

/deep/ .el-carousel__arrow--right {
    right: 10px;
}
</style>
<template>
    <div class="creat-audio" v-loading="audioLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
        <div class="c-a-t">
            <div class="c-a-t-l">讲解内容</div>
            <div class="c-a-t-r">
                <voice-popup @confirm="confirm"></voice-popup>
                <span>字幕</span>
                <el-switch v-model="subtitleShow" @change="subtitleChange" active-color="#2B4AFE" inactive-color="#DCDFE6">
                </el-switch>
            </div>
        </div>
        <div class="c-a-m" v-if="ttsType==1">
            <div class="text-input">
                <div id="textinput" class="text-input-c" ref="editor" contenteditable="true" @input="input" @compositionstart="compositStart" @compositionend="compositEnd" @paste="copyText" v-html="content"></div>
                <div class="total">{{textSize}}/20000</div>
            </div>
        </div>
        <div class="c-a-m" v-if="ttsType==2 || ttsType==3">
            <div class="upload-box">
                <el-upload class="avatar-uploader" action="#" accept="audio/wav,audio/mp3,audio/m4a,audio/x-m4a" :show-file-list="false" :http-request="uploadSuccess">
                    <el-button type="primary" size="small">上传录音
                        <el-tooltip effect="dark" placement="top">
                            <div slot="content">
                                支持mp3、wav、m4a文件，单个录音时长小于1小时<br />
                                请上传纯干音文件，背景音、噪声会影响视频合成效果哦~
                            </div>
                            <img class="img el-icon--right" src="@/assets/images/editor/icon2.png" alt="">
                        </el-tooltip>
                    </el-button>
                </el-upload>
            </div>
        </div>
        <div class="c-a-b">
            <div class="c-a-b-l">
                <span>读音检查</span>
                <el-tooltip effect="dark" placement="top">
                    <div slot="content">点击后将自动高亮数字、多音字，右击高亮文字后可<br />以编辑读法，标记读音；若要编辑未高亮的文字，选<br />中文字即可编辑</div>
                    <img src="@/assets/images/editor/icon2.png" alt="">
                </el-tooltip>
                <el-switch v-model="value2" active-color="#2B4AFE" inactive-color="#DCDFE6">
                </el-switch>
                <span class="pdl30">页面停留</span>
                <div class="input"><el-input class="audio" size="mini" v-model="time" placeholder=""></el-input></div>
                <span>秒</span>
                <el-tooltip effect="dark" placement="top">
                    <div slot="content">表示数字人讲解结束后，当前页面停留的时长；<br />若讲解内容为空，当前页的时长以停留时长为准；</div>
                    <img src="@/assets/images/editor/icon2.png" alt="">
                </el-tooltip>
            </div>
            <div class="c-a-b-r">
                <span>局部试听</span>
                <el-tooltip effect="dark" content="选中部分文字，右击可以进行局部试听哦~" placement="top">
                    <img src="@/assets/images/editor/icon2.png" alt="">
                </el-tooltip>
                <div class="btn" @click="syntheticAudio">试听</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import VoicePopup from '../../components/voicePopup/index.vue'
export default {
    components: {
        VoicePopup
    },
    props: {

    },
    data() {
        return {
            audioLoading: false,
            value1: false,
            value2: false,
            time: 0,
            acceptList: ['audio/wav', 'audio/mp3', 'audio/m4a', 'audio/x-m4a', 'audio/mpeg'],
            textSize: 0,
            lock: true,
            fullContent: '',
            content: '',   // 文本数据       
        };
    },
    computed: {
        ...mapState({
            default_tts: state => state.voiceInfo.default_tts,
            sceneList: state => state.videoData.sceneList,
            sceneIndex: state => state.videoData.sceneIndex,
            ttsType: state => state.voiceInfo.ttsType,
            sizeRatio: state => state.videoCreate.sizeRatio, // 宽高比例
        }),
        subtitleShow: {
            get() {
                return this.$store.state.videoData.subtitleShow
            },
            set(val) {
                this.$store.commit('videoData/SET_SUBTITLE_SHOW', val)
            }
        },
    },
    created() {
    },
    mounted() {
        this.subtitleShow = this.$store.state.videoData.subtitleShow
        this.content = this.sceneList[this.sceneIndex]?.data.content || ''
    },
    watch: {

    },
    methods: {
        // 输入中文开始时
        compositStart(e) {
            this.lock = false;
            document.querySelector("#textinput").focus();
            document.execCommand("selectAll", false, null);
            document.getSelection().collapseToEnd();
        },
        // 输入中文结束时
        compositEnd(e) {
            this.lock = true;
            this.input(e);
        },
        input(e) {
            if (this.lock) {
                this.textSize = e.target.innerHTML.length
                if (this.textSize >= 20000) {
                    this.textSize = 20000;
                    this.fullContent = e.target.innerHTML.substring(0, 20000);
                    e.target.innerHTML = e.target.innerHTML.substring(0, 20000);
                } else {
                    this.fullContent = ''
                }
                this.content = e.target.innerHTML;
                this.textFocus();
            } else if (this.fullContent) {
                // 目标对象：超过20000字时候的中文输入法
                // 原由：虽然不会输入成功，但是输入过程中字母依然会显现在输入框内
                // 弊端：谷歌浏览器输入法的界面偶尔会闪现
                e.target.innerHTML = this.fullContent;
                this.lock = true;
                this.textFocus();
            }
        },
        // 粘贴富文本转为纯文本
        copyText(e) {
            e.stopPropagation();
            e.preventDefault();
            let text = '', event = (e.originalEvent || e);
            if (event.clipboardData && event.clipboardData.getData) {
                text = event.clipboardData.getData('text/plain');
            } else if (window.clipboardData && window.clipboardData.getData) {
                text = window.clipboardData.getData('Text');
            }
            if (document.queryCommandSupported('insertText')) {
                document.execCommand('insertText', false, text);
            } else {
                document.execCommand('paste', false, text);
            }
        },
        // 文本输入框聚焦，焦点在最后位置
        textFocus() {
            setTimeout(() => {
                document.querySelector("#textinput").focus();
                document.execCommand("selectAll", false, null);
                document.getSelection().collapseToEnd();
            }, 0);
        },
        // 试听  合成
        syntheticAudio() {
            if (this.audioLoading) {
                return
            }
            var that = this
            if (that.content == '') {
                this.$errMsg('请输入文本')
                return
            }
            that.audioLoading = true
            var params = {
                content: that.content,
                content_trans: that.content,
                order_source: 1,
                avatar: that.default_tts.avatar,
                speed_rate: that.default_tts.speed_rate,
                volume: that.default_tts.volume,
                pitch: that.default_tts.pitch,
                sample_rate: that.default_tts.sample_rate,
                tts_list_id: that.default_tts.id,
                tts_list_name: that.default_tts.name,
                name: 'audio' + new Date().getTime(),
            }
            that.$audioApi.ttsCreateSynthesis(params).then(res => {
                that.audioLoading = false
                if (res.code == 1000) {
                    let obj = {
                        current: 2,
                        url: res.result.production_file,
                        duration: res.result.duration,
                        content: that.content
                    }
                    // 清空字幕数据
                    this.$store.commit('videoData/SET_SUBTITLE_LIST', [])
                    this.$emit('confirm', obj)
                } else {
                    that.$errMsg(res.message)
                }
            })
        },

        uploadSuccess(item) {
            var that = this
            // 限制图片格式
            const isType = this.acceptList.includes(item.file.type)
            // 限制图片大小
            const isLt50M = item.file.size / 1024 / 1024 <= 50;
            if (!isType) {
                that.$errMsg('上传视频只能是 MP3 , m4a, mav 格式!');
            }
            if (!isLt50M) {
                that.$errMsg('上传视频大小不能超过 50MB!');
            }
            if (isType && isLt50M) {
                let newFile = new FormData()
                newFile.append('type', 5);
                newFile.append('file', item.file);
                that.audioLoading = true
                that.$publicApi.uploadImg(newFile).then(res => {
                    that.audioLoading = false
                    if (res.code == 1000) {
                        let obj = {
                            current: 2,
                            url: res.result.url,
                            duration: 0,
                            content: '',
                            name: item.file.name,
                        }
                        this.$emit('confirm', obj)
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },

        // 是否真是字幕
        subtitleChange(val) {
            this.$store.commit('videoData/SET_SUBTITLE_SHOW', val)
            if (val) {
                this.addSubtitle()
            }
        },

        // 插入字幕
        addSubtitle() {
            var that = this
            // 默认
            let str = {
                type: 'subtitle',
                name: '字幕',
                url: '',
                style: {
                    left: 480,
                    top: 888,
                    width: 960,
                    height: 120,
                },
                textStyle: {
                    color: "#000000",
                    fontSize: 60,
                    fontStyle: "normal",
                    textAlign: "center",
                    fontFamily: "SourceHanSerifCN-Regular",
                    fontWeight: "normal",
                    lineHeight: 1,
                    letterSpacing: 1,
                    strokeColor: "",
                    strokeWidth: 0,
                    content: '这里是字幕',
                }
            }
            // 默认16:9  9比16时候位置更换
            if (this.sizeRatio == 2) {
                str.style.left = 67
                str.style.top = 1644
            }
            var arr = JSON.parse(JSON.stringify(that.sceneList))
            var scenes = arr[that.sceneIndex].scenes_conf || []
            let i = scenes.findIndex(ele => ele.type == 'subtitle')

            if (i == -1) {
                str.id = new Date().getTime();
                // 没有时候添加
                scenes.push(str)
                var params = {
                    type: 'conf',
                    info: scenes
                }
                that.$store.dispatch('videoData/modifyProduct', params)
            }
        },
        confirm(val) {
            let obj = {
                current: 2,
                url: val.url,
                duration: 0,
                content: '',
                name: val.name,
            }
            this.$emit('confirm', obj)
        },
    },

};
</script>

<style scoped lang="scss">
.creat-audio {
    width: 100%;
    height: 270px;
    background: #1b1d1f;
    padding: 0 20px;
    .c-a-t {
        width: 100%;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .c-a-t-l {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
        }
        .c-a-t-r {
            display: flex;
            align-items: center;
            .a-btn {
                display: inline-block;
                padding: 0 10px;
                height: 28px;
                background: rgba(48, 77, 242, 0.5);
                border-radius: 4px;
                border: 1px solid #2e4bf2;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: #ffffff;
                cursor: pointer;
                img {
                    margin-right: 4px;
                    width: 15px;
                    height: 14px;
                }
            }
            span {
                font-size: 14px;
                color: #ffffff;
                padding: 0 12px 0 20px;
            }
        }
    }
    .c-a-m {
        width: 100%;
        height: 170px;
        background: #0d1011;
        border-radius: 6px;
        .upload-box {
            width: 100%;
            display: flex;
            align-content: center;
            justify-content: center;
            button {
                display: flex;
                align-items: center;
                margin-top: 70px;
            }
            .img {
                width: 14px;
                height: 14px;
            }
        }
        .text-input {
            width: 100%;
            height: 100%;
            padding: 10px 6px 10px 10px;
            box-sizing: border-box;
            position: relative;

            .text-input-c {
                width: 100%;
                height: 100%;
                font-weight: 400;
                color: #a4a4a4;
                font-size: 14px;
                line-height: 20px;
                overflow-y: auto;
                word-wrap: break-word;
                word-break: break-all;
            }

            .text-input-c::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 3px;
            }
            .text-input-c::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
                background: #636363;
            }

            .total {
                position: absolute;
                right: 12px;
                bottom: 12px;
                font-size: 12px;
                color: #8f8f8f;
            }
        }
    }
    .c-a-b {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 14px;
        .c-a-b-l {
            display: flex;
            align-items: center;
            span {
                color: #ffffff;
                font-size: 14px;
            }
            img {
                width: 12px;
                height: 12px;
                margin-left: 4px;
                margin-right: 12px;
            }
            .input {
                margin: 0 6px;
                width: 36px;
            }
        }
        .c-a-b-r {
            display: flex;
            align-items: center;
            img {
                width: 12px;
                height: 12px;
                margin-left: 4px;
                margin-right: 12px;
            }
            span {
                color: #ffffff;
                font-size: 14px;
            }
            .btn {
                width: 60px;
                height: 30px;
                background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
                border-radius: 4px;
                text-align: center;
                line-height: 30px;
                font-size: 14px;
                color: #ffffff;
                cursor: pointer;
            }
        }
    }
}
.pdl30 {
    padding-left: 30px;
}
::v-deep .audio .el-input__inner {
    padding: 0 5px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #919191;
    background: #1b1d1f;
    font-size: 14px;
    color: #ffffff;
}
[contenteditable]:focus {
    outline: none;
}
</style>

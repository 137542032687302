<template>
    <div class="">
        <div class="video_table" :class="!navInfo.navFlag ? 'video_table_close' : ''">
            <!-- 标题 -->
            <div class="table_title">
                <i class="el-icon-close" @click="close"></i>
                <div class="title">滤镜</div>
            </div>
            <div class="table_content">
                <!-- 下方的筛选项目连带搜索 -->
                <div class="search-box">
                    <div class="search_inp">
                        <el-input placeholder="请输入滤镜名称" prefix-icon="el-icon-search" size="small" v-model="name" clearable @clear="expandClose" @keyup.enter.native="reset">
                        </el-input>
                    </div>
                </div>
                <div class="filter-list">
                    <div class="contentbox scroll_bar" v-if="name" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="">

                            <div class="list">

                                <div class="item openHover" v-for="(item, x) in searchlist" :key="x">
                                    <div class="item-pic" :ref='`bt` + x' @mouseover="previewbtn(x, item.thumbnail)">
                                        <div class="picbox">
                                            <el-image style="width: 96px; height: 70px" :src='item.thumbnail' fit="contain">
                                                <div slot="error" class="image-slot">
                                                    <img src="@/assets/images/icon/img.png" alt="">
                                                </div>
                                            </el-image>
                                        </div>
                                        <!-- 下方的插入按钮 -->
                                        <div class="insert">
                                            <div class="button" @click="selectMaterials(item)">插入</div>
                                        </div>
                                    </div>
                                    <div class="item-title">{{ item.name }}</div>
                                </div>
                            </div>
                            <!-- 没有更多 -->
                            <Loading :loading="loading" :list="searchlist" :page="page" :totalPage="last_page">
                            </Loading>
                        </div>
                    </div>
                    <div class="contentbox scroll_bar" v-else v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="" v-for="(ele, i) in list" :key="i">
                            <div class="list-title">
                                <div class="title">{{ ele.name }}</div>
                            </div>
                            <div class="list">

                                <div class="item openHover" v-for="(item, x) in ele.materialFilter" :key="x">
                                    <div class="item-pic" :ref='`bt` + x' @mouseover="previewbtn(x, item.thumbnail)">
                                        <div class="picbox">
                                            <el-image style="width: 96px; height: 70px" :src='item.thumbnail' fit="contain">
                                                <div slot="error" class="image-slot">
                                                    <img src="@/assets/images/icon/img.png" alt="">
                                                </div>
                                            </el-image>
                                        </div>
                                        <!-- 下方的插入按钮 -->
                                        <div class="insert">
                                            <div class="button" @click="selectMaterials(item)">插入</div>
                                        </div>
                                    </div>
                                    <div class="item-title">{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                        <!-- 没有更多 -->
                        <Loading :loading="loading" :list="list">
                        </Loading>
                    </div>
                </div>
            </div>
        </div>
        <!-- 预览 -->
        <el-popover v-if='showPop' ref='pop' :reference='reference' placement="left-start" trigger="hover" popper-class="preview-filter">
            <el-image style="width: 160px; height: 116px" :src='url' fit="contain">
                <div slot="error" class="image-slot">
                    <img src="@/assets/images/icon/img.png" alt="">
                </div>
            </el-image>
        </el-popover>
        <!-- 提示弹框 -->
        <point-out ref='dialogTip' type='works' @close='knowBtn' dialogTitle="提示" dialogWidth='380px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :affirmShow='true' affirmText='知道了'>
            <div class="zc_dialog_tip_content">
                <div class="text">请先设置场景配音后再添加滤镜效果！</div>
            </div>
        </point-out>
    </div>
</template>

<script>
import {
    mapState
} from "vuex"

export default {
    components: {
        PointOut: (resolve) => require(['@/components/point_out/point_out'], resolve),//提示弹框
        Loading: (resolve) => require(['@/components/loading/index'], resolve)

    },
    props: {
        navInfo: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            name: '',
            category: '',
            loading: false,
            page: 0,
            last_page: 1, // 总页数
            limit: 20,
            list: [],
            // categoryInfo: {},
            searchlist: [],
            // 保存当前激活的refrence id
            indexlist: '',
            // 控制渲染条件 如果不用v-if会报错 具体可自行尝试
            showPop: false,
            reference: {},
            url: '',
        }
    },
    created() {
        this.getList()
    },
    computed: {
        ...mapState({
        }),
        searchForm() {
            return {
                page: this.page,
                limit: this.limit,
                category_id: this.category,
                name: this.name
            }
        },
    },
    methods: {
        // 关闭
        close() {
            let obj = {
                ...this.navInfo,
                navFlag: false,
            }

            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', obj)
        },
        //
        inputBtn() { },
        // 加载更多
        load() {
            if (this.loading || this.page === this.last_page || this.last_page === 0) return
            this.page++
            this.getSearchList()
        },
        // 重置
        reset() {
            console.log(this.name)
            this.category = ''
            this.searchlist = []
            this.page = 1
            this.getSearchList()
        },
        // 列表-带分类
        getList() {
            this.loading = true
            this.$workbenApi.filter_cate().then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 列表-带搜索
        getSearchList() {
            this.loading = true
            this.$workbenApi.filter_list({
                ...this.searchForm
            }).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.searchlist = [...this.searchlist, ...res?.result?.data] || []
                    this.last_page = res?.result?.last_page
                    this.page = res?.result?.current_page ?? 1
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 更多
        moreBtn(ele) {
            // this.categoryInfo = ele
            this.category = ele.id
            this.getSearchList()
            // this.$set(ele, 'expand', !ele.expand)
        },
        // 收起
        expandClose() {
            this.category = ''
            this.searchlist = []
        },
        // 插入素材
        selectMaterials(info) {
            this.$refs.dialogTip.openDialogBtn()

        },
        // 知道了
        knowBtn() {

        },
        previewbtn(index, url) {
            this.url = url
            // 这个操作是为了避免与源码中的点击reference doToggle方法冲突
            if (this.indexlist === index && this.showPop) return
            this.showPop = false
            this.indexlist = index
            // 因为reference是需要获取dom的引用 所以需要是$el
            this.reference = this.$refs['bt' + index][0]
            this.$nextTick(() => {
                // 等待显示的popover销毁后再 重新渲染新的popover
                this.showPop = true
                this.$nextTick(() => {
                    // 此时才能获取refs引用
                    this.$refs.pop.doShow()
                })
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";

.search-box {
    padding-top: 0;
    padding-bottom: 15px;
}

.contentbox {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 320px);
    margin-right: -8px;
}

.filter-list {
    .list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: -8px;
    }

    .item {
        margin-right: 8px;
        margin-bottom: 20px;
        position: relative;

        .item-pic {
            width: 96px;
            height: 70px;
            border-radius: 4px;
            background: #000000;
            overflow: hidden;
            position: relative;

            .insert {
                position: absolute;
                left: 28px;
                bottom: -26px;
                transition: all 0.3s ease;

                .button {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    width: 40px;
                    height: 20px;
                    background: linear-gradient(
                        90deg,
                        #4875ff 0%,
                        #2b4afe 100%
                    );
                    border-radius: 2px;
                    text-align: center;
                    line-height: 20px;
                    cursor: pointer;
                }
            }
        }

        .item-title {
            margin-top: 6px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
        }
    }

    .openHover {
        &:hover {
            .insert {
                bottom: 6px;
                transition: all 0.3s ease;
            }
        }
    }
}
</style>
<style lang="scss">
.preview-filter {
    width: 180px;
    height: 136px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    padding: 10px;
    border: none;
}

.preview-filter.el-popper[x-placement^="left"] .popper__arrow {
    top: 50%;
    right: -6px;
    margin-bottom: 3px;
    border-right-width: 0;
    border-left-color: #000 !important;
}

.preview-filter.el-popper[x-placement^="left"] .popper__arrow::after {
    right: 1px;
    bottom: -6px;
    margin-left: -6px;
    border-right-width: 0;
    border-left-color: #000 !important;
}
</style>
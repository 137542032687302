<!-- @Author: Yu_Bo 2023-10-11 14:06:11-->
<template>
    <div style="height: 100%;">
        <div class="header">
            <div class="header_left">
                <!-- 图标 -->
                <div class="scaleimg" @click="setFullBtn">
                    <img src="@/assets/images/createvideo/icon1.png" alt="" v-show="isFull" />
                    <img src="@/assets/images/createvideo/icon2.png" alt="" v-show="!isFull" />
                </div>
                <!-- 输入框 -->
                <div class="name" v-show="!isEdit">
                    {{ data.name }}
                </div>
                <!-- name输入框 -->
                <div class="nameinput" v-show="isEdit">
                    <el-input v-model="inputName" placeholder="请输入视频名称" @keyup.enter.native="$event.target.blur()" @blur="confimName"></el-input>
                </div>
                <!-- 编辑的图标 -->
                <i class="el-icon-edit editicon" v-show="!isEdit" @click="isEdit = true, inputName = data.name"></i>
            </div>
            <div class="header_right">
                <!-- 时长包 -->
                <el-popover popper-class="right_popover" placement="bottom" width="220" trigger="hover">
                    <div class="time_info">
                        <div class="info_num">
                            <span v-if="$utils.formatSeconds(video_duration, 3).h"> {{ $utils.formatSeconds(video_duration,
                                3).h }}小时</span>
                            <span v-if="$utils.formatSeconds(video_duration, 3).m"> {{ $utils.formatSeconds(video_duration,
                                3).m }}分</span>
                            <span>{{ $utils.formatSeconds(video_duration, 3).s }}秒</span>
                        </div>
                        <div class="time_name">总时长</div>
                        <div class="time_txt">
                            <div class="left">视频时长</div>
                            <div class="right">
                                <span v-if="$utils.formatSeconds(video_duration, 3).h"> {{
                                    $utils.formatSeconds(video_duration, 3).h }}小时</span>
                                <span v-if="$utils.formatSeconds(video_duration, 3).m"> {{
                                    $utils.formatSeconds(video_duration, 3).m }}分</span>
                                <span>{{ $utils.formatSeconds(video_duration, 3).s }}秒</span>
                            </div>
                        </div>
                        <div class="time_txt">
                            <div class="left">钻石余额</div>
                            <div class="right">
                                <span>--</span>
                                <img src="@/assets/images/icon/diamond_icon.png" alt="">
                            </div>
                        </div>
                        <div class="time_btn" @click="investBtn(0)">充值</div>
                    </div>
                    <div class="right_time" slot="reference">
                        <img src="@/assets/images/createvideo/time.png" alt="">
                        <span>时长包</span>
                    </div>
                </el-popover>
                <!-- 分割线 -->
                <el-divider direction="vertical"></el-divider>
                <!-- 设置 -->
                <el-popover ref="refHeader" popper-class="right_popover" placement="bottom" width="280" trigger="click">
                    <div class="set_info">
                        <div class="info">
                            <div class="left">宽高比</div>
                            <div class="right right_ratio">
                                <div class="ratio_txt" :class="sizeRatio == 2 ? 'txt_active' : ''" @click="ratioBtn(2)">
                                    <img src="@/assets/images/createvideo/h_icon.png" alt="">
                                    <span>9:16</span>
                                </div>
                                <div class="ratio_txt" :class="sizeRatio == 1 ? 'txt_active' : ''" @click="ratioBtn(1)">
                                    <img src="@/assets/images/createvideo/v_icon.png" alt="">
                                    <span>16:9</span>
                                </div>
                            </div>
                        </div>
                        <div class="info">
                            <div class="left">分辨率</div>
                            <div class="right">
                                <el-select popper-class="select_box" v-model="resolutionRatio" size="small" placeholder="请选择">
                                    <el-option v-for="item in ratioList" :key="item.value" :label="item.label" :value="item.value">
                                        <span style="float: left">{{ item.label }}</span>
                                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="info">
                            <div class="left">码率</div>
                            <div class="right">
                                <el-select popper-class="select_box" v-model="bit_rate" size="small" placeholder="请选择">
                                    <el-option v-for="item in codeRateList" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="info">
                            <div class="left">帧率</div>
                            <div class="right">
                                <el-select popper-class="select_box" v-model="fps" size="small" placeholder="请选择" @change="changeFps">
                                    <el-option v-for="item in frameRateList" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="right_btn" slot="reference">
                        <img src="@/assets/images/createvideo/set.png" alt="">
                    </div>
                </el-popover>
                <!-- 复制 -->
                <div class="right_btn right_btn_left">
                    <el-tooltip class="item" effect="dark" content="复制" placement="top">
                        <img @click="copyVideo" src="@/assets/images/createvideo/copy.png" alt="">
                    </el-tooltip>
                </div>
                <!-- 保存 -->
                <div class="right_btn right_btn_left">
                    <el-tooltip class="item" effect="dark" content="保存" placement="top">
                        <img @click="saveBtn('save')" src="@/assets/images/createvideo/save.png" alt="">
                    </el-tooltip>
                </div>
                <!-- 保存时间 -->
                <div class="right_save_time" v-if="saveTime">{{ saveTime }}</div>
                <!-- 分割线 -->
                <el-divider direction="vertical"></el-divider>
                <!-- 设为模板 -->
                <div class="right_template" @click="saveBtn('template')">设为模板</div>
                <!-- 合成 -->
                <div class="right_synthesis">合成</div>
            </div>
        </div>
        <!-- 充值 -->
        <invest-money ref="InvestMoney" @renovate='getParams' />
    </div>
</template>

<script>
import { mapState } from "vuex"
import InvestMoney from '@/components/invest_money/invest_money.vue'//充值

export default {
    components: { InvestMoney },
    props: {
        isFull: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            // 名称 是否为编辑状态
            isEdit: false,
            inputName: '',
            // 名称
            // 时长包
            video_duration: 1212121212,
            // 保存时间
            saveTime: '',
        };
    },

    computed: {
        ...mapState({
            sizeRatio: state => state.videoCreate.sizeRatio, // 宽高比例
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            data: state => state.videoData.data,//选中场景下标
            subtitleShow: state => state.videoData.subtitleShow,
        }),
        // 分辨率
        resolutionRatio: {
            get() {
                return this.$store.state.videoCreate.resolutionRatio
            },
            set(val) {
                this.$store.commit('videoCreate/SET_RESOLUTION_RATIO', val)
            }
        },
        // 码率
        bit_rate: {
            get() {
                return this.$store.state.videoCreate.bit_rate
            },
            set(val) {
                this.$store.commit('videoCreate/SET_BIT_RATE', val)
            }
        },
        // 帧率
        fps: {
            get() {
                return this.$store.state.videoCreate.fps
            },
            set(val) {
                this.$store.commit('videoCreate/SET_FPS_RATE', val)
            }
        },
        ratioList() {
            if (this.sizeRatio == 2) {
                return [
                    { label: '4k', value: '2160*3840' },
                    { label: '2k', value: '1440*2560' },
                    { label: '1080p', value: '1080*1920' },
                    { label: '720p', value: '720*1280' },
                    { label: '480p', value: '480*854' },
                ]
            } else {
                return [
                    { label: '4k', value: '3840*2160' },
                    { label: '2k', value: '2560*1440' },
                    { label: '1080p', value: '1920*1080' },
                    { label: '720p', value: '1280*720' },
                    { label: '480p', value: '854*480' },
                ]
            }
        },
        codeRateList() {
            return [
                { label: '1.5M', value: '1.5M' },
                { label: '3M', value: '3M' },
                { label: '3.5M', value: '3.5M' },
                { label: '5M', value: '5M' },
                { label: '7M', value: '7M' },
                { label: '8M', value: '8M' },
                { label: '10M', value: '10M' },
                { label: '12M', value: '12M' },
                { label: '16M', value: '16M' },
            ]
        },
        frameRateList() {
            return [
                { label: '15fps', value: 15 },
                { label: '20fps', value: 20 },
                { label: '25fps', value: 25 },
                { label: '30fps', value: 30 },
            ]
        },
    },

    watch: {},

    created() { },

    mounted() {
    },

    methods: {
        // 获取数据
        getParams() { },
        // 全屏
        setFullBtn() {
            this.$emit('full')
        },
        copyVideo() {
            let obj = {
                id: this.data.id
            }
            this.$digitalApi.copyVideo(obj).then(res => {
                if (res.code == 1000) {
                    this.$succMsg('复制成功')
                    this.$router.push({
                        path: '/createvideo/index/' + res.result.id,
                        query: {
                            id: '新建视频'
                        },
                    })
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 修改名称
        confimName() {
            if (this.inputName == '') {
                this.$warMsg('请输入名称')
                return
            }
            var obj = {
                name: this.inputName
            }
            this.$digitalApi.putCard(obj, this.data.id).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.data.name = this.inputName
                    this.isEdit = false
                    this.inputName = ''
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 切换比例
        ratioBtn(val) {
            var obj = this.ratioList.find(item => item.value == this.resolutionRatio)
            this.$store.commit('videoCreate/SET_SIZE_RATIO', val)
            this.$nextTick(() => {
                var objTxt = this.ratioList.find(item => item.label == obj.label)
                if (val == 1) {
                    this.$store.commit('videoCreate/SET_RESOLUTION_RATIO', objTxt.value)
                } else {
                    this.$store.commit('videoCreate/SET_RESOLUTION_RATIO', objTxt.value)
                }
                // 重置数据
                var params = {
                    type: 'resets',
                }
                // 修改数据
                this.$store.dispatch('videoData/modifyProduct', params)
                this.$refs.refHeader.doClose()
                this.$emit('ratio')
            })
        },
        // 充值
        investBtn(type) {
            this.$refs.InvestMoney.openDialogBtn(type)
        },
        // 保存
        saveBtn(type) {
            this.saveTime = this.$utils.currentSaveTime() + ' 已保存'
            let arr = this.sceneList
            let obj = {
                template_from: 2,
                aspect_ratio: this.sizeRatio,
                bit_rate: this.bit_rate,
                resolution: this.resolutionRatio,
                fps: this.fps,
                config: arr,
            }
            let params = {
                aspect_ratio: this.sizeRatio,
                bit_rate: this.bit_rate,
                resolution: this.resolutionRatio,
                config: JSON.stringify(obj)
            }
            this.$digitalApi.putCard(params, this.data.id).then(res => {
                if (res.code == 1000) {
                    if (type == 'template') {
                        this.saveTemplate()
                    }
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 保存模板
        saveTemplate() {
            let obj = {
                id: this.data.id
            }
            this.$digitalApi.postTemplate(obj).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        changeFps(val) {
            this.sceneList[this.sceneIndex].data.fps = val
        },

    }
}
</script>
<style lang='scss' scoped>
.header {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header_left {
        display: flex;
        align-items: center;

        .scaleimg {
            cursor: pointer;

            img {
                width: 32px;
                height: 32px;
            }
        }

        .name {
            margin-left: 20px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
        }

        .nameinput {
            width: 260px;
            height: 32px;
            margin-left: 20px;

            ::v-deep .el-input__inner {
                height: 32px;
                line-height: 32px;
                background: #3c4148;
                color: #fff;
                border: none;
            }
        }

        .editicon {
            color: #aeaeae;
            margin-left: 20px;
            cursor: pointer;
            padding-top: 2px;
        }
    }

    .header_right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .right_btn {
            width: 32px;
            height: 32px;

            img {
                cursor: pointer;
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        .right_btn_left {
            margin-left: 10px;
        }

        .right_save_time {
            padding-left: 10px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
        }

        .el-divider {
            margin: 0 20px;
            background-color: #666;
        }

        .right_time {
            cursor: pointer;
            height: 32px;
            line-height: 30px;
            padding: 0 8px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            background: linear-gradient(90deg, #ff6060 0%, #ff9665 100%);
            border-radius: 4px;
            display: flex;
            align-items: center;

            img {
                display: block;
                width: 17px;
                margin-right: 5px;
            }
        }

        .right_template {
            cursor: pointer;
            height: 32px;
            line-height: 32px;
            padding: 0 18px;
            font-size: 14px;
            margin-right: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 4px;
        }

        .right_synthesis {
            cursor: pointer;
            height: 32px;
            line-height: 32px;
            padding: 0 18px;
            margin-left: 5px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
            border-radius: 4px;
        }
    }
}
</style>
<style lang="scss">
.right_popover {
    background: #2b3036;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    border: 1px solid #6e757e;

    .popper__arrow {
        border-bottom-color: #6e757e !important;
    }

    .popper__arrow::after {
        border-bottom-color: #2b3036 !important;
    }

    .set_info {
        width: 100%;
        padding: 5px 0;

        .info {
            width: 100%;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                padding-left: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }

            .right {
                width: 186px;
                height: 32px;

                .el-select {
                    width: 100%;

                    .el-input__inner {
                        color: #fff;
                        border: 1px solid #3c4148;
                        background-color: #3c4148;
                    }
                }
            }

            .right_ratio {
                display: flex;
                align-items: center;

                .ratio_txt {
                    cursor: pointer;
                    width: 70px;
                    height: 32px;
                    margin-right: 10px;
                    background: #3c4148;
                    border: 1px solid #3c4148;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        display: block;
                        width: 16px;
                        height: 16px;
                    }

                    span {
                        padding-left: 5px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                    }
                }

                .txt_active {
                    background: #252f69;
                    border: 1px solid #2e4bf2;
                }
            }
        }

        .info:last-child {
            margin-bottom: 0;
        }
    }

    .time_info {
        width: 100%;
        padding: 10px 0;

        .info_num {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }
        }

        .time_name {
            text-align: center;
            padding-top: 4px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #d5d5d5;
        }

        .time_txt {
            margin-top: 25px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #d5d5d5;
            }

            .right {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                span {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                }

                img {
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin-left: 10px;
                }
            }
        }

        .time_btn {
            cursor: pointer;
            margin: 25px auto 0;
            width: 60px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            background: linear-gradient(90deg, #ff6060 0%, #ff9665 100%);
            border-radius: 4px;
        }
    }
}
</style>

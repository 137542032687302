<!-- @Author: Yu_Bo -->
<template>
    <div class='con_txt' v-if="styleInfo">
        <div class="text-number" style="width: 100px;">
            <el-input-number style="width: 100%;" size="mini" v-model="styleInfo.fontSize" :step="1" :step-strictly="true" :min="12" :max="200" label="" @change='inputChange' @focus="focusInput" @blur="blurInput"></el-input-number>
        </div>
        <div class="text-style">
            <div class="text-style-item" @click="modifyBold">
                <img v-if="styleInfo.fontWeight == 'bold'" src="@/assets/images/icon/bold.png" alt="">
                <img v-else src="@/assets/images/icon/no-bold.png" alt="">
            </div>
            <div class="line"></div>
            <div class="text-style-item" @click="modifyItalic">
                <img v-if="styleInfo.fontStyle == 'italic'" src="@/assets/images/icon/italic.png" alt="">
                <img v-else src="@/assets/images/icon/no-italic.png" alt="">
            </div>
        </div>
        <div class="text_color">
            <el-color-picker v-model="styleInfo.color" @change="changeColor" class='picker-config' :popper-append-to-body="false" size="mini"></el-color-picker>
        </div>
    </div>
</template>

<script>
import {
    mapState
} from "vuex"
export default {
    components: {},
    props: {
        infoIndex: { //当前编辑的下标
            type: Number,
            default: 0,
        },
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
        }),
        styleInfo() {
            if (this.sceneList.length) {
                var obj = this.sceneList[this.sceneIndex].scenes_conf[this.infoIndex]
                if (obj && (obj.type == 'subtitle' || obj.type == 'text')) {
                    return obj.textStyle
                } else {
                    return {
                        barrage_type: 2,
                        color: "#333333",
                        fontSize: 32,
                        fontStyle: "",
                        fontWeight: "",
                        id: new Date().getTime(),
                        content: "展示字幕",
                    }
                }
            }
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 输入框获取焦点
        focusInput() {
            this.$emit('input', 1)
        },
        // 输入框失去焦点
        blurInput() {
            this.$emit('input', 2)
        },
        // 改变字号大小
        inputChange() {
            this.editBtn()
        },
        // 加租
        modifyBold() {
            if (this.styleInfo.fontWeight == 'bold') {
                this.styleInfo.fontWeight = ''
            } else {
                this.styleInfo.fontWeight = 'bold'
            }
            this.editBtn()
        },
        // 斜体
        modifyItalic() {
            if (this.styleInfo.fontStyle == 'italic') {
                this.styleInfo.fontStyle = ''
            } else {
                this.styleInfo.fontStyle = 'italic'
            }
            this.editBtn()
        },
        // 选择颜色
        changeColor(val) {
            if (!val) {
                this.styleInfo.color = "#333333"
            }
            this.editBtn()
        },
        // 修改之后
        editBtn() {
            var arr = JSON.parse(JSON.stringify(this.sceneList))
            var str = arr[this.sceneIndex].scenes_conf
            str[this.infoIndex].textStyle = this.styleInfo
            var params = {
                type: 'conf',
                info: str
            }
            this.$store.dispatch('videoData/modifyProduct', params)
        },
    },
}
</script>

<style lang='scss' scoped>
.con_txt {
    position: relative;
    padding: 4px;
    display: flex;
    align-items: center;

    .text-style {
        width: 72px;
        height: 28px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #d1d1d1;
        display: flex;
        margin: 0 5px;

        .line {
            width: 1px;
            height: 28px;
            background: #d1d1d1;
        }

        .text-style-item {
            cursor: pointer;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 14px;
                height: 14px;
            }
        }
    }

    .text_color {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

::v-deep .el-color-picker__trigger,
::v-deep .el-input__inner {
    border: 1px solid #d1d1d1;
}

::v-deep .el-input-number__decrease,
::v-deep .el-input-number__increase {
    background: #ffffff;
}
</style>

<template>
    <div class="videoTrack" :style="{ width: audioDuration * (40+(secWidth*10)) + 'px' }" @click.stop>
        <div class="videoTrackColor"></div>
        <div class="videoTrackImage" :style="[imgStyle]"></div>
        <div class="captions-list" @click.stop>
            <div class="captions-item" :class="{active:index==current}" draggable="false" v-for="(item,index) in list" :key="index" :style="captionsStyle(item)">
                <div class="left" @mousedown.stop="mousedown($event,item,index,'left')"></div>
                <div class="move" @mousedown.stop="mousedown($event,item,index,'move')"><img src="@/assets/images/createvideo/cut_to.png" alt=""></div>
                <div class="right" @mousedown.stop="mousedown($event,item,index,'right')"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    props: {
        secWidth: Number,
        sWidth: Number,  //刻度尺每秒宽度 px
        audioDuration: Number, //音频时长
        paused: Boolean, //是否暂停
        currentTime: Number,  //当前播放时间
        x: Number,  //指针的x轴  添加按钮同位置
    },
    data() {
        return {
            current: null,// 当前选中ID
            startx: '', //上次移动开始距离
            index: '', //当前下标
        };
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,
            sceneIndex: state => state.videoData.sceneIndex,
        }),
        list() {
            if (this.sceneList.length) {
                return this.sceneList[this.sceneIndex].flipSections || []
            } else {
                return []
            }
        },
        imgStyle() {
            let arr = this.sceneList[this.sceneIndex].scenes_conf || []
            let img = ''
            arr.forEach(ele => {
                if (ele.type == 'dthuman') {
                    img = ele.url
                }
            })
            return { backgroundImage: 'url(' + img + ')' }
        },
    },
    created() {

    },
    mounted() {

    },
    watch: {

    },
    methods: {
        // 鼠标按下
        mousedown(e, item, index, type) {
            if (!this.paused) {
                return
            }
            this.index = index
            this.current = index
            // 绑定mousemove
            this.startx = e.pageX;
            if (type == 'move') {
                document.addEventListener('mousemove', this.mousemove)
            } else if (type == 'left') {
                document.addEventListener('mousemove', this.resizeMoveLeft)
            } else if (type == 'right') {
                document.addEventListener('mousemove', this.resizeMoveRight)
            }
            // 绑定鼠标抬起清除事件
            document.addEventListener('mouseup', this.mouseup)

            // 清空素材轨道选中
            this.$emit('clear')
        },
        // 右侧放大缩小
        resizeMoveRight(e) {
            let step = e.pageX - this.startx;
            this.startx = e.pageX;
            // 下一条的开头时间
            let nextSec = this.list[this.index + 1] ? this.list[this.index + 1].start : this.audioDuration
            // 拖动后结束时间
            let newEnd = this.list[this.index].end + (step / this.sWidth)
            // 结束时间减去开始时间 小于20像素 return
            let max = (this.list[this.index].end * this.sWidth)
            let min = (this.list[this.index].start * this.sWidth) + 20
            // 最小值为上一个的尾部
            if (nextSec >= newEnd) {
                // 最小限制20像素
                if (max > min) {
                    this.list[this.index].end += step / this.sWidth
                } else {
                    // 大于0是向右  小于0向左
                    if (step > 0) {
                        this.list[this.index].end += step / this.sWidth
                    } else {
                        return
                    }
                }
            } else {
                return
            }
        },
        // 左侧放大缩小
        resizeMoveLeft(e) {
            let step = e.pageX - this.startx;
            this.startx = e.pageX;
            // 上一个的结尾时间
            let prevSec = this.list[this.index - 1] ? this.list[this.index - 1].end : 0
            // 拖动后开始时间
            let newStart = this.list[this.index].start + (step / this.sWidth)
            // 结束时间减去开始时间 小于20像素 return
            let max = (this.list[this.index].end * this.sWidth)
            let min = (this.list[this.index].start * this.sWidth) + 20
            // 最小值为上一个的尾部
            if (prevSec <= newStart) {
                // 最小限制20像素
                if (max > min) {
                    this.list[this.index].start += step / this.sWidth
                } else {
                    // 大于0是向右  小于0向左
                    if (step < 0) {
                        this.list[this.index].start += step / this.sWidth
                    } else {
                        return
                    }
                }
            } else {
                return
            }
        },


        // 左右拖动
        mousemove(e) {
            let step = e.pageX - this.startx;
            this.startx = e.pageX;
            // 上一个的结尾时间
            let prevSec = this.list[this.index - 1] ? this.list[this.index - 1].end : 0
            // 下一条的开头时间
            let nextSec = this.list[this.index + 1] ? this.list[this.index + 1].start : this.audioDuration
            // 拖动后开始时间
            let newStart = this.list[this.index].start + (step / this.sWidth)
            // 拖动后结束时间
            let newEnd = this.list[this.index].end + (step / this.sWidth)
            if (prevSec <= newStart && nextSec >= newEnd) {
                this.list[this.index].start += step / this.sWidth
                this.list[this.index].end += step / this.sWidth
            } else {
                return
            }
        },
        // 解除绑定mousemove
        mouseup() {
            document.removeEventListener('mousemove', this.mousemove, false)
            document.removeEventListener('mousemove', this.resizeMoveLeft, false)
            document.removeEventListener('mousemove', this.resizeMoveRight, false)
        },
        // 动态样式
        captionsStyle(item) {
            return {
                width: (item.end - item.start) * this.sWidth + 'px',
                left: (item.start * this.sWidth) + 'px'
            }
        },

    },
    components: {

    },
};
</script>

<style scoped lang="scss">
.videoTrack {
    width: 100%;
    height: 24px;
    margin-left: 16px;
    margin-top: 5px;
    position: relative;
    z-index: 0;
    .videoTrackColor {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 24px;
        background: #282828;
        z-index: 1;
    }
    .videoTrackImage {
        width: 100%;
        height: 24px;
        background-image: url(~@/assets/images/editor/model.png);
        background-size: auto 100%;
        background-repeat: repeat-x;
        position: relative;
        z-index: 2;
    }
    .captions-list {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 24px;
        z-index: 3;
        .captions-item {
            height: 24px;
            background: rgba(79, 49, 124, 0.5);
            border-radius: 3px;
            line-height: 24px;
            text-align: center;
            padding: 0 6px;
            color: #ffffff;
            cursor: pointer;
            text-align: center;
            font-size: 12px;
            position: absolute;
            z-index: 10;
            top: 0;
            border: 1px solid rgba(79, 49, 124, 0.5);
            .move {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                img {
                    width: 16px;
                    height: 16px;
                    opacity: 0.8;
                }
            }
            .left {
                width: 4px;
                height: 24px;
                position: absolute;
                left: 0;
                top: 0;
                cursor: ew-resize;
            }
            .right {
                width: 4px;
                height: 24px;
                position: absolute;
                right: 0;
                top: 0;
                cursor: ew-resize;
            }
        }
        .captions-item.active {
            background: linear-gradient(
                90deg,
                rgba(120, 46, 242, 0.4) 0%,
                rgba(123, 71, 189, 0.4) 100%
            );
            border: 1px solid rgba(120, 46, 242, 1);
        }
    }
}
</style>

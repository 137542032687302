<template>
    <div class="data_info" :style="[style]">
        <div class="info_data" :style="[infoStyle]">
            <div class="info_inner" v-for="(item, index) in sceneList" :key="index" :style="innerStyle(index)">
                <!-- 图片 -->
                <img v-if="item.type == 'image'||item.type == 'avatar'|| item.type=='dthuman' && item.url" :src="item.url" @dragstart.prevent alt="">
                <!-- 视频 -->
                <video v-if="item.type == 'video' && item.url" :src="item.url" muted loop autoplay></video>
                <!-- 字幕 -->
                <!-- 文本 -->
                <div class="text" v-if="item.type == 'text'">
                    <div class='txt' :style="textStyle(item)">{{ item.content }}</div>
                </div>
                <div class="subtitle" v-if="item.type == 'subtitle' && subtitleShow">
                    <img :src="item.url" @dragstart.prevent alt="">
                    <div class='txt' :style="subtitleStyle(item)">{{ subtitle }}</div>
                    <div class='txt' :style="subtitleStyle1(item)">{{ subtitle }}</div>
                </div>
            </div>
        </div>
    </div>
</template> 

<script>
import { mapState } from "vuex"
export default {
    components: {},
    props: {
        scenesInfo: [Array, Object]
    },
    data() {
        return {

        };
    },

    computed: {
        ...mapState({
            sceneShow: state => state.videoCreate.sceneShow,
            sizeRatio: state => state.videoCreate.sizeRatio,
            resolutionRatio: state => state.videoCreate.resolutionRatio,
            subtitle: state => state.videoData.subtitle,//字幕
            subtitleShow: state => state.videoData.subtitleShow, //是否显示字幕
        }),
        sceneList() {
            return this.scenesInfo.scenes_conf
        },
        winStyle() {
            var arr = this.resolutionRatio.split('*')
            return {
                width: arr[0],
                height: arr[1]
            }
        },
        style() {
            if (this.sceneShow) {
                return {
                    width: this.sizeRatio == 2 ? '49px' : '156px',
                    height: '86px',
                }
            } else {
                return {
                    width: this.sizeRatio == 2 ? '18px' : '56px',
                    height: '32px',
                }
            }
        },
        infoStyle() {
            let background = this.scenesInfo?.background || {}
            var obj = {
                width: this.winStyle.width + 'px',
                height: this.winStyle.height + 'px',
            }
            if (background.type == 'color') {
                obj.background = background.color
            }
            if (background.type == 'image') {
                obj.background = 'url(' + background.url + ') no-repeat 100%/cover'
            }
            if (this.sceneShow) {
                var ratio = this.sizeRatio == 2 ? (49 / this.winStyle.width).toFixed(5) : (156 / this.winStyle.width).toFixed(5)
                return {
                    ...obj,
                    transform: 'scale(' + ratio + ')'
                }
            } else {
                var ratio = this.sizeRatio == 2 ? (18 / this.winStyle.width).toFixed(5) : (56 / this.winStyle.width).toFixed(5)
                return {
                    ...obj,
                    transform: 'scale(' + ratio + ')'
                }
            }
        },
    },

    watch: {},

    created() { },

    mounted() { },

    methods: {
        // 设置样式
        innerStyle(index) {
            var str = this.sceneList[index].style
            var type = this.sceneList[index].type
            if (type == 'background') {
                return {
                    width: '100%',
                    height: '100%',
                    top: '0px',
                    left: '0px',
                }
            }
            if (str) {
                return {
                    width: str.width + 'px',
                    height: str.height + 'px',
                    top: str.top + 'px',
                    left: str.left + 'px',
                    transform: 'rotate(' + str.rotation + 'deg)',
                }
            }
        },
        // 字幕样式 加描边
        subtitleStyle(info) {
            return {
                fontFamily: info.textStyle.fontFamily,
                color: info.textStyle.color,
                fontSize: info.textStyle.fontSize + 'px',
                fontWeight: info.textStyle.fontWeight,
                fontStyle: info.textStyle.fontStyle,
                textAlign: info.textStyle.textAlign,
                lineHeight: (info.textStyle.fontSize + 20) * info.textStyle.lineHeight + 'px',
                'text-stroke': `${info.textStyle.strokeWidth}px ${info.textStyle.strokeColor}`,
                '-webkit-text-stroke': `${info.textStyle.strokeWidth}px ${info.textStyle.strokeColor}`,
            }
        },
        // 描边会让文字不清晰  再加一层文字
        subtitleStyle1(info) {
            return {
                fontFamily: info.textStyle.fontFamily,
                color: info.textStyle.color,
                fontSize: info.textStyle.fontSize + 'px',
                fontWeight: info.textStyle.fontWeight,
                fontStyle: info.textStyle.fontStyle,
                textAlign: info.textStyle.textAlign,
                letterSpacing: info.textStyle.letterSpacing + 'px',
                lineHeight: (info.textStyle.fontSize + 20) * info.textStyle.lineHeight + 'px',
            }
        },
        textStyle(info) {
            return {
                fontFamily: info.textStyle.fontFamily,
                fontSize: info.textStyle.fontSize + 'px',
                color: info.textStyle.color,
                fontWeight: info.textStyle.fontWeight,
                fontStyle: info.textStyle.fontStyle,
                textAlign: info.textStyle.textAlign,
                lineHeight: info.textStyle.lineHeight,
                letterSpacing: info.textStyle.letterSpacing + 'px'
            }
        },
        // 背景样式
        backgroundStyle(str) {
            return {
                width: str.width + 'px',
                height: str.height + 'px',
                top: str.top + 'px',
                left: str.left + 'px'
            }
        },
    }
}
</script>
<style lang='scss' scoped>
.data_info {
    position: relative;
    margin: auto;
    transition: 0.3s;
    .info_data {
        position: absolute;
        transform-origin: 0 0;
        background: #fff;
        transition: 0.3s;
        .info_inner {
            position: absolute;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }

            video {
                display: block;
                width: 100%;
                height: 100%;
            }
            .subtitle {
                position: relative;
                width: 100%;
                height: 100%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                .txt {
                    position: absolute;
                    top: 50%;
                    z-index: 2;
                    width: 100%;
                    transform: translate(0, -50%);
                }
            }
            .text {
                position: relative;
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
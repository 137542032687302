<!-- @Author: Yu_Bo 2023-10-13 15:36:31-->
<template>
    <el-dialog class="point_ratio zc_dialog_inv" :visible.sync="dialogVisible" width="480px" :close-on-click-modal='false' :append-to-body="true">
        <div class="ratio_mian">
            <div class="main_close" @click='closeBtn'><i class="el-icon-close"></i></div>
            <div class="main_icon">
                <img src="../../../../assets/images/createvideo/point_icon1.png" alt="">
            </div>
            <div class="main_txt">
                点击<span>“确定”</span>后切换宽高比为{{ ratio==1?'9:16':'16:9' }}，保留作品配音、并默认居中展示模特
            </div>
            <div class="main_tip">
                <div class="tip_txt">1、点击“取消”按钮返回编辑页面</div>
                <div class="tip_txt">2、点击“不保存素材”按钮后直接切换宽高比为{{ ratio==1?'9:16':'16:9' }}并清空作品作品素材</div>
            </div>
            <div class="main_btn">
                <el-button size="small" class='btn1' type="text" @click="submit(1)">不保留素材</el-button>
                <el-button size="small" @click='closeBtn'>取消</el-button>
                <el-button size="small" class="btnBgColor_blue" @click="submit(2)">确定</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { mapState } from "vuex"
export default {
    components: {},
    props: {},
    data() {
        return {
            dialogVisible: false,
            ratio: 1,
        };
    },

    computed: {
        ...mapState({
            sizeRatio: state => state.videoCreate.sizeRatio, // 宽高比例
            resolutionRatio: state => state.videoCreate.resolutionRatio, // 分辨率
        }),
        ratioList() {
            if (this.sizeRatio == 2) {
                return [
                    { label: '4k', value: '2160*3840' },
                    { label: '2k', value: '1440*2560' },
                    { label: '1080p', value: '1080*1920' },
                    { label: '720p', value: '720*1280' },
                    { label: '480p', value: '480*854' },
                ]
            } else {
                return [
                    { label: '4k', value: '3840*2160' },
                    { label: '2k', value: '2560*1440' },
                    { label: '1080p', value: '1920*1080' },
                    { label: '720p', value: '1280*720' },
                    { label: '480p', value: '854*480' },
                ]
            }
        },
        winInfo() {
            var arr = this.resolutionRatio.split('*')
            return {
                width: arr[0],
                height: arr[1]
            }
        },
    },

    watch: {},

    created() { },

    mounted() { },

    methods: {
        // 打开
        openDialogBtn(val) {
            this.ratio = val
            this.dialogVisible = true
        },
        // 关闭
        closeBtn() {
            this.dialogVisible = false
        },
        // 1=不保留素材 2=确定
        submit(type) {
            var obj = this.ratioList.find(item => item.value == this.resolutionRatio)
            this.$store.commit('videoCreate/SET_SIZE_RATIO', this.ratio)
            this.$nextTick(() => {
                var objTxt = this.ratioList.find(item => item.label == obj.label)
                if (this.ratio == 1) {
                    this.$store.commit('videoCreate/SET_RESOLUTION_RATIO', objTxt.value)
                } else {
                    this.$store.commit('videoCreate/SET_RESOLUTION_RATIO', objTxt.value)
                }
                this.$emit('ratio')
                this.closeBtn()
            })
        },
    }
}
</script>
<style lang='scss' scoped>
.point_ratio {
    .ratio_mian {
        position: relative;
        width: 100%;
        padding-bottom: 20px;
        .main_close {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .el-icon-close {
                font-size: 20px;
            }
        }
        .main_icon {
            width: 100%;
            padding: 30px 0 0;
            display: flex;
            justify-content: center;
            img {
                display: block;
                width: 68px;
                height: 68px;
                margin: auto;
            }
        }
        .main_txt {
            width: 100%;
            line-height: 25px;
            padding: 20px 60px;
            text-align: center;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            span {
                color: #2e4bf2;
            }
        }
        .main_tip {
            width: 430px;
            margin: auto;
            line-height: 25px;
            padding: 10px 15px;
            background: #f2f7ff;
            border-radius: 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
        }
        .main_btn {
            width: 100%;
            padding: 20px 30px 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .btn1 {
                color: #ff7432;
            }
        }
    }
}
</style>
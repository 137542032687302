<!-- @Author: Yu_Bo 2023-10-20 13:58:18-->
<template>
    <div class="card_scene">
        <div class="scene_title">
            <img class="card_icon" src="@/assets/images/createvideo/scene_icon.png" alt="">
            <span>场景</span>
            <img :class="sceneShow ? 'card_open' : 'card_close'" @click="scenOpen" src="@/assets/images/createvideo/left_card.png" alt="">
        </div>
        <!--  -->
        <div class="scene_content scroll_bar">
            <div class="scene_info">
                <div class="info_title">场景1</div>
                <div class="info_con">
                    <div class="con_data" v-for="(item,index) in sceneList" :key="index">
                        <!-- <scene-info></scene-info> -->
                        <scene-info :key="index" :scenesList="item.scenes_conf"></scene-info>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import SceneInfo from './scene_info'
export default {
    components: { SceneInfo },
    props: {},
    data() {
        return {

        };
    },

    computed: {
        ...mapState({
            sceneShow: state => state.videoCreate.sceneShow,
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
        }),
    },

    watch: {},

    created() { },

    mounted() { },

    methods: {
        // 展开收起
        scenOpen() {
            this.$emit('open')
        },
    }
}
</script>
<style lang='scss' scoped>
.card_scene {
    position: relative;
    width: 100%;
    height: 100%;

    .scene_title {
        position: relative;
        width: 100%;
        height: 60px;
        padding: 0 20px;
        display: flex;
        align-items: center;

        .card_icon {
            display: block;
            width: 18px;
            height: 18px;
        }

        span {
            padding-left: 10px;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
        }

        .card_open {
            cursor: pointer;
            position: absolute;
            top: 21px;
            right: 20px;
            display: block;
            width: 18px;
            height: 18px;
            -moz-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            transform: scaleX(-1);
        }

        .card_close {
            cursor: pointer;
            position: absolute;
            top: 21px;
            right: 20px;
            display: block;
            width: 18px;
            height: 18px;
        }
    }

    .scene_content {
        width: 100%;
        height: calc(100% - 70px);
        padding: 0 20px;
        overflow-y: auto;

        .scene_info {
            width: 100%;

            .info_title {
                width: 100%;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
            }

            .info_con {
                width: 100%;
                padding-top: 10px;

                .con_data {
                    position: relative;
                    width: 100%;
                    margin-top: 5px;
                    background: url(~@/assets/images/createvideo/scene_background.png)
                        no-repeat;
                    background-size: 100% 100%;
                    border-radius: 6px;
                    border: 2px solid #2b4afe;
                    overflow: hidden;
                }
            }
        }
    }
}
</style>
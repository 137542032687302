<template>
    <div class="">
        <div class="video_table" :class="!navInfo.navFlag ? 'video_table_close' : ''">
            <!-- 标题 -->
            <div class="table_title">
                <i class="el-icon-close" @click="close"></i>
                <div class="title">字幕</div>
            </div>
            <div class="table_content">
                <!-- 添加 -->
                <div class="add-btn" @click="selectMaterials('add')">
                    <i class="el-icon-plus icon"></i>
                    添加字幕
                </div>

                <!-- 下方的筛选项目连带搜索 -->
                <div class="search-box">
                    <div class="search_inp">
                        <el-input placeholder="请输入字幕名称" prefix-icon="el-icon-search" size="small" v-model="name" clearable @clear="expandClose" @keyup.enter.native="reset">
                        </el-input>
                    </div>
                </div>
                <!-- 列表 -->
                <div class="captions-list">
                    <!-- 列表-更多 -->
                    <div class="contentbox scroll_bar" v-if="category" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="list-title">
                            <div class="title">{{ categoryInfo.name }}</div>
                            <div class="icon" @click="expandClose()">
                                <i class="el-icon-arrow-left"></i>
                            </div>
                        </div>
                        <div class="">
                            <div class="item openHover" v-for="(item, x) in searchlist" :key="x">
                                <div class="item-pic">
                                    <div class="picbox">
                                        <el-image style="width: 304px; height: 60px" :src='item.thumbnail' fit="cover">
                                            <div slot="error" class="image-slot">
                                                <img src="@/assets/images/icon/img.png" alt="">
                                            </div>
                                        </el-image>
                                    </div>
                                    <!-- 下方的插入按钮 -->
                                    <div class="insert">
                                        <div class="button" @click="selectMaterials(item)">插入</div>
                                    </div>
                                </div>
                            </div>
                            <!-- 没有更多 -->
                            <Loading :loading="loading" :list="searchlist" :page="page" :totalPage="last_page">
                            </Loading>
                        </div>
                    </div>
                    <div class="contentbox scroll_bar" v-else-if="name" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="">
                            <div class="item openHover" v-for="(item, x) in searchlist" :key="x">
                                <div class="item-pic">
                                    <div class="picbox">
                                        <el-image style="width: 304px; height: 60px" :src='item.thumbnail' fit="cover">
                                            <div slot="error" class="image-slot">
                                                <img src="@/assets/images/icon/img.png" alt="">
                                            </div>
                                        </el-image>
                                    </div>
                                    <!-- 下方的插入按钮 -->
                                    <div class="insert">
                                        <div class="button" @click="selectMaterials(item)">插入</div>
                                    </div>
                                </div>
                            </div>
                            <!-- 没有更多 -->
                            <Loading :loading="loading" :list="searchlist" :page="page" :totalPage="last_page">
                            </Loading>
                        </div>

                    </div>
                    <!-- 列表-带分类 -->
                    <div class="contentbox scroll_bar" v-else v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="" v-for="(ele, i) in list" :key="i">
                            <div class="list-title">
                                <div class="title">{{ ele.name }}</div>
                                <div class="icon" @click="moreBtn(ele)">
                                    <span>更多<i class="el-icon-arrow-right"></i></span>
                                </div>
                            </div>
                            <div class="">
                                <div class="item openHover" v-for="(item, x) in ele.materialCaption" :key="x">
                                    <div class="item-pic">
                                        <div class="picbox">
                                            <el-image style="width: 304px; height: 60px" :src='item.thumbnail' fit="cover">
                                                <div slot="error" class="image-slot">
                                                    <img src="@/assets/images/icon/img.png" alt="">
                                                </div>
                                            </el-image>
                                        </div>
                                        <!-- 下方的插入按钮 -->
                                        <div class="insert">
                                            <div class="button" @click="selectMaterials(item)">插入</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- 没有更多 -->
                        <Loading :loading="loading" :list="list">
                        </Loading>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapState
} from "vuex"
export default {
    components: {
        Loading: (resolve) => require(['@/components/loading/index'], resolve)
    },
    props: {
        navInfo: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            name: '',
            category: '',
            loading: false,
            page: 0,
            last_page: 1, // 总页数
            limit: 20,
            list: [],
            categoryInfo: {},
            searchlist: [],
        }
    },
    created() {
        this.getList()
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            sizeRatio: state => state.videoCreate.sizeRatio,//宽高比2=9:16 1=16:9
        }),
        searchForm() {
            return {
                page: this.page,
                limit: this.limit,
                category_id: this.category,
                name: this.name
            }
        },
    },
    methods: {
        // 关闭
        close() {
            let obj = {
                ...this.navInfo,
                navFlag: false,
            }
            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', obj)

        },
        //
        inputBtn() { },
        // 加载更多
        load() {
            if (this.loading || this.page === this.last_page || this.last_page === 0) return
            this.page++
            this.getSearchList()
        },
        // 重置
        reset() {
            this.category = ''
            this.searchlist = []
            this.page = 1
            this.getSearchList()
        },
        // 列表-带分类
        getList() {
            this.loading = true
            this.$workbenApi.caption_cate().then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 列表-带搜索
        getSearchList() {
            this.loading = true
            this.$workbenApi.caption_list({
                ...this.searchForm
            }).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.searchlist = [...this.searchlist, ...res?.result?.data] || []
                    this.last_page = res?.result?.last_page
                    this.page = res?.result?.current_page ?? 1
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 更多
        moreBtn(ele) {
            this.categoryInfo = ele
            this.category = ele.id
            this.getSearchList()
            // this.$set(ele, 'expand', !ele.expand)
        },
        // 收起
        expandClose() {
            this.category = ''
            this.searchlist = []
        },
        // 插入素材
        selectMaterials(info) {
            let str = {}
            if (info == 'add') {
                // 默认
                str = {
                    type: 'subtitle',
                    name: '字幕',
                    url: '',
                    style: {
                        left: 480,
                        top: 888,
                        width: 960,
                        height: 120,
                    },
                    textStyle: {
                        color: "#000000",
                        fontSize: 60,
                        fontStyle: "normal",
                        textAlign: "center",
                        fontFamily: "SourceHanSerifCN-Regular",
                        fontWeight: "normal",
                        lineHeight: 1,
                        letterSpacing: 1,
                        strokeColor: "",
                        strokeWidth: 0,
                        content: '这里是字幕',
                    }
                }

            } else {
                // 已有数据
                let obj = JSON.parse(info.config_json)
                str = {
                    type: 'subtitle',
                    name: '字幕',
                    url: info.thumbnail,
                    style: {
                        left: 480,
                        top: 888,
                        width: 960,
                        height: 120,
                    },
                    textStyle: {
                        color: obj.color,
                        fontSize: obj.fontSize,
                        fontStyle: obj.fontStyle,
                        textAlign: obj.textAlign,
                        fontFamily: obj.fontFamily,
                        fontWeight: obj.fontWeight,
                        lineHeight: obj.lineHeight,
                        letterSpacing: obj.letterSpacing,
                        strokeColor: obj.stroke,
                        strokeWidth: obj.strokeThickness,
                        content: '这里是字幕',
                    }
                }
            }

            // 默认16:9  9比16时候位置更换
            if (this.sizeRatio == 2) {
                str.style.left = 67
                str.style.top = 1644
            }

            var that = this
            var arr = JSON.parse(JSON.stringify(that.sceneList))
            var idx = that.sceneIndex
            var scenes = arr[idx].scenes_conf || []

            let i = scenes.findIndex(ele => ele.type == 'subtitle')

            if (i == -1) {
                str.id = new Date().getTime();
                // 没有时候添加
                scenes.push(str)
            } else {
                str.id = scenes[i].id
                // 有的时候替换
                scenes[i] = str
            }
            var params = {
                type: 'conf',
                info: scenes
            }
            that.$store.dispatch('videoData/modifyProduct', params)
            // 插入后选中
            that.$store.commit('videoData/SET_SHOW_ID', str.id)
            that.$store.commit('videoData/SET_SUBTITLE_SHOW', true)
        },
    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";

// .search-box {
//   padding-top: 0;
//   padding-bottom: 15px;
// }

.contentbox {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 320px);
    margin-right: -8px;
}

.add-btn {
    width: 306px;
    height: 38px;
    background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
    text-align: center;
    cursor: pointer;
}

.captions-list {
    .item {
        margin-bottom: 20px;
        border-radius: 4px;

        .item-pic {
            width: 304px;
            height: 60px;
            border-radius: 4px;
            overflow: hidden;
            position: relative;

            .insert {
                position: absolute;
                left: 132px;
                bottom: -20px;
                transition: all 0.3s ease;

                .button {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    width: 40px;
                    height: 20px;
                    background: linear-gradient(
                        90deg,
                        #4875ff 0%,
                        #2b4afe 100%
                    );
                    border-radius: 2px;
                    text-align: center;
                    line-height: 20px;
                    cursor: pointer;
                }
            }
        }
    }

    .openHover {
        &:hover {
            .item {
                background: rgba(0, 0, 0, 0.57);
            }

            .insert {
                bottom: 20px;
                transition: all 0.3s ease;
            }
        }
    }
}
</style>
<template>
    <div class="captions-track">
        <div class="captions-list" @click.stop>
            <div class="captions-item" :class="{active:index==current}" draggable="false" v-for="(item,index) in list" :key="index" :style="captionsStyle(item)" @dblclick="editShow(index)">
                <div class="left" @mousedown.stop="mousedown($event,item,index,'left')"></div>
                <div class="move" @mousedown.stop="mousedown($event,item,index,'move')">{{item.content}}</div>
                <div class="right" @mousedown.stop="mousedown($event,item,index,'right')"></div>
            </div>
            <div class="add" v-if="addShow" :style="{left:x + 'px'}" @mousedown.stop="addCaptions"> + 添加字幕</div>
        </div>
        <!-- 字幕编辑 -->
        <captions-edit ref="captionsedit" :list="list"></captions-edit>
    </div>
</template>

<script>
import CaptionsEdit from './edit.vue'
import { mapState } from "vuex"
export default {
    components: {
        CaptionsEdit
    },
    props: {
        sWidth: Number,  //刻度尺每秒宽度 px
        audioDuration: Number, //音频时长
        paused: Boolean, //是否暂停
        currentTime: Number,  //当前播放时间
        x: Number,  //指针的x轴  添加按钮同位置
    },
    data() {
        return {
            current: null,// 当前选中ID
            startx: '', //上次移动开始距离
            index: '', //当前下标
            disable: false,//是否可添加字幕
        };
    },
    created() {
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,
            sceneIndex: state => state.videoData.sceneIndex,
        }),
        list() {
            if (this.sceneList.length) {
                return this.sceneList[this.sceneIndex].data.subtitleList || []
            } else {
                return []
            }
        },
        // 是否显示添加字幕按钮
        addShow() {
            // 播放中不显示
            if (!this.paused) {
                return false
            }
            if (this.disable) {
                return false
            }
            // 时间段内有字幕不显示
            let idx = this.list.findIndex(ele => (this.currentTime > ele.start && this.currentTime < ele.end))
            if (idx != -1) {
                return false
            }

            let ind = this.list.findIndex(ele => this.currentTime < ele.start)

            // 添加字幕可选最大时间
            let min;
            if (ind == -1) {
                min = this.audioDuration - this.currentTime
            } else {
                min = this.list[ind].start - this.currentTime
            }
            // 距离下一个开始时间宽度小于30不显示
            if ((min * this.sWidth) > 30) {
                return true
            } else {
                return false
            }
        },
    },
    watch: {
        currentTime(val) {
            this.disable = false
            let ind = this.list.findIndex(ele => (val > ele.start && val < ele.end))
            if (ind != -1) {
                this.current = ind
                this.$store.commit('videoData/SET_SUBTITLE', this.list[ind].content)
            } else {
                this.current = null
                this.$store.commit('videoData/SET_SUBTITLE', '字幕展示')
            }
        },
    },
    methods: {
        // 添加字幕
        addCaptions() {
            let ind = this.list.findIndex(ele => this.currentTime < ele.start)
            // 添加字幕可选最大时间
            let maxTime;
            if (ind == -1) {
                maxTime = this.audioDuration
            } else {
                maxTime = this.list[ind].start
            }

            let min = maxTime - this.currentTime
            let end;
            // 如果可添加字幕范围时间大于1时长就是1   小于1就按可用最大值
            if (min >= 1) {
                end = this.currentTime + 1
            } else {
                end = this.currentTime + min
            }
            let obj = {
                start: this.currentTime,
                end: end,
                content: "添加字幕",
                // id: this.randomString(10)
            }
            if (ind == -1) {
                // 插入结尾
                this.list.push(obj);
                this.index = this.list.length - 1
            } else if (ind == 0) {
                // 插入开头
                this.list.unshift(obj);
                this.index = ind
            } else {
                // 中间插入
                this.list.splice(ind, 0, obj);
                this.index = ind
            }
            // 选中id
            this.current = this.index
            // 添加成功  不能重复添加 直到时间改变
            this.disable = true
        },
        // 编辑字幕弹窗
        editShow(index) {
            this.$refs.captionsedit.open(this.list, index)
        },
        // 鼠标按下
        mousedown(e, item, index, type) {
            if (!this.paused) {
                return
            }

            this.index = index
            // 有内容展示内容  没有内容显示字幕展示
            this.$emit('captionsChange', this.list[index].content ? this.list[index].content : '字幕展示')
            // 绑定mousemove
            this.startx = e.pageX;
            if (type == 'move') {
                document.addEventListener('mousemove', this.mousemove)
            } else if (type == 'left') {
                document.addEventListener('mousemove', this.resizeMoveLeft)
            } else if (type == 'right') {
                document.addEventListener('mousemove', this.resizeMoveRight)
            }
            // 绑定鼠标抬起清除事件
            document.addEventListener('mouseup', this.mouseup)

            // 清空素材轨道选中
            this.$emit('clear')
        },
        // 右侧放大缩小
        resizeMoveRight(e) {
            let step = e.pageX - this.startx;
            this.startx = e.pageX;
            // 下一条的开头时间
            let nextSec = this.list[this.index + 1] ? this.list[this.index + 1].start : this.audioDuration
            // 拖动后结束时间
            let newEnd = this.list[this.index].end + (step / this.sWidth)
            // 结束时间减去开始时间 小于20像素 return
            let max = (this.list[this.index].end * this.sWidth)
            let min = (this.list[this.index].start * this.sWidth) + 20
            // 最小值为上一个的尾部
            if (nextSec >= newEnd) {
                // 最小限制20像素
                if (max > min) {
                    this.list[this.index].end += step / this.sWidth
                } else {
                    // 大于0是向右  小于0向左
                    if (step > 0) {
                        this.list[this.index].end += step / this.sWidth
                    } else {
                        return
                    }
                }
            } else {
                return
            }
        },
        // 左侧放大缩小
        resizeMoveLeft(e) {
            let step = e.pageX - this.startx;
            this.startx = e.pageX;
            // 上一个的结尾时间
            let prevSec = this.list[this.index - 1] ? this.list[this.index - 1].end : 0
            // 拖动后开始时间
            let newStart = this.list[this.index].start + (step / this.sWidth)
            // 结束时间减去开始时间 小于20像素 return
            let max = (this.list[this.index].end * this.sWidth)
            let min = (this.list[this.index].start * this.sWidth) + 20
            // 最小值为上一个的尾部
            if (prevSec <= newStart) {
                // 最小限制20像素
                if (max > min) {
                    this.list[this.index].start += step / this.sWidth
                } else {
                    // 大于0是向右  小于0向左
                    if (step < 0) {
                        this.list[this.index].start += step / this.sWidth
                    } else {
                        return
                    }
                }
            } else {
                return
            }
        },


        // 左右拖动
        mousemove(e) {
            let step = e.pageX - this.startx;
            this.startx = e.pageX;
            // 上一个的结尾时间
            let prevSec = this.list[this.index - 1] ? this.list[this.index - 1].end : 0
            // 下一条的开头时间
            let nextSec = this.list[this.index + 1] ? this.list[this.index + 1].start : this.audioDuration
            // 拖动后开始时间
            let newStart = this.list[this.index].start + (step / this.sWidth)
            // 拖动后结束时间
            let newEnd = this.list[this.index].end + (step / this.sWidth)
            if (prevSec <= newStart && nextSec >= newEnd) {
                this.list[this.index].start += step / this.sWidth
                this.list[this.index].end += step / this.sWidth
            } else {
                return
            }
        },
        // 解除绑定mousemove
        mouseup() {
            document.removeEventListener('mousemove', this.mousemove, false)
            document.removeEventListener('mousemove', this.resizeMoveLeft, false)
            document.removeEventListener('mousemove', this.resizeMoveRight, false)
        },
        // 动态样式
        captionsStyle(item) {
            return {
                width: (item.end - item.start) * this.sWidth + 'px',
                left: (item.start * this.sWidth) + 'px'
            }
        },
        // 随机字符串
        randomString(val) {
            var len = val || 32;
            var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
            var maxPos = $chars.length;
            var pwd = '';
            for (let i = 0; i < len; i++) {
                pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        }
    },
};
</script>

<style scoped lang="scss">
.captions-track {
    width: 100%;
    height: 24px;
    background: #282828;
    margin-top: 5px;
    margin-left: 16px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    .captions-list {
        display: flex;
        width: 100%;
        .captions-item {
            height: 24px;
            background: #4f317c;
            border-radius: 5px;
            line-height: 24px;
            text-align: center;
            padding: 0 6px;
            color: #ffffff;
            cursor: pointer;
            text-align: center;
            font-size: 12px;
            position: absolute;
            z-index: 10;
            top: 0;
            .move {
                width: 100%;
                height: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .left {
                width: 4px;
                height: 24px;
                position: absolute;
                left: 0;
                top: 0;
                cursor: ew-resize;
            }
            .right {
                width: 4px;
                height: 24px;
                position: absolute;
                right: 0;
                top: 0;
                cursor: ew-resize;
            }
        }
        .captions-item.active {
            background: linear-gradient(90deg, #782ef2 0%, #7b47bd 100%);
        }
    }
    .add {
        width: 75px;
        line-height: 24px;
        color: #a4a5ad;
        cursor: pointer;
        padding-left: 4px;
        font-size: 12px;
        z-index: 9;
        position: absolute;
        top: 0;
    }
}
</style>

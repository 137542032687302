<template>
    <div class="select-box" :style="selectStyle(list)" @mouseenter="mouseenter" @mouseleave="mouseleave">
        <div class="select-list">
            <div class="item" v-for="(item,x) in list" :key="x" :class="{active:item.id==id}" @click.stop="activeId(item)">
                <div class="select" v-if="list.length>=3">
                    <el-checkbox v-model="item.check" size="small" @change="change"></el-checkbox>
                </div>
                <div class="del" @click.stop="del(item.id)">
                    <i class="el-icon-delete"></i>
                </div>
                <img class="music" v-if="item.type=='audio'" src="@/assets/images/icon/music.png" alt="">
                <img class="img" v-if="item.type=='image'" :src="item.url" alt="">
                <img class="img" v-if="item.type=='video'" :src="item.thumbnail" alt="">
                <div class="text" v-if="item.type=='text'">{{item.content}}</div>
            </div>
        </div>
        <div class="select-bottom" v-if="show">
            <el-checkbox v-model="checkAll" size="small" @change="changeAll">已选择{{checkNum}}个</el-checkbox>
            <div class="btn1" @click="batchDel">删除</div>
            <div class="btn2" @click="back">退出管理</div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        // index: Number,
    },
    data() {
        return {
            top: 0,
            left: 0,
            checked: false,
            checkAll: false,
            list: [],
            checkNum: 0,
            show: false,
            id: '',
        }
    },
    watch: {
        // index() {
        //     this.remove()
        // },
    },
    created() { },
    mounted() {
        // 挂载到body 
        this.$nextTick(() => {
            const body = document.querySelector("body");
            if (body.append) {
                body.append(this.$el);
            } else {
                body.appendChild(this.$el);
            }
        });
    },
    methods: {
        del(id) {
            this.$emit('listDel', id, 'single')
            this.remove()
        },
        // 批量删除
        batchDel() {
            let arr = []
            this.list.forEach(ele => {
                if (ele.check) {
                    arr.push(ele.id)
                }
            })
            this.$emit('listDel', arr, 'batch')
            this.remove()
        },
        activeId(item) {
            this.id = item.id
            this.$emit('selectId', item, 'children')
        },
        // 单选
        change(val) {
            this.show = true
            let i = 0
            this.list.forEach(ele => {
                if (ele.check) {
                    i++
                }
            })
            this.checkNum = i
            if (this.checkNum == this.list.length) {
                this.checkAll = true
            } else {
                this.checkAll = false
            }
        },
        // 全选反选
        changeAll(val) {
            if (val) {
                this.list.forEach(ele => {
                    ele.check = true
                })
            } else {
                this.list.forEach(ele => {
                    ele.check = false
                })
            }
        },
        // 鼠标移入弹框 父组件赋值不会消失
        mouseenter() {
            this.$emit('mouseMove', true)
        },
        // 鼠标移入弹框 父组件赋值不会消失
        mouseleave() {
            this.$emit('mouseMove', false)
        },
        // 退出管理
        back() {
            this.list.forEach(ele => {
                ele.check = false
            })
            this.checkAll = false
            this.show = false
        },
        // 打开
        openDialog(top, left, val) {
            let arr = []
            val.children.forEach(ele => {
                ele.check = false
                arr.push(ele)
            })
            this.list = arr
            this.top = top
            this.left = left
            // 将组件挂在body上
        },
        // 销毁组件
        remove() {
            // 将组件从body上销毁
            const body = document.querySelector("body")
            body.removeChild(this.$el)
        },
        // 动态样式  弹框坐标
        selectStyle(val) {
            let num = Math.ceil(val.length / 3);
            let height = this.show ? 42 : 0
            return {
                top: this.top - (num * 80) - height - 64 + 'px',
                left: this.left + 'px'
            }
        },
    },
}
</script>

<style lang='scss' scoped>
.select-box {
    position: fixed;
    transform: translateX(-50%);
    margin-left: 12px;
    background: #2b3036;
    border: 1px solid #6e757e;
    border-radius: 4px;
    padding-bottom: 10px;
    z-index: 100;
    .select-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 10px;

        max-width: 277px;
        .item {
            width: 79px;
            height: 79px;
            margin-right: 10px;
            margin-top: 10px;
            border-radius: 4px;
            background: #ffffff;
            position: relative;
            border: 1px solid #2b3036;
            .img {
                width: 100%;
                height: 100%;
            }
            .music {
                width: 100%;
                height: 100%;
                padding: 8px;
            }
            .text {
                width: 100%;
                height: 100%;
                line-height: 70px;
                font-size: 12px;
                color: #999999;
                overflow: hidden;
                text-align: center;
            }
            .select {
                position: absolute;
                left: 6px;
            }
            .del {
                width: 14px;
                height: 14px;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 2px;
                position: absolute;
                top: 6px;
                right: 6px;
                text-align: center;
                line-height: 12px;
                cursor: pointer;
                display: none;
                .el-icon-delete {
                    font-size: 12px;
                    color: #ffffff;
                }
            }
        }
        .item.active {
            border: 1px solid #2e4bf2;
        }
        .item:hover {
            border: 1px solid #2e4bf2;
            .del {
                display: block;
            }
        }
    }
    &::before {
        content: "";
        display: block;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #2b3036;
        // border: 1px solid #6e757e;
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
    }
    &::after {
        content: "";
        display: block;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #6e757e;
        // border: 1px solid #6e757e;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
    }

    .select-bottom {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-top: 10px;
        .btn1 {
            width: 60px;
            height: 32px;
            background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
            border-radius: 4px;
            line-height: 32px;
            text-align: center;
            color: #ffffff;
            font-size: 14px;
            cursor: pointer;
            margin-right: 10px;
            margin-left: 26px;
        }
        .btn2 {
            width: 82px;
            height: 32px;
            border-radius: 4px;
            border: 1px solid #aaaaaa;
            line-height: 30px;
            text-align: center;
            color: #ffffff;
            font-size: 14px;
            cursor: pointer;
            margin-right: 10px;
        }
    }
}
::v-deep .el-checkbox__label {
    font-size: 12px !important;
    padding-left: 8px !important;
    color: #ffffff !important;
}
</style>
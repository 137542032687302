var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[{
  [_vm.classNameActive]: _vm.enabled,
  [_vm.classNameDragging]: _vm.dragging,
  [_vm.classNameResizing]: _vm.resizing,
  [_vm.classNameDraggable]: _vm.draggable,
  [_vm.classNameResizable]: _vm.resizable
}, _vm.className],style:(_vm.style),on:{"mousedown":function($event){$event.stopPropagation();return _vm.elementMouseDown.apply(null, arguments)},"touchstart":_vm.elementTouchDown}},[_vm._l((_vm.actualHandles),function(handle){return _c('div',{key:handle,class:[_vm.classNameHandle, _vm.classNameHandle + '-' + handle],style:({display: _vm.enabled ? 'block' : 'none'}),on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleDown(handle, $event)},"touchstart":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleTouchDown(handle, $event)}}},[_vm._t(handle)],2)}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
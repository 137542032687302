<!-- @Author: Yu_Bo 2023-09-25 09:38:30-->
<template>
    <div class="create_card" :class="isFull ? 'card_box' : ''">
        <div class="card_main" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
            <!-- 上 -->
            <div class="main_header">
                <video-header :isFull="isFull" @full="setFull" @ratio="selfAdaption"></video-header>
            </div>
            <!-- 下 -->
            <div class="main_content" v-if="!loading">
                <!-- 左 -->
                <div class="video_left" v-if="templateFrom==1">
                    <video-left></video-left>
                </div>
                <!-- 左 -->
                <div class="con_left">
                    <!-- 左 名片场景 -->
                    <div class="left_left" :style="[sceneStyle]" v-if="templateFrom==2">
                        <cadr-scene @open="sceneOpen"></cadr-scene>
                    </div>
                    <!-- 左 视频场景 -->
                    <div class="middle_left" :style="[sceneStyle]" v-if="templateFrom==1">
                        <img v-if='sceneShow' @click="sceneOpen(false)" class="switch_icon_img" src="@/assets/images/createvideo/left_close.png" alt="">
                        <img v-else class="switch_icon_img" @click="sceneOpen(true)" src="@/assets/images/createvideo/left_open.png" alt="">
                        <video-scene></video-scene>
                    </div>
                    <!-- 右 -->
                    <div class="left_right" :style="[pageStyle]">
                        <!-- 上 -->
                        <div class="right_top">
                            <video-top @self="selfAdaption" @scroll="cardScroll"></video-top>
                        </div>
                        <!-- 中 -->
                        <div class="right_middle" :style="[middleStyle]">
                            <video-content ref="content"></video-content>
                        </div>
                        <!-- 下 -->
                        <div class="right_bottom" :style="[botStyle]">
                            <img v-if='bottomShow' @click="bottomOpen(false)" class="bottom_icon_img" src="@/assets/images/createvideo/bot_close.png" alt="">
                            <img v-else class="bottom_icon_img" @click="bottomOpen(true)" src="@/assets/images/createvideo/bot_open.png" alt="">
                            <editor></editor>
                        </div>
                    </div>
                </div>
                <!-- 右 -->
                <div class="con_right">
                    <video-right></video-right>
                </div>
            </div>
        </div>
        <!-- 切换宽高比 -->
        <point-ratio ref="ratio" @ratio="selfAdaption"></point-ratio>
    </div>
</template>

<script>
import { mapState } from "vuex"
import VideoHeader from './components/videoHeader/index'  // 顶部
import VideoRight from '@/components/video_right/index.vue'  // 右侧菜单
import VideoTop from './components/video_top/index'  // 编辑区顶部
import VideoContent from './components/video_content/index'  // 编辑区
import Editor from '@/components/editor/index.vue'  // 中间底部编辑
import VideoLeft from './components/video_left/index' // 左侧菜单
import PointRatio from './components/point_ratio/index'  // 切换宽高比
import CadrScene from './components/card_scene/index'  // 名片场景
import VideoScene from './components/video_scene/index'  // 视频场景
export default {
    components: { VideoHeader, VideoRight, VideoTop, CadrScene, VideoContent, Editor, VideoScene, VideoLeft, PointRatio },
    props: {},
    data() {
        return {
            // 是否全屏幕
            isFull: false,
            loading: false,
        };
    },

    computed: {
        ...mapState({
            sceneShow: state => state.videoCreate.sceneShow,
            bottomShow: state => state.videoCreate.bottomShow,
            templateFrom: state => state.videoCreate.template_from,//1视频 2名片
        }),
        // 场景宽带
        sceneStyle() {
            return {
                width: this.sceneShow ? '220px' : '150px'
            }
        },
        // 编辑区域宽
        pageStyle() {
            return {
                width: this.sceneShow ? 'calc(100% - 224px)' : 'calc(100% - 124px)'
            }
        },
        // 操作区高
        middleStyle() {
            return {
                width: '100%',
                height: this.bottomShow ? 'calc(100% - 330px)' : 'calc(100% - 110px)',
                padding: '0 10px 10px 0',
            }
        },
        // 底部高
        botStyle() {
            return {
                height: this.bottomShow ? '270px' : '42px'
            }
        },
    },

    watch: {},

    created() {
        if (this.$route.params) {
            this.id = this.$route.params.id
        }
        this.getInfo()
    },

    mounted() { },

    methods: {

        // 设置为全屏幕
        setFull() {
            this.isFull = !this.isFull
            setTimeout(() => {
                this.$refs.content.getSize()
            }, 10)
            this.$store.commit('videoCreate/SET_IS_FULL', this.isFull)
        },
        // 场景展开
        sceneOpen() {
            this.$store.commit('videoCreate/SET_SCENE_SHOW', !this.sceneShow)
        },
        // 底部展开
        bottomOpen(val) {
            this.$store.commit('videoCreate/SET_BOTTOM_SHOW', val)
        },
        // 自适应屏幕
        selfAdaption() {
            this.$refs.content.getSize()

            // 切换弹窗
            this.$refs.ratio.openDialogBtn(1)
        },
        // 滚动条位置
        cardScroll() {
            this.$refs.content.scrollBar()
        },
        getInfo() {
            this.loading = true
            this.$digitalApi.creatCardInfo(this.id).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.$store.commit('videoData/SET_DATA', res.result)
                    this.$store.commit('videoCreate/SET_TEMPLATE_FROM', res.result.template_from)
                    let arr = JSON.parse(res.result.config)
                    this.$store.commit('videoData/SET_SECNE_LIST', JSON.stringify(arr.config))
                    this.$store.commit('videoData/SET_SUBTITLE_SHOW', arr.config[0].data?.subtitleShow || false)
                    this.$store.commit('videoCreate/SET_SIZE_RATIO', arr.aspect_ratio)
                    this.$store.commit('videoCreate/SET_BIT_RATE', arr.bit_rate)
                    this.$store.commit('videoCreate/SET_RESOLUTION_RATIO', arr.resolution)
                    this.$store.commit('videoCreate/SET_FPS_RATE', arr.fps)

                    let val = arr.config[0].data || {}
                    if (val.ttsAudio) {
                        this.$store.commit('voiceInfo/SET_CURRENT', 2)
                    } else {
                        this.$store.commit('voiceInfo/SET_CURRENT', 1)
                    }
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
    },
    beforeDestroy() {
        // 清空数据
        this.$store.dispatch('videoCreate/initialization')
        this.$store.dispatch('videoData/initialization')
        this.$store.dispatch('voiceInfo/initialization')
    },
}
</script>
<style lang='scss' scoped>
.create_card {
    width: 100%;
    height: 100%;
    min-height: 700px;
    padding: 15px;
    background: #101314;
    border-radius: 10px;

    .card_main {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .main_header {
            width: 100%;
            height: 60px;
            background: #1b1d1f;
        }

        .main_content {
            width: 100%;
            height: calc(100% - 64px);
            display: flex;
            justify-content: space-between;
            .video_left {
                width: 70px;
                height: 100%;
                background: #1b1d1f;
                position: relative;
                z-index: 10;
                margin-right: 5px;
            }

            .con_left {
                position: relative;
                z-index: 9;
                width: calc(100% - 84px);
                height: 100%;
                display: flex;
                justify-content: space-between;

                .left_left {
                    height: 100%;
                    background: #1b1d1f;
                    transition: 0.3s;
                }
                .middle_left {
                    position: relative;
                    height: 100%;
                    background: #1b1d1f;
                    transition: 0.3s;

                    .switch_icon_img {
                        cursor: pointer;
                        position: absolute;
                        right: -10px;
                        top: 50%;
                        width: 10px;
                        height: 50px;
                        margin-top: -25px;
                    }
                }
                .left_right {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    transition: 0.3s;

                    .right_top {
                        width: 100%;
                        height: 60px;
                        background: #1b1d1f;
                    }

                    .right_middle {
                        transition: 0.3s;
                    }

                    .right_bottom {
                        position: relative;
                        width: 100%;
                        background: #1b1d1f;
                        transition: 0.3s;

                        .bottom_icon_img {
                            cursor: pointer;
                            position: absolute;
                            left: 50%;
                            top: -10px;
                            width: 50px;
                            height: 10px;
                            margin-left: -25px;
                        }
                    }
                }
            }

            .con_right {
                width: 80px;
                height: 100%;
                background: #1b1d1f;
                position: relative;
                z-index: 10;
            }
        }
    }
}

.card_box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 0;
}</style>
<template>
    <div>
        <el-dialog title="字幕修改" :visible.sync="dialogVisible" width="700px" append-to-body :close-on-click-modal="false">
            <div class="content">
                <div class="item" :class="{active:index==current}" :id="'id'+index" v-for="item,index in list" :key="index">
                    <div class="time">
                        {{item.start}} ~ {{item.end}}
                    </div>
                    <div class="text">
                        <el-input :ref="'input' + index" size="mini" v-if="index==current" v-model="item.content" placeholder="请输入字幕"></el-input>
                        <div v-else @click="inputShow(index)">{{item.content}}</div>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false,
            current: '',
            list: []
        };
    },
    methods: {
        open(val, index) {
            this.list = JSON.parse(JSON.stringify(val))
            this.dialogVisible = true

            // 打开弹窗 滚动到指定位置选中
            this.$nextTick(() => {
                window.location.hash = '#id' + index;
                this.inputShow(index)
            })

        },
        confirm() {
            this.$store.commit('videoData/SET_SUBTITLE_LIST', this.list)
            this.dialogVisible = false
        },
        inputShow(index) {
            this.current = index
            // input选中
            this.$nextTick(() => {
                eval("this.$refs.input" + index)[0].focus()
            })
        },
    },

};
</script>

<style scoped lang="scss">
.content {
    width: 100%;
    height: 350px;
    background-color: #f7f9fb;
    overflow-y: auto;
    color: #999999;
    font-size: 12px;
    padding: 10px;
    user-select: none;
    .item {
        .time {
            line-height: 20px;
        }
        .text {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 28px;
        }
    }
    .item.active {
        .time {
            color: #333333;
        }
        .text {
            color: #2b4afe;
        }
    }
    .item:hover {
        .text {
            color: #2b4afe;
        }
    }
}

.content::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
}
.content::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.3);
}
.content::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
}

::v-deep .el-dialog__body {
    padding: 10px 20px;
}
::v-deep .el-input__inner {
    background-color: #f7f9fb;
    border: none;
    padding: 0;
    color: #2b4afe;
}
</style>

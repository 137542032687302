<!-- @Author: Yu_Bo -->
<template>
    <div class='con_btn'>
        <el-tooltip effect="dark" content="图层" placement="top">
            <div class="btn_img" v-show="type != 'background'" @click="imgBtn('img')">
                <img src="@/assets/images/icon/icon1.png" alt="">
            </div>
        </el-tooltip>
        <el-tooltip effect="dark" :content="type == 'avatar' ? '应用到全局' : '复制到其他场景'" placement="top">
            <div class="btn_img" v-show="type == 'avatar' || type == 'image' || type == 'video' || type=='dthuman'" @click="imgBtn('copy')">
                <img src="@/assets/images/icon/icon3.png" alt="">
            </div>
        </el-tooltip>
        <el-tooltip effect="dark" content="删除" placement="top">
            <div class="tips-box" slot="content">
                <div class="tips-top">
                    <img class="icon" src="@/assets/images/digital/warn.png" alt="">
                    <div class="text">注意事项</div>
                </div>
                <div class="tips-main">
                    <img class="tips-img" src="@/assets/images/digital/avatar.png" alt="">
                    <div class="line1"></div>
                    <div class="line2"></div>
                    <div class="text1">留有间距</div>
                    <div class="text2">不露牙齿</div>
                </div>
                <div class="tips-bottom">
                    请勿上传敏感、违规图片
                </div>
            </div>
            <div class="btn_img" v-show="type == 'avatar'">
                <el-upload class="avatar-uploader" action="#" :accept="imgAccept" :show-file-list="false" :http-request="uploadimg">
                    <img src="@/assets/images/icon/icon7.png" alt="">
                </el-upload>
            </div>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看" placement="top">
            <div class="btn_img" v-show="type == 'image' || type == 'video' || type == 'avatar'">
                <img src="@/assets/images/icon/icon4.png" alt="" @click="imgBtn('view')">
            </div>
        </el-tooltip>
        <!-- <el-tooltip effect="dark" content="放大" placement="top">
            <div class="btn_img" v-show="type == 'dthuman' && !reduce" @click="imgBtn('amplify')">
                <img src="@/assets/images/icon/icon4.png" alt="">
            </div>
        </el-tooltip>
        <el-tooltip effect="dark" content="缩小" placement="top">
            <div class="btn_img" v-show="type == 'dthuman' && reduce" @click="imgBtn('reduce')">
                <img src="@/assets/images/icon/icon6.png" alt="">
            </div>
        </el-tooltip> -->
        <el-tooltip effect="dark" content="删除" placement="top">
            <div class="btn_img" v-show="type != 'avatar'" @click="imgBtn('del')">
                <img src="@/assets/images/icon/icon5.png" alt="">
            </div>
        </el-tooltip>
        <!-- 图层设置 -->
        <div class="box_index" v-if="zIndexShow" @mouseleave="closeIndexBtn">
            <span @click="zIndexBtn('top')">置顶</span>
            <span @click="zIndexBtn('bot')">置底</span>
            <span @click="zIndexBtn('top_one')">上移一层</span>
            <span @click="zIndexBtn('bot_one')">下移一层</span>
        </div>
        <!-- 图片查看 -->
        <img-preview ref="img"></img-preview>
        <!-- 视频查看 -->
        <video-preview ref="video"></video-preview>
        <!-- 删除提示 -->
        <point-tip ref='pointTip' @confirm='confirmPoin' :type='tip_type' :text='tip_text' :cancelShow='true' btnText='确 定'></point-tip>
    </div>
</template>

<script>
import { mapState } from "vuex"
import ImgPreview from '@/components/img_preview/index.vue'
import VideoPreview from '@/components/video_preview/index.vue'
import PointTip from '@/components/point_out/point_tip.vue'//提示弹框
export default {
    components: { ImgPreview, VideoPreview, PointTip },
    props: {
        type: {
            type: String,
            default: '',
        },
        infoIndex: {//当前编辑的下标
            type: [Number, String],
            default: 0,
        },
    },
    data() {
        return {
            //图层设置
            zIndexShow: false,
            ruleType: 1,
            expressionStart: 1,
            expressionShow: 2,
            expressionValue: 10,
            // 提示
            tip_type: 1,
            tip_text: '',
            is_img: ['image/png', 'image/jpg', 'image/jpeg'],
            imgAccept: 'image/jpg,image/jpeg,image/png',
        }
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
        }),
        reduce() {
            // 主播 true放大  false缩小
            if (this.sceneList.length && this.type == 'dthuman') {
                var reduce = this.sceneList[this.sceneIndex].scenes_conf[this.infoIndex].reduce
                return reduce
            } else {
                return true
            }
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 上传图片
        uploadimg(item) {
            var self = this
            // 限制图片格式
            const isJPG = this.is_img.includes(item.file.type)
            // 限制图片大小
            const isLt2M = item.file.size / 1024 / 1024 <= 3;
            if (!isJPG) {
                self.$errMsg('上传图片只能是 JPG ,JPEG, PNG 格式!');
            }
            if (!isLt2M) {
                self.$errMsg('上传图片大小不能超过 2MB!');
            }
            if (isJPG && isLt2M) {
                let newFile = new FormData()
                // newFile.append('type', 4); // type 图片大小限制 1：10M以内；2：15M；3：30M；4：3M
                newFile.append('file', item.file);
                const loading = this.$loading({
                    lock: true,
                    text: '上传中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                self.$creationApi.uploadAvatar(newFile).then(res => {
                    if (res.code == 1000) {
                        var arr = JSON.parse(JSON.stringify(this.sceneList))
                        var str = arr[this.sceneIndex].scenes_conf
                        str[this.infoIndex].url = res.result.url
                        var params = {
                            type: 'conf',
                            info: str
                        }
                        this.$store.dispatch('videoData/modifyProduct', params)
                        loading.close();
                    } else {
                        self.$errMsg(res.message)
                        loading.close();
                    }
                })
            }
        },
        // 点击
        imgBtn(val) {
            // 图层
            if (val == 'img') {
                this.zIndexShow = !this.zIndexShow
                return
            }
            // 显示设置
            if (val == 'display') {
                this.showSet = !this.showSet
                return
            }
            // 应用到全局--复制到其他场景
            if (val == 'copy') {
                if (this.type == 'dthuman') {
                    this.tip_type = 1
                    this.tip_text = '确定要应用到全局吗?'
                    this.$refs.pointTip.openDialog()
                } else {
                    this.tip_type = 2
                    this.tip_text = '确定要复制到其他场景吗?'
                    this.$refs.pointTip.openDialog()
                }
                return
            }
            // 查看
            if (val == 'view') {
                var url = this.sceneList[this.sceneIndex].scenes_conf[this.infoIndex].url
                if (this.type == 'video') {
                    this.$refs.video.openDialogBtn(url)
                } else {
                    this.$refs.img.openDialog(url)
                }
                return
            }
            // 放大
            if (val == 'amplify') {
                var arr = JSON.parse(JSON.stringify(this.sceneList))
                var str = arr[this.sceneIndex].scenes_conf
                var obj = str[this.infoIndex]
                var style = {
                    width: Math.floor(obj.style.width * 2),
                    height: Math.floor(obj.style.height * 2),
                    top: obj.style.top - Math.floor(obj.style.height / 2),
                    left: obj.style.left - Math.floor(obj.style.width / 2)
                }
                obj.style = style
                obj.reduce = true
                var params = {
                    type: 'conf',
                    info: str
                }
                this.$store.dispatch('videoData/modifyProduct', params)
                var info = {
                    type: 'amplify',
                    style: style
                }
                this.$emit('edit', info)
                return
            }
            // 缩小
            if (val == 'reduce') {
                var arr = JSON.parse(JSON.stringify(this.sceneList))
                var str = arr[this.sceneIndex].scenes_conf
                var obj = str[this.infoIndex]
                var style = {
                    width: Math.floor(obj.style.width / 2),
                    height: Math.floor(obj.style.height / 2),
                    top: obj.style.top + Math.floor(obj.style.height / 4),
                    left: obj.style.left + Math.floor(obj.style.width / 4)
                }
                obj.style = style
                obj.reduce = false
                var params = {
                    type: 'conf',
                    info: str
                }
                this.$store.dispatch('videoData/modifyProduct', params)
                var info = {
                    type: 'reduce',
                    style: style
                }
                this.$emit('edit', info)
                return
            }
            // 删除
            if (val == 'del') {
                var arr = JSON.parse(JSON.stringify(this.sceneList))
                var str = arr[this.sceneIndex].scenes_conf
                str.splice(this.infoIndex, 1)
                this.$emit('del')
                var params = {
                    type: 'conf',
                    info: str
                }
                this.$store.dispatch('videoData/modifyProduct', params)
                this.$store.commit('videoData/SET_SHOW_ID', null)
                return
            }
        },
        confirmPoin(val) {
            var arr = JSON.parse(JSON.stringify(this.sceneList))
            var obj = arr[this.sceneIndex].scenes_conf[this.infoIndex]
            if (val == 1) {
                // 设置主播
                arr.forEach(ite => {
                    if (ite.scenes_conf.length) {
                        var imgAnchor = ite.scenes_conf.find(it => it.type == 'dthuman')
                        var imgAnchorIndex = ite.scenes_conf.findIndex(it => it.type == 'dthuman')
                        obj.id = new Date().getTime()
                        if (imgAnchor) {
                            ite.scenes_conf[imgAnchorIndex] = obj
                        } else {
                            ite.scenes_conf.unshift(obj)
                        }
                    } else {
                        ite.scenes_conf.push(obj)
                    }
                })
            } else {
                // 设置其他
                arr.forEach((item, index) => {
                    if (this.sceneIndex == index) {
                        // 判断场景是否为当前场景
                    } else {
                        item.scenes_conf.push(obj)
                    }
                })
            }
            var params = {
                type: 'scen',
                info: arr
            }
            this.$store.dispatch('videoData/modifyProduct', params)
        },
        // 图层
        async zIndexBtn(val) {
            var index = null
            var arr = JSON.parse(JSON.stringify(this.sceneList))
            var str = arr[this.sceneIndex].scenes_conf
            if (val == 'top') {//置顶
                if (this.infoIndex == str.length - 1) {
                    return
                }
                var item = str[this.infoIndex]
                str.splice(this.infoIndex, 1)
                str.push(item)
                index = str.length - 1
            }
            if (val == 'bot') {//置底
                if (this.infoIndex == 0) {
                    return
                }
                var imgBackground = str.find(item => item.type == 'background')
                var backgroundIndex = str.findIndex(item => item.type == 'background')
                if (imgBackground && this.infoIndex == backgroundIndex + 1) {
                    return
                }
                var item = str[this.infoIndex]
                str.splice(this.infoIndex, 1)
                str.splice(backgroundIndex + 1, 0, item)
                index = 0
            }
            if (val == 'top_one') {//上移一层
                if (this.infoIndex == str.length - 1) {
                    return
                }
                var item = str[this.infoIndex]
                str[this.infoIndex] = str[this.infoIndex + 1]
                str[this.infoIndex + 1] = item
                index = this.infoIndex + 1
            }
            if (val == 'bot_one') {//下移一层
                if (this.infoIndex == 0) {
                    return
                }
                var imgBackground = str.find(item => item.type == 'background')
                var backgroundIndex = str.findIndex(item => item.type == 'background')
                if (imgBackground && this.infoIndex == backgroundIndex + 1) {
                    return
                }
                var item = str[this.infoIndex]
                str[this.infoIndex] = str[this.infoIndex - 1]
                str[this.infoIndex - 1] = item
                index = this.infoIndex - 1
            }
            var params = {
                type: 'conf',
                info: str
            }
            await this.$store.dispatch('videoData/modifyProduct', params)
            this.$emit('edit', index)
        },
        // 图层移出
        closeIndexBtn() {
            this.zIndexShow = false
        },
        // 显示设置=确定按钮
        setClose() {
            this.showSet = !this.showSet
        },
        // 隐藏=显示设置
        closeShowSet() {
            this.showSet = false
        },
    },
}
</script>

<style lang='scss' scoped>
.con_btn {
    position: relative;
    padding: 0 4px;
    display: flex;
    align-items: center;

    .btn_img {
        width: 28px;
        min-width: 28px;
        height: 28px;
        padding: 4px;
        margin: 0 1px;

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .btn_img:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    /*  */
    .box_index {
        position: absolute;
        top: 30px;
        left: 0px;
        z-index: 1;
        width: 60px;
        padding: 4px 0;
        background: #ffffff;
        box-shadow: 0px 2px 6px 0px rgba(108, 108, 108, 0.5);
        border-radius: 2px;

        span {
            display: block;
            width: 100%;
            line-height: 24px;
            text-align: center;
            font-size: 12px;
            color: #333333;
            cursor: pointer;
        }

        span:hover {
            background: #f3f3f3;
        }
    }

    /*  */
    .display_set {
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 1;
        width: 322px;
        background: #2b3036;
        box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.43);
        border-radius: 6px;
        padding: 10px 20px 20px;

        .set_radio {
            display: flex;
            align-items: center;
            padding: 8px 0;

            .name {
                font-size: 13px;
                color: rgba(255, 255, 255, 0.9);
                line-height: 18px;
                padding-right: 15px;
            }

            .radio {
                display: flex;
                align-items: center;
            }
        }

        .text {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 13px;
            line-height: 40px;
            color: rgba(255, 255, 255, 0.9);

            .input {
                width: 46px;
                margin: 0 12px;
            }
        }

        .btn {
            margin-top: 12px;
        }

        ::v-deep .input .el-input__inner {
            width: 100%;
            height: 24px;
            line-height: 24px;
            background: #505963;
            border-radius: 4px;
            border: 1px solid #505963;
            color: rgba(255, 255, 255, 0.9);
            padding: 0 10px;
        }

        ::v-deep .radio .el-radio__input.is-checked + .el-radio__label {
            color: rgba(255, 255, 255, 0.9);
        }
    }
}
.tips-box {
    width: 155px;
    height: 115px;
    opacity: 0.8;
    .tips-top {
        display: flex;
        align-items: center;
        .icon {
            width: 12px;
            height: 12px;
        }
        .text {
            font-size: 12px;
            color: #ffffff;
            padding-left: 6px;
        }
    }
    .tips-main {
        width: 100%;
        height: 75px;
        margin-top: 8px;
        position: relative;
        font-size: 12px;
        color: #ffffff;
        .tips-img {
            width: 75px;
            height: 75px;
        }
        .line1 {
            position: absolute;
            width: 30px;
            height: 1px;
            border-bottom: 1px dashed #ffffff;
            left: 68px;
            top: 13px;
        }
        .line2 {
            position: absolute;
            width: 58px;
            height: 1px;
            border-bottom: 1px dashed #ffffff;
            left: 40px;
            top: 50px;
        }
        .text1 {
            position: absolute;
            top: 5px;
            left: 100px;
        }
        .text2 {
            position: absolute;
            top: 42px;
            left: 100px;
        }
    }
    .tips-bottom {
        font-size: 12px;
        color: #ffffff;
        margin-top: 6px;
    }
}
</style>

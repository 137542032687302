<template>
    <div class="">
        <div class="video_table" :class="!navInfo.navFlag ? 'video_table_close' : ''">
            <!-- 标题 -->
            <div class="table_title">
                <i class="el-icon-close" @click="close"></i>
                <div class="title">背景</div>
            </div>
            <div class="table_content">
                <!-- tab切换 -->
                <div class="tabs">
                    <div class="item" :class="{ 'active': tabValue == item.value }" v-for="(item, idx) in tabs" :key="idx" @click="tabsChange(item)">
                        <div class="txt">{{ item.label }}</div>
                        <div class="bar"></div>
                    </div>
                </div>

                <!-- 下方的筛选项目连带搜索 -->
                <div class="search-box" v-if="tabValue != 1">
                    <div class="select_box">
                        <el-select popper-class="select_popper_dark" v-model="category" placeholder="请选择" size="small" @change="selectBtn">
                            <el-option v-for="item in category_list" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search_inp">
                        <el-input placeholder="请输入关键字" prefix-icon="el-icon-search" size="small" v-model="name" clearable @input="inputBtn" @clear="reset" @keyup.enter.native="reset">
                        </el-input>
                    </div>

                </div>
                <!-- 纯色背景 -->
                <div class="single-bg" v-if="tabValue != 1">
                    <div class="txt">纯色背景</div>
                    <div class="list">
                        <div class="item" @click="colorChange('transparent')">
                            <img :src="require('@/assets/images/workben/color.png')" alt="">
                        </div>
                        <div class="item" :style="{ background: item }" v-for="(item, index) in singleBg" :key="index" @click="colorChange(item)"></div>
                        <div class="item">
                            <el-color-picker v-model="color" @change="colorChange"></el-color-picker>
                        </div>
                    </div>
                </div>
                <!-- 比例、类型 -->
                <div class="type-box" v-if="tabValue != 1">
                    <div class="cate-list">
                        <div class="item" :class="{ 'active': proportionValue == item.value }" v-for="(item, i) in proportion" :key="i" @click="proportionChange(item.value)">{{ item.label }}
                        </div>
                    </div>
                    <div class="type-line"></div>
                    <div class="cate-list">
                        <div class="item" :class="{ 'active': typeValue == item.value }" v-for="(item, i) in typelist" :key="i" @click="typeChange(item)">{{ item.label }}</div>
                    </div>
                </div>
                <!-- 列表 -->
                <div class="piclist" v-if="tabValue != 1">
                    <div class="contentboxs scroll_bar" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <el-checkbox-group v-model="checkList">
                            <div class="item openHover" v-for="(item, x) in list" :key="x">
                                <div class="item-pic">
                                    <div class="picbox">
                                        <el-image style="width: 96px; height: 70px" :src='item.image' fit="contain">
                                            <div slot="error" class="image-slot">
                                                <img src="@/assets/images/icon/img.png" alt="">
                                            </div>
                                        </el-image>
                                    </div>
                                    <!-- 左上角的眼睛 -->
                                    <div class="preview" @click="look(item)">
                                        <i class="el-icon-view color"></i>
                                    </div>
                                    <!-- 下方的插入按钮 -->
                                    <div class="insert">
                                        <div class="button" @click="selectMaterials(item)">插入</div>
                                    </div>
                                    <!-- 左边的选中框 -->
                                    <!-- <div class="selectCheck">
                    <el-checkbox :label="item.id"><br /></el-checkbox>
                  </div> -->
                                </div>
                            </div>
                        </el-checkbox-group>
                        <!-- 没有更多 -->
                        <Loading :loading="loading" :list="list" :page="page" :totalPage="last_page">
                        </Loading>
                    </div>
                </div>
            </div>
        </div>

        <!-- 预览图片 -->
        <ImgPreview ref="ImgPreview"></ImgPreview>
        <VideoPreview ref="VideoPreview"></VideoPreview>

    </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    components: {
        ImgPreview: resolve => require(['@/components/img_preview/index'], resolve),
        VideoPreview: resolve => require(['@/components/video_preview/index'], resolve),
        Loading: (resolve) => require(['@/components/loading/index'], resolve)
    },
    props: {
        navInfo: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            tabs: [
                {
                    label: '全部',
                    value: ''
                },
                {
                    label: '最近使用',
                    value: '1'
                },
            ],
            tabValue: '',
            category: '', // 下拉框选定的值
            name: '',
            category_list: [], // 分类列表
            proportion: [
                {
                    label: '9:16',
                    value: 2
                },
                {
                    label: '16:9',
                    value: 1
                },
            ], // 比例
            proportionValue: 2,
            typelist: [ // 类型
                {
                    label: '全部',
                    value: ''
                },
                {
                    label: '图片',
                    value: '1'
                },
                {
                    label: '视频',
                    value: '2'
                }
            ],
            typeValue: '',
            singleBg: [ // 纯色背景
                '#EB5757',
                '#F1994A',
                '#F1CA4B',
                '#6FCF97',
                '#2F80ED',
                '#FFFFFF',
            ],
            color: null,
            colorValue: '',
            loading: false,
            page: 0,
            last_page: 1, // 总页数
            limit: 20,
            list: [],
            checkList: [],

        }
    },
    created() {
        // this.getList()
        this.getCate()
    },
    watch: {

    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
        }),
        background() {
            return this.sceneList[this.sceneIndex].background || {}
        },
        searchForm() {
            return {
                page: this.page,
                limit: this.limit,
                name: this.name,
                type: this.typeValue,
                category_id: this.category,
                ratio: this.proportionValue,
            }
        },
    },
    methods: {
        // 关闭
        close() {
            let obj = {
                ...this.navInfo,
                navFlag: false,
            }
            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', obj)
        },
        // tab切换
        tabsChange(item) {
            this.tabValue = item.value
            this.reset()
        },
        inputBtn() { },
        // 获取分类
        getCate() {
            this.$workbenApi.backgroup_cate().then(res => {
                if (res.code === 1000) {
                    this.category_list = res.result.cat_list
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 分类
        selectBtn() {
            this.reset()
        },
        // 比例
        proportionChange(val) {
            this.proportionValue = val
            this.reset()
        },
        // 类型
        typeChange(item) {
            this.typeValue = item.value
            this.reset()
        },
        // 颜色
        colorChange(val) {
            this.colorValue = val
            this.background.type = 'color'
            this.background.url = ''
            this.background.color = val
        },
        // 加载更多
        load() {
            if (this.loading || this.page === this.last_page || this.last_page === 0) return
            this.page++
            this.getList()
        },
        // 重置
        reset() {
            this.list = []
            this.page = 1
            this.getList()
        },
        // 列表
        getList() {
            this.loading = true
            this.$workbenApi.backgroup_list({
                ...this.searchForm
            }).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = [...this.list, ...res?.result?.data] || []
                    this.last_page = res?.result?.last_page
                    this.page = res?.result?.current_page ?? 1
                } else {
                    this.$message.error(res.message)
                }
            })
        },

        // 插入素材
        selectMaterials(info) {
            this.background.type = 'image'
            this.background.url = info.image
        },
        // 预览
        look(item) {
            if (item.type == 1) {
                // 图片
                this.$refs.ImgPreview.openDialog(item.source_url)
            }
            if (item.type == 2) {
                // 视频
                this.$refs.VideoPreview.openDialogBtn(item.source_url)
            }
        },

    }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";

.tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #454a50;
    margin-bottom: 10px;

    .item {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .txt {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 400;
            color: #ffffff;
        }

        .bar {
            width: 28px;
            height: 2px;
            background: transparent;
            border-radius: 1px;
            margin-top: 5px;
        }
    }

    .active {
        .txt {
            font-weight: 500;
        }

        .bar {
            background: #ffffff;
        }
    }
}

.search-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .select_box {
        width: 88px;
        background: #445265;
        border-radius: 4px 0px 0px 4px;

        ::v-deep .el-select {
            .el-input__inner {
                border-radius: 4px 0px 0px 4px;
                background: #445265;
                border-color: transparent;
                color: #ffffff;
                padding: 0 0 0 6px;
            }

            .el-input__suffix {
                right: 0px;
            }
        }
    }

    .search_inp {
        flex: 1;

        ::v-deep .el-input__inner {
            color: #ffffff;
            background: #272c32;
            border-radius: 0px 4px 4px 0px;
            border-color: transparent;
        }
    }
}

.single-bg {
    margin-bottom: 20px;

    .txt {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
    }

    .list {
        display: flex;
        align-items: center;
        margin-top: 12px;

        .item {
            width: 38px;
            height: 26px;
            cursor: pointer;

            // 控制其宽高
            /deep/ .el-color-picker .el-color-picker__trigger {
                width: 38px;
                height: 26px;
                border-radius: 0;
                padding: 0;
                border: none;
            }
        }
    }
}

.type-box {
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    .cate-list {
        display: flex;

        .item {
            width: 42px;
            height: 24px;
            background: #3c4148;
            border-radius: 2px;
            margin-right: 10px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 24px;
            text-align: center;
            cursor: pointer;
        }

        .active {
            background: #252f69;
            border: 1px solid #2e4bf2;
        }
    }
}

.type-line {
    width: 1px;
    height: 18px;
    border: 1px solid #6f777f;
    margin: 0 20px 0 10px;
}

.contentboxs {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 500px);
    margin-right: -8px;
}
</style>

<template>
    <div class="">
        <div class="video_table" :class="!navInfo.navFlag ? 'video_table_close' : ''">
            <!-- 标题 -->
            <div class="table_title">
                <i class="el-icon-close" @click="close"></i>
                <div class="title">音乐</div>
            </div>
            <div class="table_content">
                <!-- 下方的筛选项目连带搜索 -->
                <div class="search-box">
                    <div class="search_inp">
                        <el-input placeholder="请输入音乐名称" prefix-icon="el-icon-search" size="small" v-model="name" clearable @clear="expandClose" @keyup.enter.native="reset">
                        </el-input>
                    </div>
                </div>
                <div class="audiolist">
                    <div class="contentbox scroll_bar" v-if="category" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="">
                            <!-- 分类 -->
                            <div class="list-title">
                                <div class="title">{{ categoryInfo.name }}</div>
                                <div class="icon" @click="expandClose()">
                                    <i class="el-icon-arrow-left"></i>
                                </div>
                            </div>
                            <!-- 展开 -->
                            <el-checkbox-group v-model="checkList">
                                <div class="item openHover" v-for="(item, x) in searchlist" :key="x" @mouseenter="mouseenter(item, x)" @mouseleave="mouseleave(item, x)">
                                    <div class="item-pic">
                                        <div class="picbox">
                                            <div class="img" @click="playBtn(item, x)">
                                                <img class="img1" v-if="item.audioflag" :src="require('@/assets/images/workben/y_3.png')" alt="">
                                                <template v-else>
                                                    <img class="pause" v-if="item.audioStatus == 'pause'" :src="require('@/assets/images/workben/y_1.png')" alt="">
                                                    <img class="play" v-else :src="require('@/assets/images/workben/y_2.png')" alt="">
                                                    <audio :ref="'audioRef' + item.id" :src="item.url" hidden></audio>
                                                </template>

                                            </div>
                                            <div class="info">
                                                <div class="title">{{ item.name }}</div>
                                                <div class="time">{{ item.duration }}s</div>
                                            </div>
                                        </div>
                                        <!-- 插入按钮 -->
                                        <div class="insert">
                                            <div class="button" @click="selectMaterials(item,1)">插入</div>
                                        </div>
                                        <!-- 应用到全局按钮 -->
                                        <div class="insert_two">
                                            <div class="button" @click="selectMaterials(item,2)">应用到全局</div>
                                        </div>
                                        <!-- 左边的选中框 -->
                                        <!-- <div class="selectCheck">
                                            <el-checkbox :label="item.id"><br /></el-checkbox>
                                        </div> -->
                                    </div>
                                </div>
                            </el-checkbox-group>
                            <!-- 没有更多 -->
                            <Loading :loading="loading" :list="searchlist" :page="page" :totalPage="last_page">
                            </Loading>
                        </div>
                    </div>
                    <div class="contentbox scroll_bar" v-else-if="name" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="">
                            <!-- 展开 -->
                            <el-checkbox-group v-model="checkList">
                                <div class="item openHover" v-for="(item, x) in searchlist" :key="x" @mouseenter="mouseenter(item, x)" @mouseleave="mouseleave(item, x)">
                                    <div class="item-pic">
                                        <div class="picbox">
                                            <div class="img" @click="playBtn(item, x)">
                                                <img class="img1" v-if="item.audioflag" :src="require('@/assets/images/workben/y_3.png')" alt="">
                                                <template v-else>
                                                    <img class="pause" v-if="item.audioStatus == 'pause'" :src="require('@/assets/images/workben/y_1.png')" alt="">
                                                    <img class="play" v-else :src="require('@/assets/images/workben/y_2.png')" alt="">
                                                    <audio :ref="'audioRef' + item.id" :src="item.url" hidden></audio>
                                                </template>

                                            </div>
                                            <div class="info">
                                                <div class="title">{{ item.name }}</div>
                                                <div class="time">{{ item.duration }}s</div>
                                            </div>
                                        </div>
                                        <!-- 插入按钮 -->
                                        <div class="insert">
                                            <div class="button" @click="selectMaterials(item,1)">插入</div>
                                        </div>
                                        <!-- 应用到全局按钮 -->
                                        <div class="insert_two">
                                            <div class="button" @click="selectMaterials(item,2)">应用到全局</div>
                                        </div>
                                        <!-- 左边的选中框 -->
                                        <!-- <div class="selectCheck">
                                            <el-checkbox :label="item.id"><br /></el-checkbox>
                                        </div> -->
                                    </div>
                                </div>
                            </el-checkbox-group>
                            <!-- 没有更多 -->
                            <Loading :loading="loading" :list="searchlist" :page="page" :totalPage="last_page">
                            </Loading>
                        </div>
                    </div>
                    <div class="contentbox scroll_bar" v-else v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="" v-for="(ele, i) in list" :key="i">
                            <!-- 分类 -->
                            <div class="list-title">
                                <div class="title">{{ ele.name }}</div>
                                <div class="icon" @click="moreBtn(ele)">
                                    <span>更多<i class="el-icon-arrow-right"></i></span>
                                    <!-- <span v-if="!ele.expand">更多<i class="el-icon-arrow-right"></i></span>
                                    <i class="el-icon-arrow-left" v-else></i> -->
                                </div>
                            </div>
                            <!-- 收起 -->
                            <el-checkbox-group v-model="checkList">
                                <div class="item openHover" v-for="(item, x) in ele.materialMusic" :key="x" @mouseenter="mouseenter(item, x)" @mouseleave="mouseleave(item, x)">
                                    <div class="item-pic">
                                        <div class="picbox">
                                            <div class="img" @click="playBtn(item, x)">
                                                <img class="img1" v-if="item.audioStatus == '' && item.audioflag" :src="require('@/assets/images/workben/y_3.png')" alt="">
                                                <template v-else>
                                                    <img class="pause" v-if="item.audioStatus == 'pause'" :src="require('@/assets/images/workben/y_1.png')" alt="">
                                                    <img class="play" v-else :src="require('@/assets/images/workben/y_2.png')" alt="">
                                                    <audio :ref="'audioRef' + item.id" :src="item.url" hidden></audio>
                                                </template>

                                            </div>
                                            <div class="info">
                                                <div class="title">{{ item.name }}</div>
                                                <div class="time">{{ item.duration }}s</div>
                                            </div>
                                        </div>
                                        <!-- 插入按钮 -->
                                        <div class="insert">
                                            <div class="button" @click="selectMaterials(item,1)">插入</div>
                                        </div>
                                        <!-- 应用到全局按钮 -->
                                        <div class="insert_two">
                                            <div class="button" @click="selectMaterials(item,2)">应用到全局</div>
                                        </div>
                                        <!-- 左边的选中框 -->
                                        <!-- <div class="selectCheck">
                                            <el-checkbox :label="item.id"><br /></el-checkbox>
                                        </div> -->
                                    </div>
                                </div>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {
    mapState
} from "vuex"
export default {
    components: {
        Loading: (resolve) => require(['@/components/loading/index'], resolve)
    },
    props: {
        navInfo: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            name: '',
            category: '',
            loading: false,
            page: 0,
            last_page: 1, // 总页数
            limit: 20,
            list: [],
            checkList: [],
            categoryInfo: {},
            searchlist: [],
        }
    },
    created() {
        this.getList()

    },
    computed: {
        ...mapState({
            currentTime: state => state.videoData.currentTime,//当前播放时间
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            duration: state => state.videoData.duration, //音频总时长
        }),
        searchForm() {
            return {
                page: this.page,
                limit: this.limit,
                category_id: this.category,
                name: this.name
            }
        },
    },
    methods: {
        // 关闭
        close() {
            let obj = {
                ...this.navInfo,
                navFlag: false,
            }

            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', obj)


        },
        //
        inputBtn() { },
        // 加载更多
        load() {
            if (this.loading || this.page === this.last_page || this.last_page === 0) return
            this.page++
            this.getSearchList()

        },
        // 重置
        reset() {
            this.category = ''
            this.searchlist = []
            this.page = 1
            this.getSearchList()
        },
        // 列表
        getList() {
            this.loading = true
            this.$workbenApi.music_cate().then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result
                    this.list.forEach(ele => {
                        ele.materialMusic.forEach(item => {
                            this.$set(item, 'audioflag', true)
                            this.$set(item, 'audioStatus', '')
                        })
                    })
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 列表-带搜索
        getSearchList() {
            this.loading = true
            this.$workbenApi.music_list({
                ...this.searchForm
            }).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.searchlist = [...this.searchlist, ...res?.result?.data] || []
                    this.searchlist.forEach(item => {
                        this.$set(item, 'audioflag', true)
                        this.$set(item, 'audioStatus', '')

                    })
                    this.last_page = res?.result?.last_page
                    this.page = res?.result?.current_page ?? 1
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 更多
        moreBtn(ele) {
            this.categoryInfo = ele
            this.category = ele.id
            this.getSearchList()
        },
        // 收起
        expandClose() {
            this.category = ''
            this.searchlist = []
        },
        // 插入素材
        selectMaterials(info, type) {
            var that = this
            if (type == 1) {
                var arr = JSON.parse(JSON.stringify(that.sceneList))
                var idx = that.sceneIndex
                var scenes = arr[idx].scenes_conf || []
                // if (that.duration == 0) {
                //     return
                // }
                var str = {
                    id: new Date().getTime(),
                    type: 'audio',
                    name: info.name,
                    url: info.url,
                    duration: info.duration,
                    loop: true,
                    volume: 0.5,
                    background: false,
                    start: that.currentTime * 1000,
                }
                // 剩余时间
                var remainder = that.duration - that.currentTime
                // 如果剩余时间大于音频时长  直接取视频时长
                if (remainder >= info.duration) {
                    str.end = (that.currentTime + info.duration) * 1000
                } else {
                    // 如果小于  直接取总时长
                    str.end = that.duration * 1000
                }
                scenes.push(str)
                var params = {
                    type: 'conf',
                    info: scenes
                }
                // 修改数据
                that.$store.dispatch('videoData/modifyProduct', params)
                // 插入后选中
                that.$store.commit('videoData/SET_SHOW_ID', str.id)
            }
            // 全局
            if (type == 2) {
                let sounds = that.sceneList[that.sceneIndex].sounds
                let obj = {
                    name: info.name,
                    url: info.url,
                    volume: sounds ? sounds.volume : 0.5,
                    duration: info.duration,
                }
                var params = {
                    type: 'sounds',
                    info: obj
                }
                // 修改数据
                that.$store.dispatch('videoData/modifyProduct', params)
            }
        },
        // 播放
        playBtn(item, index) {
            this.$set(item, 'audioflag', false)
            let recordAudio = this.$refs['audioRef' + item.id][0]
            if (recordAudio.paused) {
                recordAudio.play()
                this.$set(item, 'audioStatus', 'pause')
            } else {
                recordAudio.pause()
                this.$set(item, 'audioStatus', 'play')
            }
            // 其他音频全部停止
            this.list.forEach(ele => {
                ele.materialMusic.forEach(ite => {
                    if (ite.id != item.id) {
                        this.$set(ite, 'audioStatus', '')
                        this.$set(ite, 'audioflag', true)
                    }
                })
            })
            this.searchlist.forEach(ele => {
                ele.materialMusic.forEach(ite => {
                    if (ite.id != item.id) {
                        this.$set(ite, 'audioStatus', '')
                        this.$set(ite, 'audioflag', true)
                    }
                })
            })
            const audios = document.getElementsByTagName('audio');
            [].forEach.call(audios, function (i) {
                if (i !== recordAudio) {
                    i.pause();
                }
            })
        },
        // 鼠标进入
        mouseenter(item, index) {
            this.$set(item, 'audioflag', false)
        },
        // 鼠标离开
        mouseleave(item, index) {
            if (item.audioStatus != '') {
                this.$set(item, 'audioflag', false)
            } else {
                this.$set(item, 'audioflag', true)
            }
            // this.$set(item, 'audioflag', true)
        },
    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";

.search-box {
    padding-top: 0;
    padding-bottom: 15px;
}

.contentbox {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 320px);
    margin-right: -8px;
}
</style>
<template>
    <div class="materials">
        <div class="video_table" :class="!navInfo.navFlag ? 'video_table_close' : ''">
            <!-- 标题 -->
            <div class="table_title">
                <i class="el-icon-close" @click="close"></i>
                <div class="title">我的素材</div>
            </div>
            <div class="table_content">

                <!-- 上传 -->
                <div class="uploadbox" :style="uploadStyle">
                    <!-- 正在上传 -->
                    <div class="uploadbutton activeupload" v-show="isupload"><i class="el-icon-loading"></i> 正在上传</div>
                    <!-- 没有上传 -->
                    <div class="uploadbutton noupload" v-show="!isupload"><i class="el-icon-upload el-icon--right"></i> 上传素材</div>
                    <div class="proupSelect">
                        <div class="proupSelect-title">上传标准
                            <el-tooltip placement="bottom">
                                <div slot="content">
                                    <div v-for="(item, z) in uploadType" :key="z" class="infotxt" style="font-size: 12px;color: #C1C1C1;line-height: 18px;">
                                        {{ item.name }}：支持{{ item.formatTxt }}格式，文件大小不能超过{{ item.maxSize }}M
                                    </div>
                                </div>
                                <i class="el-icon-warning-outline icon"></i>
                            </el-tooltip>
                        </div>
                        <div class="proupSelect-type">
                            <div class="item" v-for="(item, y) in uploadType" :key="y" @click="selectUploadType(y)">
                                <el-upload ref="uploadRef" action="#" :show-file-list="false" :accept="item.format" :http-request="uploadChange">
                                    <div slot>
                                        <div class="item-pic">
                                            <img :src="require(`@/assets/images/icon/${item.pic}.webp`)" alt="">
                                        </div>
                                        <p class="item-label">{{ item.name }}</p>
                                    </div>
                                </el-upload>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="empty" v-if="!list1.length && !list2.length && !list3.length && !list4.length">
                    <img :src="require('@/assets/images/workben/material_empty.png')" alt="">
                    上传本地素材添加到作品吧～
                </div>
                <!-- 下方的内容列表 -->
                <div class="contentbox scroll_bar">
                    <PicList ref="piclist" v-if="listname == 'all' || listname == 'piclist'" @getList="getList1" @listshowchange="piclistshowchange" :listshow="piclistshow" @upDataDelete="upDataDelete"></PicList>
                    <BackgroundList ref="bglist" v-if="listname == 'all' || listname == 'bglist'" @getList="getList2" @listshowchange="bglistshowchange" :listshow="bglistshow" @upDataDelete="upDataDelete"></BackgroundList>
                    <VideoList ref="videolist" v-if="listname == 'all' || listname == 'videolist'" @getList="getList4" @listshowchange="videolistshowchange" :listshow="videolistshow" @upDataDelete="upDataDelete"></VideoList>
                    <audioList ref="audiolist" v-if="listname == 'all' || listname == 'audiolist'" @getList="getList3" @listshowchange="audiolistshowchange" :listshow="audiolistshow" @upDataDelete="upDataDelete"></audioList>
                </div>
            </div>
            <!-- 最下方的删除弹窗 -->
            <div class="deletebox" :class="{ 'deleteboxShow': deleteIds.length }">
                <div class="deletebox-l">
                    <el-checkbox v-model="checkedAll" @change="checkedAllOrClean"></el-checkbox>
                    已选{{ deleteIds.length }}个
                </div>
                <div class="deletebox-r">
                    <div class="buttonclose" @click="closeDelete">退出管理</div>
                    <div class="button" :class="{ 'deleteOpacity': deleteIds.length == 0 }" @click="openDelete">删除</div>
                </div>
            </div>

            <!-- 删除的提示 -->
            <point-out ref="point" @close='confirmSecondBtn' dialogTitle="提示" dialogWidth='380px' type="works" iconColor="#FF9665" borderRadius='10px' :iconShow="true" :affirmShow="true" affirmText='确 定'>
                <div class="zc_dialog_tip_content">
                    <div class="text">请确认要删除吗？</div>
                </div>
            </point-out>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        PicList: resolve => require(['@/components/video_right/components/picList.vue'], resolve),
        BackgroundList: resolve => require(['@/components/video_right/components/backgroundList.vue'], resolve),
        VideoList: resolve => require(['@/components/video_right/components/videoList.vue'], resolve),
        audioList: resolve => require(['@/components/video_right/components/audioList.vue'], resolve),
        PointOut: resolve => require(['@/components/point_out/point_out'], resolve),
        // Loading: resolve => require(['@/components/loading/index'], resolve)
    },
    props: {
        navInfo: {
            type: Object,
            default: () => { }
        },
        type: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            name: '',
            checkedAll: false, // 选择所有
            loading: false,
            deleteIds: [], // 需要删除的数组的列表
            progressPercent: 0,
            uploadType: [
                { name: '图片', pic: 'materialicon2', type: 1, maxSize: 50, format: 'image/gif, image/png, image/jpg', formatTxt: 'png/jpg/gif' },
                { name: '背景', pic: 'materialicon1', type: 2, maxSize: 50, format: 'image/gif, image/png, image/jpg, video/mp4, video/webm', formatTxt: 'png/jpg/gif/mp4/webm' },
                { name: '音乐', pic: 'materialicon4', type: 3, maxSize: 50, format: 'audio/mp3, audio/wav', formatTxt: 'mp3/wav' },
                { name: '视频', pic: 'materialicon3', type: 4, maxSize: 50, format: 'video/mp4, video/webm', formatTxt: 'mp4/webm' },
            ], // 上传的分类
            activeUploadType: 0, // 正在上传的类型
            isupload: false, // 是否正在上传
            piclistshow: false,
            list1: [],
            bglistshow: false,
            list2: [],
            audiolistshow: false,
            list3: [],
            videolistshow: false,
            list4: [],
            listname: 'all'
        }
    },
    mounted() {
    },
    watch: {
        deleteIds() {
            if (this.listname == 'piclist') {
                this.checkedAll = this.list1.length === this.deleteIds.length && this.deleteIds.length > 0

            }
            if (this.listname == 'bglist') {
                this.checkedAll = this.list2.length === this.deleteIds.length && this.deleteIds.length > 0
            }
            if (this.listname == 'videolist') {
                this.checkedAll = this.list4.length === this.deleteIds.length && this.deleteIds.length > 0
            }
            if (this.listname == 'audiolist') {
                this.checkedAll = this.list3.length === this.deleteIds.length && this.deleteIds.length > 0
            }
            // this.checkedAll = this.list.length === this.deleteIds.length && this.deleteIds.length > 0
        }
    },
    computed: {
        uploadStyle() {
            return {
                '--uploadW': `${this.progressPercent}%`
            }
        }
    },
    methods: {
        // 关闭
        close() {
            let obj = {
                ...this.navInfo,
                navFlag: false,
            }
            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', obj)
        },
        piclistshowchange(e) {
            this.piclistshow = e
            if (e) {
                this.listname = 'piclist'
            } else {
                this.listname = 'all'
                this.$refs['piclist'].changeSelect([])

            }
            this.reset()
        },
        bglistshowchange(e) {
            this.bglistshow = e
            if (e) {
                this.listname = 'bglist'
            } else {
                this.listname = 'all'
                this.$refs['bglist'].changeSelect([])
            }
            this.reset()
        },
        videolistshowchange(e) {
            this.videolistshow = e
            if (e) {
                this.listname = 'videolist'
            } else {
                this.listname = 'all'
                this.$refs['videolist'].changeSelect([])
            }
            this.reset()
        },
        audiolistshowchange(e) {
            this.audiolistshow = e
            if (e) {
                this.listname = 'audiolist'
            } else {
                this.listname = 'all'
                this.$refs['audiolist'].changeSelect([])
            }
            this.reset()
        },
        // 重置
        reset() {
            this.checkedAll = false
            this.deleteIds = []
        },
        getList1(e) {
            this.list1 = e
        },
        getList2(e) {
            this.list2 = e
        },
        getList3(e) {
            this.list3 = e
        },
        getList4(e) {
            this.list4 = e
        },
        // 更新删除的数字
        upDataDelete(value) {
            this.deleteIds = value || []
        },
        // 选择全部或者清除全部
        checkedAllOrClean() {
            if (this.listname == 'piclist') {
                const arr = this.checkedAll ? this.list1.map(item => { return item.id }) : []
                this.$refs[this.listname].changeSelect(arr)
            }
            if (this.listname == 'bglist') {
                const arr = this.checkedAll ? this.list2.map(item => { return item.id }) : []
                this.$refs[this.listname].changeSelect(arr)
            }
            if (this.listname == 'videolist') {
                const arr = this.checkedAll ? this.list4.map(item => { return item.id }) : []
                this.$refs[this.listname].changeSelect(arr)
            }
            if (this.listname == 'audiolist') {
                const arr = this.checkedAll ? this.list3.map(item => { return item.id }) : []
                this.$refs[this.listname].changeSelect(arr)
            }
            // this.$refs[this.listname].changeSelect(arr)
        },
        // 退出管理 取消全部选择
        closeDelete() {
            if (this.listname == 'piclist') {
                const arr = []
                this.$refs[this.listname].changeSelect(arr)
            }
            if (this.listname == 'bglist') {
                const arr = []
                this.$refs[this.listname].changeSelect(arr)
            }
            if (this.listname == 'videolist') {
                const arr = []
                this.$refs[this.listname].changeSelect(arr)
            }
            if (this.listname == 'audiolist') {
                const arr = []
                this.$refs[this.listname].changeSelect(arr)
            }
        },
        // 打开删除的弹窗
        openDelete() {
            if (this.deleteIds.length > 0) {
                this.$refs.point.openDialogBtn()
            }
        },

        // 确认删除的回调
        confirmSecondBtn() {
            this.$workbenApi.self_material_del({
                ids: this.deleteIds.join(',')
            }).then(res => {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: res.code === 1000 ? 'success' : 'error'
                })
                if (res.code === 1000) {
                    this.reset()
                    // 如果当前展示的类型与上传的类型相同就刷新界面
                    if (this.listname == 'piclist') {
                        this.$refs.piclist.reset()
                        this.piclistshow = false
                        this.listname = 'all'

                    }
                    if (this.listname == 'bglist') {
                        this.$refs.bglist.reset()
                        this.bglistshow = false
                        this.listname = 'all'

                    }
                    if (this.listname == 'videolist') {
                        this.$refs.videolist.reset()
                        this.videolistshow = false
                        this.listname = 'all'

                    }
                    if (this.listname == 'audiolist') {
                        this.$refs.audiolist.reset()
                        this.audiolistshow = false
                        this.listname = 'all'

                    }
                }
            })
        },

        // 打开上传的内容
        selectUploadType(index) {
            this.activeUploadType = index
        },

        // 上传之前的钩子函数
        uploadChange(response) {
            const file = response.file
            console.log(file)
            const checkSize = file.size / 1024 / 1024 <= this.uploadType[this.activeUploadType].maxSize
            // 判断大小
            if (!checkSize) {
                this.$message({
                    message: `只能上传不超过${this.uploadType[this.activeUploadType].maxSize}M大小的文件`,
                    type: 'warning'
                })
                return false
            }
            // 上传的进度
            const config = {
                onUploadProgress: progressEvent => {
                    this.progressPercent = Number((progressEvent.loaded / progressEvent.total * 100).toFixed(2))
                    // console.log(this.progressPercent)
                }
            }
            this.isupload = true
            const newFile = new FormData()
            newFile.append('file', file)
            newFile.append('type', this.uploadType[this.activeUploadType].type)
            this.$workbenApi.up_material(newFile, config).then(res => {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: res.code === 1000 ? 'success' : 'error'
                })
                this.isupload = false
                if (res.code === 1000) {
                    // 如果当前展示的类型与上传的类型相同就刷新界面
                    if (this.uploadType[this.activeUploadType].type == 1) {
                        this.$refs.piclist.reset()
                    }
                    if (this.uploadType[this.activeUploadType].type == 2) {
                        this.$refs.bglist.reset()
                    }
                    if (this.uploadType[this.activeUploadType].type == 4) {
                        this.$refs.videolist.reset()
                    }
                    if (this.uploadType[this.activeUploadType].type == 3) {
                        this.$refs.audiolist.reset()
                    }
                }
                // message.close()
            })
        },

        // 上传文件时候的进度
        modalMessage() {
            this.$showMessageinModal(true)
        }

    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";

.materails {
}

.uploadbox {
    position: relative;

    .uploadbutton {
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        cursor: pointer;
        transition: all 0.3 ease;
        overflow: hidden;
    }

    .activeupload {
        background-color: rgba(72, 117, 255, 0.5);
        position: relative;

        &::after {
            content: "";
            height: 32px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #4875ff;
            width: var(--uploadW);
            z-index: -1;
        }
    }

    .noupload {
        background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
    }

    .proupSelect {
        width: 306px;
        height: 0;
        background: #404851;
        box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.35);
        border-radius: 4px;
        position: absolute;
        top: 40px;
        z-index: 9;
        transition: all 0.3s ease;
        overflow: hidden;

        .proupSelect-title {
            padding-top: 10px;
            padding-left: 12px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;

            .icon {
                color: #ffffff;
                margin-left: 6px;
            }

            .infotxt {
                font-size: 12px;
                padding-bottom: 4px;
            }
        }

        .proupSelect-type {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 12px;

            .item {
                padding: 7px 15px;
                background: #24282e;
                border-radius: 4px;
                cursor: pointer;

                .item-pic {
                    width: 28px;
                    height: 28px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .item-label {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                }
            }
        }
    }

    &:hover {
        .proupSelect {
            height: 120px;
            transition: all 0.3s ease;
        }
    }
}

.contentbox {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 300px);
}

.deletebox {
    position: absolute;
    bottom: -45px;
    height: 48px;
    width: 344px;
    background: #2e363f;
    // box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.34);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 19px;
    transition: all 0.3s ease;
    z-index: 9;

    .deletebox-l {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
    }

    .deletebox-r {
        display: flex;

        .buttonclose {
            width: 76px;
            height: 32px;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 32px;
            text-align: center;
            transition: all 0.3s ease;
            border: 1px solid #ffffff;
            cursor: pointer;
            margin-right: 10px;
        }

        .button {
            width: 54px;
            height: 32px;
            background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 32px;
            text-align: center;
            transition: all 0.3s ease;
            cursor: pointer;
        }
    }
}

.deleteboxShow {
    bottom: 0px !important;
    transition: all 0.3s ease;
}

.deleteOpacity {
    opacity: 0.3;
    cursor: not-allowed !important;
}

.empty {
    width: 180px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 50% auto;

    img {
        // display: block;
        width: 180px;
        height: 120px;
        margin-bottom: 10px;
    }

    font-size: 13px;
    font-weight: 400;
    color: #999999;
}
</style>
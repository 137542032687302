<template>
    <div class="card_content scroll_bar_all" id="winMain" ref="winConfig">
        <div class="con_main" :style="[style]">
            <div class="main_info" ref="tupuPicture" :style="[nrStyle]" @click.stop="clickAround">
                <div class="info_inner" v-for="(item, index) in infoList" :key="index" :style="innerStyle(index)" @mousedown="mouseDownBtn(index, item, $event)">
                    <!-- 图片 -->
                    <div class="dthuman" crossOrigin="anonymous" v-if="item.type=='dthuman'" @dblclick.stop="openSetting">
                        <video class="video" id="dthumanVideo" v-show="!paused" ref="videoPlayer" loop muted :src="item.video_url"></video>
                        <img class="image" v-show="paused" :src="item.url" @dragstart.prevent alt="">
                    </div>

                    <img class="image" crossOrigin="anonymous" v-if="item.type == 'image' || item.type == 'avatar' && item.url && item.show" @dblclick.stop="openSetting" :src="item.url" @dragstart.prevent alt="">
                    <!-- 视频 -->
                    <video class="video" crossOrigin="anonymous" v-if="item.type == 'video' && item.url && item.show" @dblclick.stop="openSetting" :src="item.url" muted loop autoplay></video>
                    <!-- 字幕 -->
                    <div class="subtitle" v-if="item.type == 'subtitle' && subtitleShow" @dblclick.stop="openSetting">
                        <img v-if="item.url" crossOrigin="anonymous" :src="item.url" @dragstart.prevent alt="">
                        <div class='subtitle_txt' :style="subtitleStyle(item)">{{ subtitle }}</div>
                        <div class='subtitle_txt' :style="subtitleStyle1(item)">{{ subtitle }}</div>
                    </div>
                    <!-- 文本 -->
                    <div class="text" v-if="item.type == 'text'">
                        <div class='text_txt' :ref="`editShow${item.id}`" @dblclick.stop="textEdit(item.id)" v-click-outside="handleBlur" :style="textStyle(item)">
                            <div :id="'edittext'+item.id">{{ item.content }}</div>
                        </div>
                    </div>
                    <!-- 音频播放 -->
                    <audio-play v-if="item.type == 'audio'" :type="item.type" :info="item" :index="index" :key="index"></audio-play>
                </div>
            </div>
            <!-- 背景设置 -->
            <div class="inner_background">
                <el-tooltip class="item" effect="dark" content="背景设置" placement="right">
                    <el-dropdown trigger="click" placement="bottom">
                        <img class="set_img" src="@/assets/images/createvideo/btn_background.png" alt="">
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <el-upload action="#" :show-file-list="false" accept="image/gif,image/png,image/jpg,image/jpeg" :before-upload="beforeUpload" :http-request="httpRequestMultiple">
                                    <div class="menu_btn">本地上传</div>
                                </el-upload>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div class="menu_btn">重新选择</div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div class="menu_btn">应用到全局</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-tooltip>
            </div>
            <!-- 可操作按钮 -->
            <div class="main_btn" v-show="!isShow && isActive" :style="{ top: top - 35 + 'px', left: left + 5 + 'px' }">
                <content-btn ref="btn" :type='selectType' :infoIndex='infoIndex' @edit='indexEdit' @del='onDeactivated'></content-btn>
            </div>
            <!-- 可操作文字 -->
            <div class="main_txt" v-show="!isShow && isActive && (selectType == 'subtitle' || selectType == 'text')" :style="{ top: top - 75 + 'px', left: left + 5 + 'px' }">
                <content-txt :infoIndex='infoIndex' @input="numInput"></content-txt>
            </div>
            <!-- 移动时显示边框 -->
            <div class="select_main_box" v-show="isShow" :style="{ transform: 'rotate(' + rotation + 'deg)', width: boxStyle.width + 'px', height: boxStyle.height + 'px', top: boxStyle.top + 'px', left: boxStyle.left + 'px' }">
            </div>
            <!-- 拖动缩放框 -->
            <vue-drag-resize :style="{ transform: 'rotate(' + rotation + 'deg)'}" v-if="isActive && selectType != 'background'" :contentClass="isShow ? 'drag-resize_no' : 'drag-resize'" :key="infoId" :isActive="isActive" :w="width" :h="height" :y="top" :x="left" :minw="10" :minh="10" :sticks="(selectType == 'video' || selectType == 'dthuman') ? ['tl', 'tr', 'br', 'bl'] : ['tl', 'tm', 'tr', 'mr', 'ml', 'br', 'bm', 'bl']" :aspectRatio="(selectType == 'video' || selectType == 'dthuman') ? true : false" :isResizable="true" @resizing="onResizing" @resizestop='onResizstop'>
            </vue-drag-resize>
        </div>
    </div>
</template>
<script>
// 自定义指令，用于处理点击外部区域的事件
const clickOutside = {
    bind(el, binding) {
        // 在元素上绑定一个点击事件监听器
        el.clickOutsideEvent = function (event) {
            // 检查点击事件是否发生在元素的内部
            if (!(el === event.target || el.contains(event.target))) {
                // 如果点击事件发生在元素的外部，则触发指令绑定的方法,将点击的event数据传过去
                binding.value(event);
            }
        };
        // 在文档上添加点击事件监听器
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unbind(el) {
        // 在元素上解除点击事件监听器
        document.removeEventListener("click", el.clickOutsideEvent);
    },
}
import AudioPlay from "./audioPlay.vue" //音频素材
import ContentBtn from './content_btn'
import ContentTxt from './content_txt'
import { mapState } from "vuex"
import debounce from "@/utils/debounce" //防抖函数
export default {
    directives: {
        "click-outside": clickOutside, // 注册自定义指令
    },
    components: { AudioPlay, ContentBtn, ContentTxt },
    props: {},
    data() {
        return {
            // 可显示区域宽和高
            win_width: 0,
            win_height: 0,

            // 选中的类型
            selectType: '',// 模特：dthuman 公屏：publicScreen  图片：image  
            // 移动时显示边框位置
            boxStyle: {
                width: 0,
                height: 0,
                top: 0,
                left: 0,
                rotation: 0
            },
            // 是否在移动
            isShow: false,
            // 拖动得下标 index
            infoIndex: 0,
            // 拖动得唯一标识 ID
            infoId: null,
            // 拖动是否选中
            isActive: false,
            width: 0,
            height: 0,
            top: 0,
            left: 0,
            rotation: 0,
            // 上传类型
            format: ['image/gif', 'image/png', 'image/jpg', 'image/jpeg'],
            // 公屏字号
            inputShow: false,
            editid: null,  //编辑文字ID
            id: null,
        };
    },

    computed: {
        ...mapState({
            sceneShow: state => state.videoCreate.sceneShow,
            bottomShow: state => state.videoCreate.bottomShow,
            scaleRatio: state => state.videoCreate.scaleRatio,
            resolutionRatio: state => state.videoCreate.resolutionRatio,
            rightNavInfo: state => state.videoCreate.rightNavInfo,

            subtitle: state => state.videoData.subtitle,//字幕
            subtitleShow: state => state.videoData.subtitleShow, //是否显示字幕
            showId: state => state.videoData.showId,//显示当前轨道的id
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            currentTime: state => state.videoData.currentTime,//当前播放时间
            disable: state => state.videoData.disable,//禁用
            duration: state => state.videoData.duration,
            paused: state => state.videoData.paused, //暂停
            clickTime: state => state.videoData.clickTime,//当前播放时间
        }),
        // 数据列表
        infoList() {
            if (this.sceneList.length) {
                return this.sceneList[this.sceneIndex].scenes_conf || []
            } else {
                return []
            }
        },

        // 监听数据变化用
        newList() {
            if (this.sceneList.length) {
                return JSON.parse(JSON.stringify(this.sceneList[this.sceneIndex].scenes_conf || []))
            } else {
                return []
            }
        },
        winStyle() {
            var arr = this.resolutionRatio.split('*')
            return {
                width: arr[0],
                height: arr[1]
            }
        },
        style() {
            if (this.win_height) {
                return {
                    width: Math.floor(Number(this.scaleRatio) * this.winStyle.width) + 'px',
                    height: Math.floor(Number(this.scaleRatio) * this.winStyle.height) + 'px'
                }
            } else {
                return {
                    width: '0px',
                    height: '0px'
                }
            }
        },
        nrStyle() {
            let obj = this.sceneList[this.sceneIndex]?.background || {}
            let params = {
                width: this.winStyle.width + 'px',
                height: this.winStyle.height + 'px',
                transform: 'scale(' + this.scaleRatio + ')',
            }
            if (obj.type == 'color') {
                params.background = obj.color
            }
            if (obj.type == 'image') {
                params.background = 'url(' + obj.url + ') no-repeat 100%/cover'
            }
            return params
        },
    },

    watch: {
        showId(val) {
            if (val === null) {
                this.close()
            }
        },
        // 切换场景下标
        sceneIndex(val) {
            this.onDeactivated()
            this.$store.commit('videoData/SET_SHOW_ID', null)
            this.$store.commit('videoData/SET_STEP_LIST', [])
            this.$store.commit('videoData/SET_SUBTITLE_SHOW', this.sceneList[val].data?.subtitleShow || false)
        },
        sceneShow() {
            setTimeout(() => {
                this.getSize()
            }, 300)
        },
        bottomShow() {
            setTimeout(() => {
                this.getSize()
            }, 300)
        },
        sceneList() {
            this.getShow()
            this.getBoxStyle()
        },
        currentTime() {
            this.getShow()
        },
        // 改变视频时间
        clickTime(val) {
            var videoTime = document.getElementById('dthumanVideo').duration;
            if (videoTime >= val) {
                this.$refs.videoPlayer[0].currentTime = val;
            } else {
                let time = val % videoTime
                this.$refs.videoPlayer[0].currentTime = time;
            }
        },
        // 视频播放暂停
        paused(val) {
            if (val) {
                this.$refs.videoPlayer[0].pause();
            } else {
                this.$refs.videoPlayer[0].play();
            }
        },
        // 监听数据变化  保存上一步下一步操作
        newList(val) {
            this.postStep(val)
        },
        duration() {
            // 总时长发生改变  素材结束时长超出总时长  修改结束时长
            this.changeEndTime()
            // 素材展示隐藏
            this.getShow()

        },
    },

    created() {
        if (this.$route.params) {
            this.id = this.$route.params.id
        }
        // 监听页面大小
        window.addEventListener("resize", this.getSize)
        window.addEventListener("click", this.outClick)
        window.addEventListener('keydown', this.getEnter)
    },

    mounted() {
        this.$nextTick(() => {
            this.getSize()
            this.getShow()
        })
    },

    methods: {
        // 保存步骤
        postStep: debounce(function (val) {
            // 回撤时候数据也会发生改变 此时禁用
            if (this.disable) {
                return
            }
            this.$store.dispatch('videoData/stepSave', val)
        }, 500),
        // 切换数据  修改选中框
        getBoxStyle() {
            if (!this.showId) {
                return
            }
            this.isActive = false
            this.$nextTick(() => {
                let id = JSON.parse(JSON.stringify(this.showId))
                let arr = JSON.parse(JSON.stringify(this.sceneList[this.sceneIndex].scenes_conf))
                arr.forEach((ele, index) => {
                    if (ele.id == id) {
                        this.innerBtn(ele.style)
                        this.draggResize(ele.style)
                        this.infoId = this.showId
                        this.isActive = true
                        this.infoIndex = index
                    }
                })
            })
        },
        // 打开设置
        openSetting() {
            let item = {
                label: '设置',
                value: 'setting',
                navFlag: true,
                icon: require('@/assets/images/icon/video_nav_9.png'),
            }
            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', item)
        },
        // 点击除了当前元素
        handleBlur(event) {
            if (this.editid && event.target.className != '') {

                // 点击周围
                let str = 'editShow' + this.editid
                this.$refs[str][0].contentEditable = false
                this.isActive = true
                this.isShow = false
                // 获取编辑后的文字
                const text = document.getElementById('edittext' + this.editid).innerHTML
                var arr = JSON.parse(JSON.stringify(this.sceneList))
                var scenes = arr[this.sceneIndex].scenes_conf
                scenes[this.infoIndex].content = text
                var params = {
                    type: 'conf',
                    info: scenes
                }
                this.$store.dispatch('videoData/modifyProduct', params)
                this.editid = null
            }
        },
        // 双击 编辑
        textEdit(val) {
            this.editid = val
            // 禁止拖动
            this.isActive = false
            this.isShow = true
            let str = 'editShow' + val
            // div可编辑
            this.$refs[str][0].contentEditable = true

            this.openSetting()
        },
        // 
        numInput(type) {
            if (type == 1) {
                this.inputShow = true
            } else {
                this.inputShow = false
            }
        },
        // 修改素材开始结束时间
        changeEndTime() {
            this.infoList.forEach(ele => {
                ele.start = 0
                ele.end = this.duration * 1000
            });
        },
        // 显示隐藏
        getShow() {
            this.infoList.forEach(ele => {
                if (ele.type == 'subtitle' || ele.type == 'dthuman') {
                    ele.show = true
                } else {
                    if (ele.start == 0 && ele.end == 0) {
                        ele.show = true
                        ele.end = this.duration * 1000
                        // console.log(ele.end)
                    } else if ((ele.start / 1000) <= this.currentTime && this.currentTime <= (ele.end / 1000)) {
                        ele.show = true
                    } else {
                        ele.show = false
                    }
                }
            });
            this.$forceUpdate()
        },
        // 点击
        mouseDownBtn(index, item, event) {
            // 在编辑状态中  点击自身编辑   点击另一个文本取消选中编辑
            if (this.editid != null && this.editid != item.id) {
                let e = {
                    target: {
                        className: 'text'
                    }
                }
                // 除了当前元素
                this.handleBlur(e)
                return
            }
            // 在编辑状态  不出现选中框
            if (this.editid) {
                return
            }
            // 锁定
            if (item.lock) {
                return
            }
            var that = this
            var info = that.infoList[index]
            that.selectType = info.type
            if (that.selectType == 'subtitle' && !that.subtitleShow) {
                return
            }
            if (!info.show) {
                return
            }
            that.infoId = null
            that.infoIndex = index
            that.innerBtn(info.style)
            that.draggResize(info.style)
            that.$store.commit('videoData/SET_SHOW_ID', info.id)
            // 鼠标按下执行
            var left = 0, top = 0
            document.onmousemove = function (move) {
                if (that.selectType == 'background') {
                    return
                }
                left = move.clientX - event.clientX
                top = move.clientY - event.clientY
                that.isShow = true
                that.boxStyleInfo(index, top, left)
            }
            // 鼠标按键松开执行
            document.onmouseup = function () {
                if (that.selectType == 'background') {
                    return
                }
                document.onmousemove = null
                document.onmouseup = null
                that.isShow = false
                that.infoId = info.id
                if (left || top) {
                    that.draggResize(info.style)
                }
            }
        },
        // 选中
        innerBtn(str) {
            this.boxStyle = {
                width: Math.floor(Number(this.scaleRatio) * str.width),
                height: Math.floor(Number(this.scaleRatio) * str.height),
                top: Math.floor(Number(this.scaleRatio) * str.top),
                left: Math.floor(Number(this.scaleRatio) * str.left),
                rotation: str.rotation,
            }
            this.$forceUpdate()
        },
        // 移动结束显示拖动缩放框
        draggResize(str, type) {
            this.isActive = true
            this.width = Math.floor(Number(this.scaleRatio) * str.width)
            this.height = Math.floor(Number(this.scaleRatio) * str.height)
            this.top = Math.floor(Number(this.scaleRatio) * str.top)
            this.left = Math.floor(Number(this.scaleRatio) * str.left)
            this.rotation = str.rotation
            this.$forceUpdate()
            // this.$store.dispatch('videoData/addProduct')
        },
        // 移动时显示边框得样式
        boxStyleInfo(index, top, left) {
            //
            this.boxStyle.top = this.top + top
            this.boxStyle.left = this.left + left
            //
            var info = {
                width: this.infoList[index].style.width,
                height: this.infoList[index].style.height,
                top: Math.floor(this.boxStyle.top / Number(this.scaleRatio)),
                left: Math.floor(this.boxStyle.left / Number(this.scaleRatio)),
                rotation: this.infoList[index].style.rotation,
            }
            // this.infoList[index].style = info
            this.$forceUpdate()
            var params = {
                type: 'style',
                index: index,
                info: info
            }
            this.$store.dispatch('videoData/modifyProduct', params)
        },
        // 缩放事件
        onResizing(style) {
            //
            this.boxStyle = style
            //
            this.width = style.width
            this.height = style.height
            this.top = style.top
            this.left = style.left
            //
            var info = {
                width: Math.floor(this.boxStyle.width / Number(this.scaleRatio)) + 1,
                height: Math.floor(this.boxStyle.height / Number(this.scaleRatio)) + 1,
                top: Math.floor(this.boxStyle.top / Number(this.scaleRatio)) + 1,
                left: Math.floor(this.boxStyle.left / Number(this.scaleRatio)) + 1,
                rotation: this.rotation,
            }
            this.infoList[this.infoIndex].style = info
            this.$forceUpdate()
            var params = {
                type: 'style',
                index: this.infoIndex,
                info: info
            }
            this.$store.dispatch('videoData/modifyProduct', params)
        },
        // 键盘事件
        getEnter(e) {
            var that = this
            var key = window.event ? e.keyCode : e.which;
            if (that.isActive && !that.inputShow) {
                // 删除
                // if (key === 46 || key === 8) {
                //     var arr = JSON.parse(JSON.stringify(that.sceneList))
                //     var str = arr[that.sceneIndex].scenes_conf
                //     str.splice(that.infoIndex, 1)
                //     that.onDeactivated()
                //     var params = {
                //         type: 'conf',
                //         info: str
                //     }
                //     that.$store.dispatch('videoData/modifyProduct', params)
                // }
                // 向左
                if (key === 37) {
                    that.left = that.left - 1
                    that.boxStyle.left = that.boxStyle.left - 1
                    that.keyboardBtn()
                }
                // 向上
                if (key === 38) {
                    that.top = that.top - 1
                    that.boxStyle.top = that.boxStyle.top - 1
                    that.keyboardBtn()
                }
                // 向右
                if (key === 39) {
                    that.left = that.left + 1
                    that.boxStyle.left = that.boxStyle.left + 1
                    that.keyboardBtn()
                }
                // 向下
                if (key === 40) {
                    that.top = that.top + 1
                    that.boxStyle.top = that.boxStyle.top + 1
                    that.keyboardBtn()
                }
            }
        },
        // 键盘事件
        async keyboardBtn() {
            var that = this
            var info = {
                width: that.infoList[that.infoIndex].style.width,
                height: that.infoList[that.infoIndex].style.height,
                top: Math.floor(that.boxStyle.top / Number(that.scaleRatio)),
                left: Math.floor(that.boxStyle.left / Number(that.scaleRatio)),
                rotation: that.infoList[that.infoIndex].style.rotation,
            }
            this.$forceUpdate()
            var params = {
                type: 'style',
                index: that.infoIndex,
                info: info
            }
            await this.$store.dispatch('videoData/modifyProduct', params)
        },

        // 缩放结束
        onResizstop() {
            // this.$store.dispatch('videoData/addProduct')
        },
        // 设置样式
        innerStyle(index) {
            var show = this.infoList[index].show
            var str = this.infoList[index].style
            var type = this.infoList[index].type
            if (type == 'background') {
                return {
                    width: '100%',
                    height: '100%',
                    top: '0px',
                    left: '0px'
                }
            }
            if (str) {
                return {
                    width: str.width + 'px',
                    height: str.height + 'px',
                    top: str.top + 'px',
                    left: str.left + 'px',
                    transform: 'rotate(' + str.rotation + 'deg)',
                    display: show ? 'block' : 'none',
                }
            }
        },
        // 字幕样式 带描边效果
        subtitleStyle(info) {
            return {
                fontFamily: info.textStyle.fontFamily,
                color: info.textStyle.color,
                fontSize: info.textStyle.fontSize + 'px',
                fontWeight: info.textStyle.fontWeight,
                fontStyle: info.textStyle.fontStyle,
                textAlign: info.textStyle.textAlign,
                lineHeight: (info.textStyle.fontSize + 20) * info.textStyle.lineHeight + 'px',
                letterSpacing: info.textStyle.letterSpacing + 'px',
                'text-stroke': `${info.textStyle.strokeWidth}px ${info.textStyle.strokeColor}`,
                '-webkit-text-stroke': `${info.textStyle.strokeWidth}px ${info.textStyle.strokeColor}`,
            }
        },
        // 描边会让文字不清晰  再加一层文字
        subtitleStyle1(info) {
            return {
                fontFamily: info.textStyle.fontFamily,
                color: info.textStyle.color,
                fontSize: info.textStyle.fontSize + 'px',
                fontWeight: info.textStyle.fontWeight,
                fontStyle: info.textStyle.fontStyle,
                textAlign: info.textStyle.textAlign,
                letterSpacing: info.textStyle.letterSpacing + 'px',
                lineHeight: (info.textStyle.fontSize + 20) * info.textStyle.lineHeight + 'px',
            }
        },
        // 文本样式
        textStyle(info) {
            return {
                fontFamily: info.textStyle.fontFamily,
                fontSize: info.textStyle.fontSize + 'px',
                color: info.textStyle.color,
                fontWeight: info.textStyle.fontWeight,
                fontStyle: info.textStyle.fontStyle,
                textAlign: info.textStyle.textAlign,
                lineHeight: info.textStyle.lineHeight,
                letterSpacing: info.textStyle.letterSpacing + 'px'
            }
        },
        // 窗口大小发生变化
        getSize() {
            this.win_width = Math.floor(this.$refs.winConfig.offsetWidth - 20)
            this.win_height = Math.floor(this.$refs.winConfig.offsetHeight - 20)
            // 获取缩放比例
            var scale = (this.win_height / this.winStyle.height).toFixed(5)
            this.$store.commit('videoCreate/SET_SCALE_RATIO', scale)
            this.onDeactivated()
        },
        // 点击可编辑区域之外时
        outClick(e) {
            let info = document.getElementById("winMain");
            if (!info.contains(e.target)) {
                this.onDeactivated()
            }
        },
        // 改变滚动条位置
        scrollBar() {
            var allWidth = Math.floor(Number(this.scaleRatio) * this.winStyle.width)
            var allHeight = Math.floor(Number(this.scaleRatio) * this.winStyle.height)
            var editWidth = this.win_width
            var editHeight = this.win_height
            this.$refs.winConfig.scrollLeft = Math.floor((allWidth - editWidth) / 2)
            this.$refs.winConfig.scrollTop = Math.floor((allHeight - editHeight) / 2)
        },
        // 上传限制
        beforeUpload(file) {
            const isLtSize = file.size / 1024 / 1024 < 50;
            const isType = this.format.includes(file.type);
            if (!isType) {
                this.$message.error('只能上传只 image/gif,image/png,image/jpg,image/jpeg 格式!');
            }
            if (!isLtSize) {
                this.$message.error('上传文件大小不能超过 50M!');
            }
            return isType && isLtSize;
        },

        // 上传
        httpRequestMultiple(uploader) {
            var that = this
            const formData = new FormData();
            formData.set('file', uploader.file);
            that.$publicApi.uploadImg(formData).then(res => {
                if (res.code === 1000) {
                } else {
                    that.$message.error(res.message);
                }
            })
        },
        // 改变下标
        indexEdit(val) {
            this.infoId = null
            if (val.type) {
                this.innerBtn(val.style)
                this.draggResize(val.style)
            } else {
                this.infoIndex = val
                var info = this.infoList[val]
                this.innerBtn(info.style)
                this.draggResize(info.style)
            }
            this.$nextTick(() => {
                this.infoId = this.infoList[this.infoIndex].id
            })
        },
        // 点击组件外
        onDeactivated() {
            // 打开场景 点击周围选中不消失
            if (this.rightNavInfo.navFlag) {
                return
            }
            this.close()
        },
        // 点击编辑框 根据className 如果不是编辑元素  取消选中
        clickAround(e) {
            if (e.target.className == 'main_info') {
                if (this.editid) {
                    let e = {
                        target: {
                            className: 'text'
                        }
                    }
                    // 除了当前元素
                    this.handleBlur(e)
                    return
                }
                this.close()
            }
        },
        // 清空选中数据
        close() {
            this.selectType = null
            this.infoId = null
            this.infoIndex = 0
            //
            this.isActive = false
            this.width = 0
            this.height = 0
            this.top = 0
            this.left = 0
            // 隐藏图层设置
            this.$refs.btn.closeIndexBtn()
            this.$refs.btn.closeShowSet()
            // 清空选中id
            this.$store.commit('videoData/SET_SHOW_ID', null)
        },
    },
    destroyed() {
        // 移除window监听事件
        window.removeEventListener("click", this.outClick)
        window.removeEventListener('resize', this.getSize)
    },
}

</script>
<style lang='scss' scoped>
.card_content {
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .con_main {
        cursor: pointer;
        position: relative;
        margin: 0 auto;

        .main_info {
            position: absolute;
            transform-origin: 0 0;
            background: #fff;
            .info_inner {
                position: absolute;

                .dthuman {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                video {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                .subtitle {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                    .subtitle_txt {
                        position: absolute;
                        top: 50%;
                        width: 100%;
                        transform: translate(0, -50%);
                    }
                }

                .text {
                    position: relative;
                    width: 100%;
                    height: 100%;
                }

                .inner_bg {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;

                    img {
                        position: absolute;
                    }
                }
            }
        }

        .main_set {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1001;
            width: 32px;
            height: 32px;

            .set_img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        .main_btn {
            position: absolute;
            z-index: 1000;
            background: #ffffff;
            border-radius: 2px;
            box-shadow: 0px 2px 6px 0px rgba(108, 108, 108, 0.5);
        }

        .main_txt {
            position: absolute;
            z-index: 1000;
            background: #ffffff;
            border-radius: 4px;
            box-shadow: 0px 2px 6px 0px rgba(108, 108, 108, 0.5);
        }

        .select_main_box {
            position: absolute;
            pointer-events: none;
            box-sizing: border-box;
            border: 1px dashed #2e4bf2;
            // background: transparent;
        }

        ::v-deep .vdr {
            pointer-events: none;
            box-sizing: border-box;
        }

        ::v-deep .vdr.active:before {
            box-sizing: border-box;
            outline: 1px dashed #2e4bf2;
        }

        ::v-deep .vdr-stick {
            pointer-events: auto;
            box-sizing: border-box;
            border: 1px solid #2e4bf2;
        }

        .drag-resize_no {
            display: none;
        }
        .inner_background {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 30px;
            height: 30px;
            z-index: 10;
            .set_img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.el-dropdown-menu {
    ::v-deep .el-dropdown-menu__item {
        padding: 0;
        .el-upload {
            width: 100%;
        }
        .menu_btn {
            text-align: center;
            padding: 0 20px;
        }
    }
}
[contenteditable]:focus {
    outline: none;
}
</style>
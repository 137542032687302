<template>
  <div class='image_amplify'>
    <div class="image_box_main" v-if="dialogVisible">
      <div class="img_close">
        <div class="close app_region_no" @click="closeBtn"><i class="el-icon-close"></i></div>
      </div>
      <div class="main_image">
        <img :src="img" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {
        dialogVisible: false,
        img: ''
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {

    },
    methods: {
      // 打开
      openDialog(val) {
        this.img = val
        this.dialogVisible = true
        // 将组件挂在body上
        this.$nextTick(() => {
          const body = document.querySelector("body");
          if (body.append) {
            body.append(this.$el);
          } else {
            body.appendChild(this.$el);
          }
        });
      },
      // 关闭
      closeBtn() {
        // 将组件从body上销毁
        const body = document.querySelector("body");
        body.removeChild(this.$el);
        this.dialogVisible = false

      },
    },
  }
</script>

<style lang='scss' scoped>
  .image_amplify {
    width: 100%;

    .image_box_main {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000000;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);

      .main_image {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
          max-width: 500px;
          max-height: 100%;
        }

      }

      .img_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        /* max-width: 30%; */
        display: flex;
        justify-content: flex-end;

        .close {
          cursor: pointer;
          width: 40px;
          height: 40px;
          font-size: 24px;
          color: #fff;
          background: rgba(0, 0, 0, .5);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0 0 0 40px;

          .el-icon-close {
            font-size: 20px;
            margin-left: 5px;
            margin-top: -5px;
          }
        }
      }
    }
  }
</style>

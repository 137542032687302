<template>
    <div>
        <el-popover popper-class="audiopopper" ref="audiopopper" placement="bottom-end" title="" width="320" trigger="click">
            <div class="popper_main">
                <div class="popper_input">
                    <el-input class="search" v-model="keyword" size="small" @input="searchInput" prefix-icon="el-icon-search" placeholder="输入关键词搜索"></el-input>
                </div>
                <div class="audio_content">
                    <div class="audio_content_menu" v-show="!searchShow">
                        <div class="menu_li" :class="{active:labelName==item.name}" v-for="item,index in labels" :key="index" @click="changeLabel(item.name)">{{item.name}}</div>
                    </div>
                    <div class="audio_content_list" :class="{search:searchShow}" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.6)">
                        <div class="kong" v-if="this.audioList.length==0">
                            暂无数据~
                        </div>
                        <div class="list_li" v-for="item in audioList" :key="item.id">
                            <div v-if="!item.children || item.children.length==0">
                                <div class="card_item" @click.stop="changeAudio(item)" :class="{active:activeItem==item.id}">
                                    <div class="img">
                                        <img :src="item.avatar" alt="">
                                    </div>
                                    <div class="info">
                                        <div class="info_t">
                                            <div class="name">{{item.name}}</div>
                                            <div class="play">
                                                <img v-if="audioId==item.id" src="@/assets/images/workben/stop.png" @click.stop="stop(item)" alt="">
                                                <img v-else src="@/assets/images/workben/play.png" @click.stop="play(item)" alt="">
                                            </div>
                                        </div>
                                        <div class="info_b">
                                            <div class="label">中文</div>
                                            <div class="type">{{item.label}}</div>
                                        </div>
                                    </div>
                                    <audio v-if="audioId==item.id" :src="item.audio_url" @ended="overAudio" autoplay="autoplay" hidden></audio>
                                </div>
                            </div>
                            <div v-else>
                                <div class="card_item" @click.stop="item.show=!item.show">
                                    <div class="img">
                                        <img :src="item.avatar" alt="">
                                    </div>
                                    <div class="info">
                                        <div class="info_t">
                                            <div class="name">{{item.name}}</div>
                                            <div class="play">
                                                <img :class="{rotate:!item.show}" src="@/assets/images/workben/expand.png" alt="">
                                            </div>
                                        </div>
                                        <div class="info_b">
                                            <div class="label">中文</div>
                                            <div class="type">{{item.label}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="item.show">
                                    <div v-for="ele in item.children" :key="ele.id" @click.stop="changeAudio(ele)" :class="{active:activeItem==ele.id}" class="card_item childer">
                                        <div class="img">
                                            <img :src="ele.avatar" alt="">
                                        </div>
                                        <div class="info">
                                            <div class="info_t">
                                                <div class="name">{{ele.name}}</div>
                                                <div class="play">
                                                    <img v-if="audioId==ele.id" src="@/assets/images/workben/stop.png" @click.stop="stop(ele)" alt="">
                                                    <img v-else src="@/assets/images/workben/play.png" @click.stop="play(ele)" alt="">
                                                </div>
                                            </div>
                                            <div class="info_b">
                                                <div class="label">英文</div>
                                                <div class="type">{{ele.label}}</div>
                                            </div>
                                        </div>
                                        <audio v-if="audioId==ele.id" :src="ele.audio_url" @ended="overAudio" autoplay="autoplay" hidden></audio>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="select_btn" slot="reference">
                <span>{{default_tts.name}}</span>
                <i class="el-icon-arrow-down"></i>
            </div>
        </el-popover>
    </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    props: {

    },
    data() {
        return {
            loading: false,
            // opnePopover: false,  //弹窗
            searchShow: false,  //是否输入框搜索
            keyword: '',
            labelName: '',   //分类名称
            audioList: [], //声音列表
            activeItem: 128,   //选中声音
            timer: null,
            audioId: '',   //播放声音的ID
        };
    },
    computed: {
        ...mapState({
            labels: state => state.voiceInfo.labels,
            default_tts: state => state.voiceInfo.default_tts,
        }),
    },
    created() {
        this.getList()
    },
    mounted() {
        // console.log(this.labels)
        // document.addEventListener('click', (e) => {
        //     if (e.target.className == 'el-dialog__wrapper' || e.target.className == 'from' || e.target.className == 'richText-inner') {
        //         this.opnePopover = false;
        //     }
        // })
    },
    watch: {
        // opnePopover(val) {
        //     if (!val && this.audioId != 'current') {
        //         this.audioId = ''
        //     }
        // },
    },
    methods: {
        // 打开弹窗
        // open() {
        //     this.opnePopover = !this.opnePopover
        // },
        // 选中声音
        changeAudio(val) {
            this.activeItem = val.id
            // this.opnePopover = false
            this.$refs.audiopopper.doClose()
            this.$store.commit('voiceInfo/SET_DEFAULT_TTS', val)
        },
        // 语音结束
        overAudio() {
            this.audioId = ''
        },
        // 播放选中语言
        playCurrentAudio() {
            if (this.audioId == 'current') {
                this.audioId = ''
            } else {
                this.audioId = 'current'
            }
        },
        // 播放声音
        play(val) {
            this.audioId = val.id
        },
        // 停止声音
        stop() {
            this.audioId = ''
        },
        // 获取列表
        getList() {
            let obj = {
                keyword: this.keyword,
                label: this.keyword ? '' : this.labelName,
            }
            this.loading = true
            this.$digitalApi.getTtsList(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    if (res.result) {
                        let arr = res.result
                        arr.forEach(ele => {
                            ele.show = false
                        })
                        this.audioList = arr
                    } else {
                        this.audioList = []
                    }
                }
            })
        },
        // 选中分类
        changeLabel(val) {
            this.labelName = val
            this.getList()
        },
        // 搜索框节流
        searchInput(e) {
            let that = this
            if (e) {
                this.searchShow = true
            } else {
                this.searchShow = false
            }
            clearTimeout(that.timer)
            that.timer = setTimeout(() => {
                that.getList()
            }, 500)
        },
    },
};
</script>

<style scoped lang="scss">
.popper_main {
    .popper_input {
        width: 320px;
        padding: 8px 12px;
        background: #2b3036;
        border-radius: 4px 4px 0px 0px;
    }
    .kong {
        text-align: center;
        color: #999999;
        line-height: 300px;
        font-size: 14px;
    }
    .audio_content {
        width: 320px;
        height: 324px;
        border-radius: 4px;
        border: 1px solid #2b3036;
        border-top: none;
        display: flex;
        .audio_content_menu {
            width: 77px;
            height: 100%;
            background: #3c4148;
            overflow-y: auto;
            .menu_li {
                width: 71px;
                height: 40px;
                border-left: 3px solid #3c4148;
                font-size: 12px;
                color: #ffffff;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
            }
            // .menu_li:hover {
            //     color: #2e4bf2;
            // }
            .menu_li.active {
                background: rgba(45, 74, 241, 0.29);
                border-left: 3px solid #2e4bf2;
                // color: #2e4bf2;
            }
        }

        .audio_content_list {
            width: 254px;
            height: 100%;
            padding-bottom: 10px;
            overflow-y: auto;
            .list_li {
                .card_item.childer {
                    background: #3c4148;
                    border: 1px solid #3c4148;
                }
                .card_item {
                    cursor: pointer;
                    width: 225px;
                    height: 68px;
                    background: #3c4148;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    padding: 0 11px;
                    margin-top: 10px;
                    margin-left: 10px;
                    border: 1px solid #3c4148;
                    .img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }
                    .info {
                        margin-left: 12px;
                        .info_t {
                            display: flex;
                            align-items: center;
                            .name {
                                font-size: 14px;
                                color: #ffffff;
                                width: 130px;
                            }
                            .play {
                                width: 16px;
                                height: 16px;
                                cursor: pointer;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .rotate {
                                transform: rotate(-90deg);
                                -webkit-transform: rotate(-90deg);
                                transition-duration: 0.3s;
                            }
                        }
                        .info_b {
                            display: flex;
                            align-items: center;
                            padding-top: 6px;
                            .label {
                                width: 34px;
                                height: 18px;
                                background: #782ef2;
                                border-radius: 2px;
                                font-size: 12px;
                                color: #ffffff;
                                line-height: 18px;
                                text-align: center;
                            }
                            .type {
                                width: 109px;
                                font-size: 12px;
                                color: #999999;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                -o-text-overflow: ellipsis;
                                margin-left: 6px;
                            }
                        }
                    }
                }
                .card_item.active {
                    border: 1px solid #2d4af1;
                }
            }
        }
        .audio_content_list.search {
            width: 318px;
            .list_li {
                width: 100%;
                .card_item {
                    width: 300px;
                    padding: 0 25px;
                    .info {
                        .info_t {
                            .name {
                                width: 160px;
                            }
                        }
                        .info_b {
                            .type {
                                width: 150px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.select_btn {
    width: 130px;
    height: 32px;
    background: #3c4148;
    border-radius: 2px;
    cursor: pointer;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #ffffff;
}

::v-deep .search .el-input__inner {
    background: #3c4148;
    border-radius: 4px;
    border: none;
    color: #ffffff;
}
::v-deep .num .el-input__inner {
    padding: 0 5px;
    text-align: center;
}
.audio_content_menu::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
}
.audio_content_menu::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    // -webkit-box-shadow: inset 0 0 5px #636363;
    background: #636363;
}
.audio_content_menu::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // -webkit-box-shadow: inset 0 0 5px #ffffff;
    border-radius: 5px;
    background: #3c4148;
}
.audio_content_list::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
}
.audio_content_list::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px #636363;
    background: #636363;
}
.audio_content_list::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px #1b1d1f;
    border-radius: 5px;
    background: #1b1d1f;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
</style>
<style lang="scss">
.audiopopper {
    background: #1b1d1f;
    box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.34);
    border: none;
    padding: 0px;

    .popper__arrow {
        display: none;
    }
}
</style>

<template>
    <div class="video_right">
        <div class='right_content scroll_bar'>
            <div class="video_nav">
                <div class="video_nav_item" :class="{ active: navInfo.value == item.value }" v-for="item in list" :key="item.value" @click="navChange(item)">
                    <div class="img">
                        <img :src="item.icon" alt="">
                    </div>
                    <div class="title">{{ item.label }}</div>
                </div>
            </div>
        </div>
        <div class="nav_right_table_wrap" :class="!navInfo.navFlag ? 'minwidth' : 'maxwidth'">
            <div class="video_point" :class="!navInfo.navFlag ? 'video_point_close' : ''" v-if="navInfo.value">
                <i class="img" @click="openWrap"></i>
            </div>
            <material-list :navInfo="navInfo" v-if="navInfo.value == 'material'"></material-list>
            <background-list :navInfo="navInfo" v-if="navInfo.value == 'background'"></background-list>
            <picture-list :navInfo="navInfo" v-if="navInfo.value == 'picture'"></picture-list>
            <audio-list :navInfo="navInfo" v-if="navInfo.value == 'audio'"></audio-list>
            <video-list :navInfo="navInfo" v-if="navInfo.value == 'video'"></video-list>
            <text-list :navInfo="navInfo" v-if="navInfo.value == 'text'"></text-list>
            <subtitle-list :navInfo="navInfo" v-if="navInfo.value == 'subtitle'"></subtitle-list>
            <filter-list :navInfo="navInfo" v-if="navInfo.value == 'filter'"></filter-list>
            <setting-list :navInfo="navInfo" v-if="navInfo.value == 'setting'"></setting-list>
            <business-card :navInfo="navInfo" v-if="navInfo.value == 'businesscard' && templateFrom == 2"></business-card>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import MaterialList from './materials'
import BackgroundList from './background'
import PictureList from './picture'
import AudioList from './audio'
import VideoList from './video'
import TextList from './text'
import SubtitleList from './subtitle'
import FilterList from './filter'
import SettingList from './setting'
import BusinessCard from './businesscard'

export default {
    components: {
        MaterialList,
        BackgroundList,
        PictureList,
        AudioList,
        VideoList,
        TextList,
        SubtitleList,
        FilterList,
        SettingList,
        BusinessCard,
    },
    props: {

    },
    data() {
        return {
            navList: [{
                label: '我的素材',
                value: 'material',
                navFlag: true,
                icon: require('@/assets/images/icon/video_nav_1.png'),
            }, {
                label: '背景',
                value: 'background',
                navFlag: true,
                icon: require('@/assets/images/icon/video_nav_2.png'),
            }, {
                label: '图片',
                value: 'picture',
                navFlag: true,
                icon: require('@/assets/images/icon/video_nav_3.png'),
            }, {
                label: '音乐',
                value: 'audio',
                navFlag: true,

                icon: require('@/assets/images/icon/video_nav_4.png'),
            }, {
                label: '视频',
                value: 'video',
                navFlag: true,
                icon: require('@/assets/images/icon/video_nav_5.png'),
            }, {
                label: '文本',
                value: 'text',
                navFlag: true,
                icon: require('@/assets/images/icon/video_nav_6.png'),
            }, {
                label: '名片',
                value: 'businesscard',
                navFlag: true,
                icon: require('@/assets/images/icon/video_nav_6.png'),
            }, {
                label: '字幕',
                value: 'subtitle',
                navFlag: true,
                icon: require('@/assets/images/icon/video_nav_7.png'),
            },
            //  {
            //     label: '滤镜',
            //     value: 'filter',
            //     navFlag: true,
            //     icon: require('@/assets/images/icon/video_nav_8.png'),
            // },
            {
                label: '设置',
                value: 'setting',
                navFlag: true,
                icon: require('@/assets/images/icon/video_nav_9.png'),
            }
            ],
        }
    },
    computed: {
        ...mapState({
            navInfoCard: state => state.videoCreate.rightNavInfo,
            templateFrom: state => state.videoCreate.template_from,//1视频 2名片
        }),
        list() {
            if (this.templateFrom == 2) {
                return this.navList
            }
            if (this.templateFrom == 1) {
                return this.navList.filter(item => { return item.value != 'businesscard' })
            }
        },
        navInfo() {
            return this.navInfoCard
        }
    },
    methods: {
        navChange(item) {
            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', item)
        },
        // 打开
        openWrap() {
            let obj = {
                ...this.navInfo,
                navFlag: !this.navInfo.navFlag,
            }
            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', obj)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";

.video_right {
    width: 100%;
    height: 100%;
}

.right_content {
    position: absolute;
    top: 0;
    right: 0;
    // position: relative;
    z-index: 2;
    // width: 100%;
    width: 80px;
    height: 100%;
    // padding-left: 4px;
    background: #1e232a;
    overflow: auto;

    .video_nav {
        width: 100%;
        height: 100%;
        padding-top: 10px;
        background: #1e232a;

        .video_nav_item {
            width: 80px;
            height: 80px;
            text-align: center;
            cursor: pointer;
            padding: 15px 0;

            .title {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }

            .img {
                width: 28px;
                height: 28px;
                margin: 0 auto;

                img {
                    width: 100%;
                }
            }
        }

        .video_nav_item.active {
            background: #2e363f;
        }
    }
}
</style>
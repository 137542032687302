<template>
    <div class="upload">
        <div class="convert">
            <div class="convert-btn" @click="openUrl">
                <img src="@/assets/images/editor/icon20.png" alt="">
                <div class="txt">链接转录音</div>
            </div>
            <div class="convert-btn" @click="openVideo">
                <img src="@/assets/images/editor/icon21.png" alt="">
                <div class="txt">视频转录音</div>
            </div>
        </div>
        <el-upload class="upload-box" element-loading-background="rgba(0, 0, 0, 0.3)" v-loading="loading" action="#" accept="audio/wav,audio/mp3,audio/m4a,audio/x-m4a" :show-file-list="false" :http-request="uploadSuccess">
            <img class="img" src="@/assets/images/editor/icon22.png" alt="">
            <el-button class="btnBgColor_blue" size="small">上传录音</el-button>
        </el-upload>

        <!-- <div class="text">单次最多上传20个录音文件，</div> -->
        <div class="text">
            支持mp3、wav、flac、m4a文件，单个录音时长小于1小时，请上传纯干音文件，背景音、噪声会影响视频合成效果哦～
        </div>
        <video-convert ref="videoconvert"></video-convert>
        <url-convert ref="urlconvert" :type="2"></url-convert>
    </div>
</template>

<script>
import { mapState } from "vuex"
import UrlConvert from './urlConvert.vue' //链接转剧本
import VideoConvert from './videoConvert.vue' //视频转录音
export default {
    components: { UrlConvert, VideoConvert },
    props: {

    },
    data() {
        return {
            num: 0,
            acceptList: ['audio/wav', 'audio/mp3', 'audio/m4a', 'audio/x-m4a', 'audio/mpeg'],
            loading: false,
        };
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,
            sceneIndex: state => state.videoData.sceneIndex,
            default_tts: state => state.voiceInfo.default_tts,
            ttsType: state => state.voiceInfo.ttsType,
        }),
        info() {
            return this.sceneList[this.sceneIndex].data
        },
    },
    created() {

    },
    mounted() {

    },
    watch: {

    },
    methods: {
        openUrl() {
            this.$refs.urlconvert.open()
        },
        openVideo() {
            this.$refs.videoconvert.open()
        },
        uploadSuccess(item) {
            var that = this
            // 限制图片格式
            const isType = this.acceptList.includes(item.file.type)
            // 限制图片大小
            const isLt50M = item.file.size / 1024 / 1024 <= 50;
            if (!isType) {
                that.$errMsg('上传视频只能是 MP3 , m4a, mav 格式!');
            }
            if (!isLt50M) {
                that.$errMsg('上传视频大小不能超过 50MB!');
            }
            if (isType && isLt50M) {
                let newFile = new FormData()
                newFile.append('type', 5);
                newFile.append('file', item.file);
                that.loading = true
                that.$publicApi.uploadImg(newFile).then(res => {
                    that.loading = false
                    if (res.code == 1000) {
                        let obj = {
                            ttsAudio: res.result.url,
                            duration: 0,
                            content: this.info.content,
                            name: item.file.name,
                            subtitleList: [],
                            label: this.default_tts.label,
                            speed_rate: this.default_tts.speed,
                            volume: this.default_tts.volume,
                            pitch: this.default_tts.intonation,
                            sample_rate: this.default_tts.rate,
                            id: this.default_tts.id,
                            ttsType: this.ttsType
                        }
                        this.$store.commit('videoData/SET_SECNE_DATA', obj)
                        this.$store.commit('voiceInfo/SET_CURRENT', 2)
                        // 清空字幕数据
                        this.$store.commit('videoData/SET_SUBTITLE_LIST', [])
                        this.$emit('close')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
    },
};
</script>

<style scoped lang="scss">
.convert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    .convert-btn {
        width: 168px;
        height: 52px;
        background: #3c4148;
        border-radius: 4px;
        border: 1px solid #838383;
        display: flex;
        align-items: center;
        padding-left: 20px;
        cursor: pointer;
        img {
            width: 24px;
            height: 24px;
        }
        .txt {
            font-size: 14px;
            color: #ffffff;
            margin-left: 12px;
            margin-right: 6px;
        }
        .icon {
            width: 12px;
            height: 12px;
        }
    }
}
.upload-box {
    width: 356px;
    height: 166px;
    background: #3c4148;
    border-radius: 4px;
    border: 1px dashed #838383;
    margin: 20px 0;
    text-align: center;
    cursor: pointer;
    .img {
        width: 60px;
        height: 60px;
        margin: 20px 10px 14px;
    }
}
.text {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
}
</style>

<template>
    <div class="voice-main-ai">
        <div class="popper-list">
            <div class="convert">
                <div class="convert-btn">
                    <img src="@/assets/images/editor/icon18.png" alt="">
                    <div class="txt">文件转剧本</div>
                    <el-tooltip effect="dark" placement="top">
                        <div slot="content">
                            * 支持.txt,.mp4,.mp3,.wav格式文件；<br />
                            * 录音或视频时长不大于1小时，文本内容不超过20000字符； <br />
                            * 本次解析剧本默认拼接再已有剧本之后；<br />
                        </div>
                        <img class="icon" src="@/assets/images/editor/icon2.png" alt="">
                    </el-tooltip>
                </div>
                <div class="convert-btn" @click="openUrl">
                    <img src="@/assets/images/editor/icon19.png" alt="">
                    <div class="txt">链接转剧本</div>
                </div>
            </div>
            <div class="li">
                <div class="text">音色</div>
                <div class="input" style="width: 130px;">
                    <voice-select></voice-select>
                </div>
                <div class="text" style="width: 82px;">应用到全局</div>
                <el-switch v-model="value" active-color="#2B4AFE" inactive-color="#DCDFE6">
                </el-switch>
            </div>
            <div class="li">
                <div class="text">语速</div>
                <div class="input"><el-input class="num" size="small" type="number" v-model="default_tts.speed_rate" placeholder=""></el-input></div>
                <div class="slider">
                    <el-slider v-model="default_tts.speed_rate" :min="-20" :max="20" :step="1" :show-tooltip="false"></el-slider>
                </div>
            </div>
            <div class="li">
                <div class="text">音量</div>
                <div class="input"><el-input class="num" size="small" type="number" v-model="default_tts.volume" placeholder=""></el-input></div>
                <div class="slider">
                    <el-slider v-model="default_tts.volume" :min="-20" :max="20" :step="1" :show-tooltip="false"></el-slider>
                </div>
            </div>
            <div class="li">
                <div class="text">语调</div>
                <div class="input"><el-input class="num" size="small" type="number" v-model="default_tts.pitch" placeholder=""></el-input></div>
                <div class="slider">
                    <el-slider v-model="default_tts.pitch" :min="-20" :max="20" :step="1" :show-tooltip="false"></el-slider>
                </div>
            </div>
            <div class="li">
                <div class="text">采样率</div>
                <div class="input" style="width: 130px;">
                    <el-select size="small" v-model="default_tts.sample_rate" placeholder="请选择">
                        <el-option v-for="item in sample_rate" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="li">
                <div class="text">插入停顿</div>
                <div class="select-box" v-for="item in numlist" :key="item">
                    {{item}}秒
                </div>
                <div class="input-box">
                    <el-input class="gray" size="small" type="number" v-model="default_tts.volume" placeholder=""></el-input>
                    <div class="text">秒</div>
                </div>
            </div>
        </div>
        <url-convert ref="urlconvert" :type="1"></url-convert>
    </div>
</template>

<script>
import { mapState } from "vuex"
import VoiceSelect from './voiceSelect.vue'  //选择声音
import UrlConvert from './urlConvert.vue'//链接转剧本
export default {
    components: { VoiceSelect, UrlConvert },
    props: {

    },
    data() {
        return {
            value: false,
            numlist: ['0.5', '1', '2'],
        };
    },
    computed: {
        ...mapState({
            sample_rate: state => state.voiceInfo.sample_rate,
            default_tts: state => state.voiceInfo.default_tts,
        }),
    },
    created() {

    },
    mounted() {

    },
    watch: {

    },
    methods: {
        openUrl() {
            this.$refs.urlconvert.open()
        },
    },
};
</script>

<style scoped lang="scss">
.convert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    .convert-btn {
        width: 168px;
        height: 52px;
        background: #3c4148;
        border-radius: 4px;
        border: 1px solid #838383;
        display: flex;
        align-items: center;
        padding-left: 20px;
        cursor: pointer;
        img {
            width: 24px;
            height: 24px;
        }
        .txt {
            font-size: 14px;
            color: #ffffff;
            margin-left: 12px;
            margin-right: 6px;
        }
        .icon {
            width: 12px;
            height: 12px;
        }
    }
}

.popper-list {
    .li {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .text {
            width: 70px;
            color: #ffffff;
            padding-left: 2px;
        }
        .input {
            width: 60px;
            margin-right: 20px;
            display: flex;
        }
        .slider {
            width: 204px;
        }
        .select-box {
            width: 60px;
            height: 32px;
            background: #3c4148;
            border-radius: 4px;
            font-size: 14px;
            color: #ffffff;
            line-height: 32px;
            margin-right: 10px;
            text-align: center;
            cursor: pointer;
        }
        .input-box {
            width: 78px;
            height: 32px;
            background: #3c4148;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #ffffff;
            padding-left: 6px;
        }
    }
}

::v-deep .el-slider__button {
    background: url(~@/assets/images/editor/icon5.png) no-repeat 100% / cover;
    border: none;
    width: 8px;
    height: 14px;
    border-radius: 0;
}

::v-deep .el-slider__runway {
    background-color: #3c4148;
}
::v-deep .el-slider__bar {
    background-color: #ffffff;
}
::v-deep .el-input__inner {
    padding: 0 10px;
    background: #3c4148;
    border-radius: 4px;
    font-size: 14px;
    border: none;
    color: #ffffff;
}
::v-deep .num .el-input__inner {
    text-align: center;
}

::v-deep .gray .el-input__inner {
    font-size: 14px;
    border: none;
    color: #ffffff;
    width: 46px;
    height: 20px;
    background: #818893;
    border-radius: 4px;
    line-height: 20px;
    text-align: center;
}
</style>
<style lang="scss" >
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -o-appearance: none !important;
    -ms-appearance: none !important;
    appearance: none !important;
    margin: 0;
}
input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    -o-appearance: textfield;
    -ms-appearance: textfield;
    appearance: textfield;
}
</style>

<template>
    <div class="captions-track">
        <div class="captions-list" @click.stop>
            <div class="captions-item" :class="{active:item.id==current}" draggable="false" v-for="(item,index) in list" :key="item.id" :style="captionsStyle(item)" @mouseenter="mouseenter(item.id)" @mouseleave="mouseleave">
                <div class="left" @mousedown.stop="mousedown($event,item,index,'left')"></div>
                <div class="move" @mousedown.stop="mousedown($event,item,index,'move')">{{item.content}}</div>
                <div class="right" @mousedown.stop="mousedown($event,item,index,'right')"></div>
            </div>

            <!-- 滤镜展示 -->
            <div class="popup-box" v-show="item.id==hoverId" v-for="(item,index) in list" :key="index+1000" :style="{left:(((item.end-item.start)/2)+item.start)*sWidth+'px'}">
                <div class="popup-content">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        sWidth: Number,  //刻度尺每秒宽度 px
        audioDuration: Number, //音频时长
        paused: Boolean, //是否暂停
        currentTime: Number,  //当前播放时间
    },
    data() {
        return {
            list: [
                {
                    "start": 3,
                    "end": 4,
                    "content": "古都",
                    "id": 2
                },
                {
                    "start": 7.69,
                    "end": 10.19,
                    "content": "奶绿",
                    "id": 3
                },
                {
                    "start": 11.19,
                    "end": 16.00,
                    "content": "元气阳光",
                    "id": 4
                },
            ],
            current: '',
            startx: '', //上次移动开始距离
            index: '',
            hoverId: '',
        };
    },
    created() {

    },
    mounted() {

    },
    watch: {
        currentTime(val) {
            this.current = ''
            this.index = ''
        },
    },
    methods: {
        // 鼠标移入展示滤镜效果
        mouseenter(id) {
            this.hoverId = id
        },
        // 鼠标移出消失
        mouseleave() {
            this.hoverId = ''
        },

        // 鼠标按下
        mousedown(e, item, index, type) {
            if (!this.paused) {
                return
            }

            this.current = item.id
            this.index = index

            // 绑定mousemove
            this.startx = e.pageX;
            if (type == 'move') {
                document.addEventListener('mousemove', this.mousemove)
            } else if (type == 'left') {
                document.addEventListener('mousemove', this.resizeMoveLeft)
            } else if (type == 'right') {
                document.addEventListener('mousemove', this.resizeMoveRight)
            }
            document.addEventListener('mouseup', this.mouseup)

            // 清空素材轨道选中
            this.$emit('clear')
        },
        // 右侧放大缩小
        resizeMoveRight(e) {
            let step = e.pageX - this.startx;
            this.startx = e.pageX;
            // 下一条的开头时间
            let nextSec = this.list[this.index + 1] ? this.list[this.index + 1].start : this.audioDuration
            // 拖动后结束时间
            let newEnd = this.list[this.index].end + (step / this.sWidth)
            // 结束时间减去开始时间 小于20像素 return
            let max = (this.list[this.index].end * this.sWidth)
            let min = (this.list[this.index].start * this.sWidth) + 20
            // 最小值为上一个的尾部
            if (nextSec >= newEnd) {
                // 最小限制20像素
                if (max > min) {
                    this.list[this.index].end += step / this.sWidth
                } else {
                    // 大于0是向右  小于0向左
                    if (step > 0) {
                        this.list[this.index].end += step / this.sWidth
                    } else {
                        return
                    }
                }
            } else {
                return
            }
        },
        // 左侧放大缩小
        resizeMoveLeft(e) {
            let step = e.pageX - this.startx;
            this.startx = e.pageX;
            // 上一个的结尾时间
            let prevSec = this.list[this.index - 1] ? this.list[this.index - 1].end : 0
            // 拖动后开始时间
            let newStart = this.list[this.index].start + (step / this.sWidth)
            // 结束时间减去开始时间 小于20像素 return
            let max = (this.list[this.index].end * this.sWidth)
            let min = (this.list[this.index].start * this.sWidth) + 20
            // 最小值为上一个的尾部
            if (prevSec <= newStart) {
                // 最小限制20像素
                if (max > min) {
                    this.list[this.index].start += step / this.sWidth
                } else {
                    // 大于0是向右  小于0向左
                    if (step < 0) {
                        this.list[this.index].start += step / this.sWidth
                    } else {
                        return
                    }
                }
            } else {
                return
            }
        },


        // 左右拖动
        mousemove(e) {
            let step = e.pageX - this.startx;
            this.startx = e.pageX;
            // 上一个的结尾时间
            let prevSec = this.list[this.index - 1] ? this.list[this.index - 1].end : 0
            // 下一条的开头时间
            let nextSec = this.list[this.index + 1] ? this.list[this.index + 1].start : this.audioDuration
            // 拖动后开始时间
            let newStart = this.list[this.index].start + (step / this.sWidth)
            // 拖动后结束时间
            let newEnd = this.list[this.index].end + (step / this.sWidth)
            if (prevSec <= newStart && nextSec >= newEnd) {
                this.list[this.index].start += step / this.sWidth
                this.list[this.index].end += step / this.sWidth
            } else {
                return
            }
        },
        // 解除绑定mousemove
        mouseup() {
            document.removeEventListener('mousemove', this.mousemove, false)
            document.removeEventListener('mousemove', this.resizeMoveLeft, false)
            document.removeEventListener('mousemove', this.resizeMoveRight, false)
        },
        // 动态样式
        captionsStyle(item) {
            return {
                width: (item.end - item.start) * this.sWidth + 'px',
                left: (item.start * this.sWidth) + 'px'
            }
        },
    },
};
</script>

<style scoped lang="scss">
.captions-track {
    width: 100%;
    height: 24px;
    background: #282828;
    margin-top: 6px;
    margin-left: 16px;
    position: relative;
    z-index: 0;
    .captions-list {
        display: flex;
        width: 100%;
        .captions-item {
            height: 24px;
            background: #4f317c;
            border-radius: 5px;
            line-height: 24px;
            text-align: center;
            padding: 0 6px;
            color: #ffffff;
            cursor: pointer;
            text-align: center;
            font-size: 12px;
            position: absolute;
            top: 0;
            .move {
                width: 100%;
                height: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .left {
                width: 4px;
                height: 24px;
                position: absolute;
                left: 0;
                top: 0;
                cursor: ew-resize;
            }
            .right {
                width: 4px;
                height: 24px;
                position: absolute;
                right: 0;
                top: 0;
                cursor: ew-resize;
            }
        }
        .captions-item.active {
            background: linear-gradient(90deg, #782ef2 0%, #7b47bd 100%);
        }
    }
}

.popup-box {
    position: absolute;
    top: -130px;
    width: 88px;
    height: 120px;
    border-radius: 2px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.8);
    padding: 6px;
    transform: translateX(-50%);
    .popup-content {
        width: 100%;
        height: 100%;
        border-radius: 2px;
        background: #8b7878;
    }
    &::before {
        content: "";
        display: block;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid rgba(0, 0, 0, 0.8);
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>

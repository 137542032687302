<template>
    <div>
        <!-- 音频 -->
        <audio v-show="false" @canplay="getDuration" controls :ref="'audio'+info.id" :src="info.url"></audio>
    </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    props: {
        type: {
            type: String,
            default: 'audio',
        },
        index: {
            type: Number,
            default: 0,
        },
        info: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {

        };
    },
    computed: {
        ...mapState({
            currentTime: state => state.videoData.currentTime,
            paused: state => state.videoData.paused,
            clickTime: state => state.videoData.clickTime, //点击轨道时候时间
        }),

        show() {
            if ((this.info.start / 1000) <= this.currentTime && this.currentTime <= (this.info.end / 1000)) {
                return true
            } else {
                return false
            }
        },
    },
    created() {

    },
    mounted() {

    },
    watch: {
        clickTime(val) {
            this.audioChange(val, this.paused)
        },
        show(val) {
            if (val) {
                this.audioChange(this.currentTime, this.paused)
            } else {
                if (this.type != 'audio') {
                    return
                }
                this.audio.pause()
            }
        },
        paused(val) {
            this.audioChange(this.currentTime, val)
        },
    },
    methods: {
        // 音频改变播放时间   播放/暂时
        audioChange(val, paused) {
            // 不是音频return
            if (this.type != 'audio') {
                return
            }
            let time = val - (this.info.start / 1000)
            if (time >= 0) {
                if (paused) {
                    this.audio.pause()
                } else {
                    this.audio.currentTime = time
                    this.audio.play()
                }
            } else {
                this.audio.pause()
            }
        },
        //视频在可以播放时触发
        getDuration() {
            this.audio = this.$refs[`audio${this.info.id}`];
        },
    },
    components: {

    },
};
</script>

<style scoped lang="scss">
.video {
    object-fit: fill;
}
</style>

<template>
    <div class="nav_left_table_wrap">
        <div class="video_table" :class="!navInfo.value == 'ppt' ? 'video_table_close' : ''">
            <!-- 标题 -->
            <div class="table_title">
                <div class="title">选择{{ navInfo.label }}</div>
                <i class="el-icon-close" @click="close"></i>
            </div>
            <div class="table_content">
                <div class="list-head">
                    <div class="upload-box">
                        <el-upload class="upload-demo" ref="uploadRef" action="#" :show-file-list="false" accept="application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation" :http-request="uploadChange">
                            <el-button class="button"><i class="el-icon-upload el-icon--right"></i>上传</el-button>
                        </el-upload>
                    </div>
                    <div class="search-box">
                        <div class="search_inp">
                            <el-input placeholder="请输入内容" prefix-icon="el-icon-search" size="small" v-model="name" clearable @clear="reset" @keyup.enter.native="reset">
                            </el-input>
                        </div>
                    </div>
                    <div class="select-link" :class="{ 'disabled': currentppt != '' }">
                        <span class="span" @click="ischeckedChange">{{ !ischecked ? '选择' : '取消' }}</span>
                    </div>
                </div>

                <div class="ppt_list">
                    <div class="contentbox scroll_bar" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="list">
                            <div class="item" v-for="(item, x) in list" :key="x" @click="selectppt(item)">
                                <div class="name">{{ item.name }}</div>
                                <div class="item-pic" :class="{ 'active': currentppt == item.id }">
                                    <div class="picbox">
                                        <el-image style="width: 300px; height: 168px" :src='item.thumbnail' fit="contain">
                                            <div slot="error" class="image-slot">
                                                <img src="@/assets/images/icon/img.png" alt="">
                                            </div>
                                        </el-image>
                                    </div>
                                    <!-- 左边的选中框 -->
                                    <div class="selectCheck" v-if="ischecked">
                                        <el-checkbox v-model="item.checked"><br /></el-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="upload_loading" v-if="uploadLoading">
                            <div class="el-icon-loading icon"></div>
                            <div class="txt">{{fileName}}</div>
                        </div>
                        <!-- 没有更多 -->
                        <Loading :loading="loading" :list="list" :page="page" :totalPage="last_page">
                        </Loading>
                    </div>
                </div>
            </div>
            <!-- 最下方的删除弹窗 -->
            <div class="deletebox" :class="{ 'deleteboxShow': ischecked }">
                <div class="deletebox-l">
                    <el-checkbox v-model="checkedAll" @change="checkedAllOrClean"></el-checkbox>
                    全选
                </div>
                <div class="deletebox-r">
                    <div class="button" :class="{ 'deleteOpacity': deleteIds.length == 0 }" @click="openDelete">删除</div>
                </div>
            </div>
        </div>
        <Selectfiles :type="navInfo.value" ref="selectfiles"></Selectfiles>
        <!-- 删除的提示 -->
        <point-out ref="point" @close='confirmBtn' dialogTitle="提示" dialogWidth='380px' type="works" iconColor="#FF9665" borderRadius='10px' :iconShow="true" :affirmShow="true" affirmText='确 定'>
            <div class="zc_dialog_tip_content">
                <div class="text">文件一旦删除后将无法进行恢复，是否继续？</div>
            </div>
        </point-out>
    </div>
</template>

<script>
export default {
    props: {
        navInfo: {
            type: Object,
            default: () => { }
        },
    },
    components: {
        PointOut: resolve => require(['@/components/point_out/point_out'], resolve),
        Loading: (resolve) => require(['@/components/loading/index'], resolve),
        Selectfiles: (resolve) => require(['@/views/createvideo/components/video_left/selectfiles'], resolve),
    },
    data() {
        return {
            loading: false,
            page: 0,
            last_page: 1, // 总页数
            limit: 20,
            name: '',
            list: [],
            checkedAll: false, // 选择所有
            ischecked: false, // 是否显示复选框
            currentppt: '', // 当前选中的ppt
            uploadLoading: false, // 上传loading
            fileName: '文件解析中', // 上传文件名称
        }
    },
    computed: {
        // 需要删除的数组的列表
        deleteIds() {
            let arr = this.list.filter(item => item.checked).map(item => item.id)
            return arr
        }
    },
    watch: {
        deleteIds() {
            if (this.deleteIds.length) {
                this.checkedAll = true
            } else {
                this.checkedAll = false
            }
        }
    },
    methods: {
        // 关闭
        close() {
            let obj = {}
            this.$store.commit('videoCreate/SET_LEFT_NAV_INFO', obj)
        },
        // 上传之前的钩子函数
        uploadChange(response) {
            const file = response.file
            // 上传word
            var skuList = 'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation'
            // 限制格式
            var isSku = skuList.includes(file.type)

            if (!isSku) {
                that.$errMsg('上传文件只能是 WORD 文件!');
            }
            console.log(file)
            // 上传的进度
            const config = {
                onUploadProgress: progressEvent => {
                    this.progressPercent = Number((progressEvent.loaded / progressEvent.total * 100).toFixed(2))
                    // console.log(this.progressPercent)
                }
            }
            this.uploadLoading = true
            const newFile = new FormData()
            newFile.append('file', file)
            newFile.append('type', 1)
            this.$workbenApi.upload_ppt_word(newFile, config).then(res => {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: res.code === 1000 ? 'success' : 'error'
                })
                if (res.code === 1000) {
                    this.uploadLoading = false
                    this.reset()
                }
            })
        },
        // 加载更多
        load() {
            if (this.loading || this.page === this.last_page || this.last_page === 0) return
            this.page++
            this.getList()
        },
        // 重置
        reset() {
            this.list = []
            this.page = 1
            this.getList()
        },
        // 是否显示复选框
        ischeckedChange() {
            this.ischecked = !this.ischecked
        },
        // 列表
        getList() {
            this.loading = true
            let obj = {
                page: this.page,
                limit: this.limit,
                category: 1,//资料分类 1:ppt;2:word
                name: this.name,
            }
            this.$workbenApi.get_ppt_word_list(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = [...this.list, ...res?.result?.data] || []
                    this.last_page = res?.result?.last_page
                    this.page = res?.result?.current_page ?? 1
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 选择全部或者清除全部
        checkedAllOrClean() {
            this.checkedAll = !this.checkedAll
            this.list.forEach(item => {
                if (this.checkedAll) {
                    item.checked = false
                } else {
                    item.checked = true
                }
            });
        },
        // 打开删除的弹窗
        openDelete() {
            if (this.deleteIds.length > 0) {
                this.$refs.point.openDialogBtn()
            }
        },
        // 确认删除的回调
        confirmBtn() {

        },
        // 选中ppt
        selectppt(item) {
            // 如果点击了选择按钮 做删除处理
            if (this.ischecked) {
                this.$set(item, 'checked', !item.checked)
            } else { // 如果是选中 显示素材框
                if (this.currentppt == item.id) {
                    this.currentppt = ''
                    this.$refs.selectfiles.close()
                } else {
                    this.currentppt = item.id
                    this.$refs.selectfiles.open()
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";

.nav_left_table_wrap {
    width: 340px;
}

.video_table {
    width: 340px;
}

.contentbox {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 360px);
}
</style>
<template>
    <div class="">
        <div class="video_table" :class="!navInfo.navFlag ? 'video_table_close' : ''">
            <!-- 标题 -->
            <div class="table_title">
                <i class="el-icon-close" @click="close"></i>
                <div class="title">文本</div>
            </div>
            <div class="table_content">
                <!-- 添加 -->
                <div class="add-btn" @click="selectMaterials">
                    <i class="el-icon-circle-plus-outline icon"></i>
                    添加文字
                </div>

                <!-- 下方的筛选项目连带搜索 -->
                <div class="search-box">
                    <div class="search_inp">
                        <el-input placeholder="请输入素材名称" prefix-icon="el-icon-search" size="small" v-model="name" clearable @input="inputBtn" @clear="reset" @keyup.enter.native="reset">
                        </el-input>
                    </div>
                </div>

                <div class="txtlist">
                    <div class="contentbox scroll_bar" v-if="category" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div>
                            <!-- 分类 -->
                            <div class="list-title">
                                <div class="title">{{ categoryInfo.name }}</div>
                                <div class="icon" @click="expandClose()">
                                    <i class="el-icon-arrow-left"></i>
                                </div>
                            </div>
                            <div class="item" v-for="(item, x) in searchlist" :key="x">
                                {{ item.content }}
                            </div>
                            <!-- 没有更多 -->
                            <Loading :loading="loading" :list="searchlist" :page="page" :totalPage="last_page">
                            </Loading>
                        </div>
                    </div>
                    <div class="contentbox scroll_bar" v-else-if="name" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div>
                            <div class="item" v-for="(item, x) in searchlist" :key="x">
                                {{ item.content }}
                            </div>
                            <!-- 没有更多 -->
                            <Loading :loading="loading" :list="searchlist" :page="page" :totalPage="last_page">
                            </Loading>
                        </div>
                    </div>
                    <div class="contentbox scroll_bar" v-else v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div v-for="(ele, i) in list" :key="i">
                            <!-- 分类 -->
                            <div class="list-title">
                                <div class="title">{{ ele.name }}</div>
                                <div class="icon" @click="moreBtn(ele)">
                                    <span>更多<i class="el-icon-arrow-right"></i></span>
                                </div>
                            </div>
                            <div class="item" v-for="(item, x) in ele.materialText" :key="x">
                                {{ item.content }}
                            </div>
                        </div>
                        <!-- 没有更多 -->
                        <Loading :loading="loading" :list="list">
                        </Loading>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapState
} from "vuex"
export default {
    components: {
        Loading: (resolve) => require(['@/components/loading/index'], resolve)
    },
    props: {
        navInfo: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            name: '',
            category: '',
            loading: false,
            page: 0,
            last_page: 1, // 总页数
            limit: 20,
            list: [],
            categoryInfo: {},
            searchlist: [],
        }
    },
    created() {
        this.getList()
    },
    computed: {
        ...mapState({
            currentTime: state => state.videoData.currentTime,//当前播放时间
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            duration: state => state.videoData.duration, //音频总时长
            sizeRatio: state => state.videoCreate.sizeRatio,//宽高比2=9:16 1=16:9
        }),
        searchForm() {
            return {
                page: this.page,
                limit: this.limit,
                category_id: this.category,
                name: this.name
            }
        },
    },
    methods: {
        // 关闭
        close() {
            let obj = {
                ...this.navInfo,
                navFlag: false,
            }
            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', obj)
        },
        //
        inputBtn() { },
        // 加载更多
        load() {
            if (this.loading || this.page === this.last_page || this.last_page === 0) return
            this.page++
            this.getSearchList()
        },
        // 重置
        reset() {
            this.category = ''
            this.searchlist = []
            this.page = 1
            this.getSearchList()
        },
        // 列表
        getList() {
            this.loading = true
            this.$workbenApi.text_cate().then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result
                    // this.list = [
                    //   {
                    //     "id": 33,
                    //     "parent_id": 9,
                    //     "name": "分类1",
                    //     "sort": 8,
                    //     "status": 1,
                    //     "create_time": "2023-09-06 10:00:19",
                    //     "update_time": "2023-09-06 10:00:19",
                    //     "materialText": [
                    //       {
                    //         "id": 2,
                    //         "name": "测试",
                    //         "category_id": 33,
                    //         "content": "我是我",
                    //         "txt_num": 3,
                    //         "status": 1,
                    //         "system_user_id": 1,
                    //         "create_time": "2023-09-06 10:23:32",
                    //         "update_time": "2023-09-06 10:23:32"
                    //       }
                    //     ]
                    //   }
                    // ]
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 列表-带搜索
        getSearchList() {
            this.loading = true
            this.$workbenApi.text_list({
                ...this.searchForm
            }).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.searchlist = [...this.searchlist, ...res?.result?.data] || []

                    this.last_page = res?.result?.last_page
                    this.page = res?.result?.current_page ?? 1
                    // this.searchlist = [
                    //   {
                    //     "id": 2,
                    //     "name": "测试",
                    //     "category_id": 33,
                    //     "content": "我是我",
                    //     "txt_num": 3,
                    //     "status": 1,
                    //     "system_user_id": 1,
                    //     "create_time": "2023-09-06 10:23:32",
                    //     "update_time": "2023-09-06 10:23:32"
                    //   }
                    // ]
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 更多
        moreBtn(ele) {
            this.categoryInfo = ele
            this.category = ele.id
            this.getSearchList()
            // this.$set(ele, 'expand', !ele.expand)
        },
        // 收起
        expandClose() {
            this.category = ''
            this.searchlist = []
        },
        // 插入文本
        selectMaterials() {
            var that = this
            var arr = JSON.parse(JSON.stringify(that.sceneList))
            var idx = that.sceneIndex
            var scenes = arr[idx].scenes_conf || []

            var str = {
                id: new Date().getTime(),
                type: 'text',
                start: that.currentTime * 1000,
                end: that.duration * 1000,
                content: "双击编辑文字",
                lock: false,
                style: {
                    height: 90,
                    left: 627,
                    top: 112,
                    width: 608,
                    rotation: 0
                },

                textStyle: {
                    fontFamily: "SourceHanSerifCN-Regular",
                    fontSize: 60,
                    color: "#000000",
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    lineHeight: 1.5,
                    letterSpacing: 0,
                },
            }
            // 默认16:9  9比16时候位置更换
            if (this.sizeRatio == 2) {
                str.style.left = 244
            }
            scenes.push(str)
            var params = {
                type: 'conf',
                info: scenes
            }
            that.$store.dispatch('videoData/modifyProduct', params)
            // 插入后选中
            that.$store.commit('videoData/SET_SHOW_ID', str.id)
        },

    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";

// .search-box {
//   padding-top: 0;
//   padding-bottom: 15px;
// }

.contentbox {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 320px);
    margin-right: -8px;
}

.add-btn {
    width: 306px;
    height: 38px;
    background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
    text-align: center;
    cursor: pointer;
}
</style>
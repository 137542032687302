<template>
    <div class="voice">
        <el-popover popper-class="voice-popper" ref="popovermain" placement="top" title="" width="396" trigger="click">
            <div class="voice-top">
                <div class="item" :class="{ active: ttsType == item.value }" v-for="(item, index) in list" :key="index" @click="changeList(item.value)">
                    {{item.label }}
                </div>
            </div>
            <voice-ai v-if="ttsType==1"></voice-ai>
            <voice-upload v-if="ttsType==2" @close="close"></voice-upload>
            <voice-user v-if="ttsType==3"></voice-user>
            <div class="tomake">
                去制作音频 >>
            </div>
            <div class="a-btn" slot="reference">
                <img src="@/assets/images/editor/icon1.png" alt="">
                <span>{{name}}</span>
            </div>
        </el-popover>
    </div>
</template>
<script>
import { mapState } from "vuex"
import VoiceAi from './voiceAi.vue'
import VoiceUpload from './voiceUpload.vue'
import VoiceUser from './voiceUser.vue'
export default {
    components: {
        VoiceAi,
        VoiceUser,
        VoiceUpload
    },
    data() {
        return {
            list: [
                {
                    label: 'AI合成',
                    value: 1
                },
                {
                    label: '上传录音',
                    value: 2
                },
                {
                    label: '我的',
                    value: 3
                },
            ],
        }
    },
    computed: {
        ...mapState({
            ttsType: state => state.voiceInfo.ttsType,
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            default_tts: state => state.voiceInfo.default_tts
        }),
        name() {
            let arr = this.sceneList[this.sceneIndex]?.data
            if (arr) {
                if (arr.name) {
                    return arr.name
                } else {
                    return this.default_tts.name
                }

            } else {
                return this.default_tts.name
            }
        },
    },
    created() {

    },
    methods: {
        close() {
            this.$refs.popovermain.doClose();
        },
        changeList(val) {
            this.$store.commit('voiceInfo/SET_TTS_TYPE', val)
        },
    }
}
</script>
<style lang="scss" scoped>
.voice-top {
    width: 356px;
    height: 32px;
    background: #1b1d1f;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .item {
        width: 33%;
        height: 32px;
        text-align: center;
        line-height: 32px;
        color: #999999;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        cursor: pointer;
        border-radius: 4px;
    }

    .item.active {
        background: #5c6470;
        color: #ffffff;
    }
}
.tomake {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff7432;
    position: absolute;
    bottom: 10px;
    right: 20px;
    cursor: pointer;
}

.a-btn {
    display: inline-block;
    padding: 0 10px;
    height: 28px;
    background: rgba(48, 77, 242, 0.5);
    border-radius: 4px;
    border: 1px solid #2e4bf2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    img {
        margin-right: 4px;
        width: 15px;
        height: 14px;
    }
    span {
        display: inline-block;
        max-width: 70px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>

<style lang="scss" >
.voice-popper {
    width: 396px;
    height: 524px;
    padding: 20px;
    border: 1px solid #6e757e;
    background: #2b3036;
    border-radius: 10px;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);

    .popper__arrow {
        display: none;
        border-bottom-color: #2b3036 !important;
    }
}
</style>
<template>
    <div class="material-track">
        <div class="material-box" @click.stop>

            <!-- 轨道内容 -->
            <draggable-resizable class="material-item" v-if="item.id==showId" v-for="(item,index) in materialList" :key="item.id" :draggable="drag" :resizable="drag" :w="(item.end-item.start)*sWidth" :h="24" :x="item.start*sWidth" :min-width="sWidth" :min-height="24" :prevent-deactivation="true" :active="true" @activated="activatedSelect(item, index)" @dragging="onDrag" @resizing="onResize" @dragstop="onStop(item)" @resizestop="onStop(item)" :handles="['ml', 'mr']" :parent="true">
                <div class="time left">{{(item.start.toFixed(3)*1000)/1000}}</div>
                <div class="time right">{{(item.end.toFixed(3)*1000)/1000}}</div>
                <div class="handle-l" v-if="!drag"></div>
                <div class="handle-r" v-if="!drag"></div>
            </draggable-resizable>

            <!-- 下面展示标签 -->
            <div class="label-list" :id="'label'+index" :class="{active:labelId==item.id}" v-for="(item,index) in labelList" :key="item.id+1000" :style="{left:item.start*sWidth+'px'}" @mouseenter="mouseenter(index,item)" @mouseleave="mouseleave(index)" @mousedown.stop="selectId(item)">
                <div class="label-content" v-if="item.children.length>1">
                    <span v-if="item.children.length>1">{{ item.children.length }}</span>
                </div>
                <div class="label-content" v-else>
                    <img class="img" v-if="item.type=='audio'&&labelId==item.id" src="@/assets/images/icon/music-active.png" alt="">
                    <img class="img" v-if="item.type=='audio'&&labelId!=item.id" src="@/assets/images/icon/music.png" alt="">
                    <img class="img" v-if="item.type=='image'" :src="item.url" alt="">
                    <img class="img" v-if="item.type=='video'" :src="item.thumbnail" alt="">
                    <div class="text" v-if="item.type=='text'">{{item.content}}</div>
                </div>
                <div class="label-line" v-if="item.children.length>1"></div>
                <div class="label-line" v-if="item.children.length>1"></div>
                <!-- 父元素有overflow: hidden  组件需要挂载到body展示 -->
                <material-select :ref="'materialselect'+index" v-if="labelCurrent===index" @mouseMove="mouseMove" @listDel="listDel" @selectId="selectId"></material-select>
            </div>
        </div>
    </div>
</template>

<script>
import DraggableResizable from '../draggableResizable/index.vue'  //拖动
import MaterialSelect from './materialSelect'
import { mapState } from "vuex"
export default {
    props: {
        sWidth: Number,  //刻度尺每秒宽度 px
        scrollLeft: Number,  //滚动条滚动距离
    },
    components: {
        DraggableResizable,
        MaterialSelect,
    },
    data() {
        return {
            materialList: [],
            current: '',  //选择当前轨道
            labelCurrent: 10000,  //当前轨道标签
            top: 0,  //选择框y轴
            left: 0, //选择框x轴
            mouse: false, //鼠标是否移入
            drag: true, //是否可以拖动
        };
    },
    watch: {
        paused(val) {
            this.drag = val
        },
        sceneList(val) {
            this.getMaterialList()
        },
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,
            sceneIndex: state => state.videoData.sceneIndex,
            // 音频播放 暂停
            paused: state => state.videoData.paused,
            //显示当前轨道的id
            showId: state => state.videoData.showId,
        }),
        list() {
            if (this.sceneList.length) {
                return this.sceneList[this.sceneIndex].scenes_conf || []
            } else {
                return []
            }
        },
        // 重新整理标签列表数据格式
        labelList() {
            let arr = JSON.parse(JSON.stringify(this.materialList))
            // 把自身添加子级数组
            arr.forEach(ele => {
                ele.children = [{ ...ele }]
            })
            //根据数值大小进行排序
            arr.sort((a, b) => {
                return a.start - b.start;
            });
            // 在创建一个循环用
            let newArr = JSON.parse(JSON.stringify(arr))
            arr.forEach((ele, x) => {
                newArr.forEach((item, index) => {
                    // 根据当前循环arr的id获取newArr的下标  只走大于当前下标的  避免后续循环重复push
                    let a = newArr.findIndex(i => i.id == arr[x].id)
                    if (index >= a) {
                        // 如果两个或多个开始时间小于0.2秒  把后面的添加到前面的children中 然后删除后面的避免下次循环重复添加  
                        if (Math.abs(item.start - ele.start) <= 0.2 && item.id != ele.id) {
                            // 先添加
                            ele.children.push(item)
                            // 删除后index会变  需要根据id获取新下标
                            let b = arr.findIndex(i => i.id == newArr[index].id)
                            if (b != -1) {
                                // 然后删除添加项
                                arr.splice(b, 1)
                            }
                        }
                    }
                })
            })
            return arr
        },
        // 标签选中id
        labelId() {
            let a;
            this.labelList.forEach(ele => {
                ele.children.forEach(item => {
                    if (this.showId == item.id) {
                        a = ele.id
                    }
                })
            })
            return a
        },
    },
    created() {

    },
    mounted() {
        this.getMaterialList()
    },
    methods: {
        // 拖动停止
        onStop(val) {
            this.list.forEach(ele => {
                if (ele.id == val.id) {
                    ele.start = val.start * 1000
                    ele.end = val.end * 1000
                }
            })
        },
        // 筛选获取素材列表
        getMaterialList() {
            let arr = []
            let data = JSON.parse(JSON.stringify(this.list))
            data.map(ele => {
                if (ele.type == 'image' || ele.type == 'audio' || ele.type == 'video' || ele.type == 'text') {
                    ele.start = ele.start / 1000
                    ele.end = ele.end / 1000
                    ele.long = ele.end - ele.start
                    arr.push(ele)
                }
            })
            this.materialList = arr
        },
        // 删除
        listDel(id, type) {
            var arr = JSON.parse(JSON.stringify(this.sceneList))
            var idx = this.sceneIndex
            var scenes = arr[idx].scenes_conf || []

            // 批量删除
            if (type == 'batch') {
                scenes = scenes.filter(item => !id.includes(item.id))
            } else {
                // 单个删除
                let ind = scenes.findIndex(ele => ele.id == id)
                scenes.splice(ind, 1)
            }

            var params = {
                type: 'conf',
                info: scenes
            }
            this.mouse = false
            this.$store.dispatch('videoData/modifyProduct', params)
            this.$store.commit('videoData/SET_SHOW_ID', null)
        },

        // 展示轨道id
        selectId(item, type) {
            if (type == 'children') {
                this.$store.commit('videoData/SET_SHOW_ID', item.id)
                this.$store.commit('videoData/SET_CLICK_TIME', item.start)
            } else {
                if (item.children.length > 1) {
                    // this.$message.error('请选择需要调整的素材');
                } else {
                    this.$store.commit('videoData/SET_SHOW_ID', item.id)
                    this.$store.commit('videoData/SET_CLICK_TIME', item.start)
                }
            }
        },
        // 选中当前轨道
        activatedSelect(item, index) {
            this.current = index;
        },

        // 轨道拉长缩短
        onResize(x, y, w) {
            this.labelCurrent = 10000
            // x距离左侧坐标 w宽度
            this.materialList[this.current].start = x / this.sWidth
            this.materialList[this.current].end = (x + w) / this.sWidth
            this.materialList[this.current].long = w / this.sWidth
        },
        // 轨道左右拖动
        onDrag(x) {
            this.labelCurrent = 10000
            this.materialList[this.current].start = x / this.sWidth
            this.materialList[this.current].end = (x / this.sWidth) + this.materialList[this.current].long
        },
        // 鼠标是否移入标签
        mouseMove(val) {
            this.mouse = val
            if (!val) {
                this.mouseleave()
            }
        },
        // 鼠标移入标签  计算弹窗位置显示弹窗
        mouseenter(index, item) {
            this.mouse = true
            this.labelCurrent = index
            // 获取标签的相对 top  left传给弹窗定位
            let dom = document.getElementById('label' + index);
            let top = this.offsetTop(dom);
            let left = this.offsetLeft(dom) - this.scrollLeft;
            this.$nextTick(() => {
                eval("this.$refs.materialselect" + index)[0].openDialog(top, left, item)
            })
        },
        // 鼠标移出标签1秒消失
        mouseleave() {
            this.mouse = false
            setTimeout(() => {
                if (!this.mouse) {
                    this.labelCurrent = 10000
                }
            }, 500)
        },
        // label y轴
        offsetTop(elements) {
            var top = elements.offsetTop;
            var parent = elements.offsetParent;
            while (parent != null) {
                top += parent.offsetTop;
                parent = parent.offsetParent;
            }
            return top;
        },
        // label x轴
        offsetLeft(elements) {
            var left = elements.offsetLeft;
            var parent = elements.offsetParent;
            while (parent != null) {
                left += parent.offsetLeft;
                parent = parent.offsetParent;
            }
            return left;
        },
        // 清除选中
        clear() {
            this.current = ''
            this.labelCurrent = 10000
            this.mouse = false
            this.$store.commit('videoData/SET_SHOW_ID', null)
        },
    },

};
</script>

<style scoped lang="scss">
.material-track {
    width: 100%;
    height: 24px;
    background: #282828;
    margin-left: 16px;
    margin-top: 5px;
    position: relative;
    z-index: 0;
    .material-box {
        width: 100%;
        height: 24px;
        .material-item {
            background: #282828;
            border: 2px solid #2e4bf2;
            .time {
                height: 16px;
                background: #ff0000;
                border-radius: 2px;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
                line-height: 16px;
                position: absolute;
                padding: 0 5px;
            }
            .time.left {
                top: 3px;
                left: 10px;
            }
            .time.right {
                top: 3px;
                right: 10px;
            }
            .handle-r {
                position: absolute;
                top: -2px;
                right: -2px;
                cursor: e-resize;
                width: 10px;
                height: 24px;
                background: #2e4bf2;
            }
            .handle-l {
                position: absolute;
                top: -2px;
                left: -2px;
                cursor: w-resize;
                width: 10px;
                height: 24px;
                background: #2e4bf2;
            }
            .handle-l::after,
            .handle-r::after {
                content: "";
                position: absolute;
                width: 2px;
                height: 13px;
                background: #ffffff;
                border-radius: 1px;
                top: 6px;
                left: 4px;
            }
        }
    }
    .label-list {
        position: absolute;
        top: 30px;
        width: 24px;
        height: 22px;
        border-radius: 2px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 500;
        color: #2e4bf2;
        font-size: 12px;
        .label-content {
            flex: 1;
            padding: 3px;
            background: #ffffff;
            width: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            border-radius: 2px;
            .text {
                width: 100%;
                overflow: hidden;
                height: 18px;
                text-align: center;
            }
            .img {
                width: 100%;
                height: 100%;
            }
        }
        .label-line {
            width: 100%;
            height: 2px;
            background: #ffffff;
            margin-top: 1px;
            border-radius: 2px;
            pointer-events: none;
        }
        &::before {
            content: "";
            display: block;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid #ffffff;
            position: absolute;
            top: -4px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .label-list.active {
        .label-content {
            background: #2e4bf2;
            color: #ffffff;
        }
        .label-line {
            background: #2e4bf2;
        }
        &::before {
            border-bottom: 4px solid #2e4bf2;
        }
    }
}
::v-deep .vdr.active:before {
    outline: none;
}
</style>

<template>
    <div class="creat-video" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)" element-loading-text="语音识别中">
        <!-- 头部 -->
        <div class="c-a-t">
            <div class="c-a-t-l">
                <span>讲解内容</span>
                <div class="btn" v-if="info.ttsType==1" @click="back">返回编辑</div>
                <el-upload class="avatar-uploader" action="#" accept="audio/wav,audio/mp3,audio/m4a,audio/x-m4a" :show-file-list="false" :http-request="uploadSuccess">
                    <div class="btn" v-if="info.ttsType==2 || info.ttsType==3" @click="reUpload">重新上传</div>
                </el-upload>
            </div>
            <div class="c-a-t-r">
                <!-- <img class="close" src="@/assets/images/editor/icon6.png" alt=""> -->
                <!-- 剪辑 -->
                <!-- <div class="shear-box">
                    <div class="shear-icon" @click="shearAudio">
                        <img src="@/assets/images/editor/icon13.png" alt="">
                    </div>
                    <div class="shear-icon" @click="delAudio">
                        <img src="@/assets/images/editor/icon14.png" alt="">
                        <img src="@/assets/images/editor/icon15.png" alt="">
                    </div>
                    <div class="shear-icon">
                        <img src="@/assets/images/editor/icon16.png" alt="">
                        <img src="@/assets/images/editor/icon17.png" alt="">
                    </div>
                </div> -->
                <!-- <div class="line"></div> -->
                <!-- <div class="a-btn">
                    <img src="@/assets/images/editor/icon1.png" alt="">
                    语音
                </div> -->
                <voice-popup></voice-popup>
                <span class="pd">字幕</span>
                <el-switch v-model="subtitleShow" @change="subtitleChange" active-color="#2B4AFE" inactive-color="#DCDFE6">
                </el-switch>
                <div class="line"></div>
                <span>首帧</span>
                <el-tooltip effect="dark" placement="top">
                    <div slot="content">默认从模特首帧开始合成，开启随机后系统从模特随机帧开始合成视频作品</div>
                    <img class="img mg" src="@/assets/images/editor/icon2.png" alt="">
                </el-tooltip>
                <el-switch v-model="firstFrame" active-color="#2B4AFE" inactive-color="#DCDFE6">
                </el-switch>
                <div class="line"></div>
                <img class="icon" src="@/assets/images/editor/icon3.png" alt="" @click="reduce">
                <div class="slider">
                    <el-slider v-model="secWidth" :min="1" :max="16" :show-tooltip="false"></el-slider>
                </div>
                <img class="icon" src="@/assets/images/editor/icon4.png" alt="" @click="plus">
            </div>
        </div>

        <!-- 中间 -->
        <div class="c-a-main">
            <div class="c-a-main-l">
                <div class="play" @click="handlePauseOrPlay">
                    <img v-if="paused" src="@/assets/images/editor/icon7.png" alt="">
                    <img v-else src="@/assets/images/editor/icon8.png" alt="">
                </div>
                <div class="time">
                    {{duration}}
                </div>
                <div class="operate">
                    <!-- 剪辑 -->
                    <!-- <img src="@/assets/images/editor/icon9.png" alt=""> -->
                    <!-- 素材 -->
                    <img src="@/assets/images/editor/icon10.png" alt="" @click="openMaterial">
                    <!-- 滤镜 -->
                    <!-- <img src="@/assets/images/editor/icon11.png" alt=""> -->
                    <!-- 字幕 -->
                    <el-tooltip effect="dark" placement="top">
                        <div slot="content">解析音频并展示为字幕</div>
                        <img v-show="subtitleShow" src="@/assets/images/editor/icon12.png" alt="" @click="analysis">
                    </el-tooltip>
                </div>
                <audio @canplay="getDuration" controls @timeupdate="updateTime" @ended="ended" v-show="false" ref="audio" :src="audioUrl" />
            </div>
            <div class="c-a-main-r" @contextmenu="showContextMenu">
                <div class="c-a-main-r-c" id="toolbox" :style="{ width: audioDuration * (40+(secWidth*10)) + 'px' }" @mousedown="rulerClick($event)">
                    <time-line :secWidth="secWidth" :second="second"></time-line>
                    <video-track v-if="templateFrom==1 && videoShow" :sWidth="sWidth" :secWidth="secWidth" :x="x" :paused="paused" :currentTime="currentTime" :audioDuration="audioDuration" @clear="clear"></video-track>
                    <audio-track ref="audiotrack" :secWidth="secWidth" :audioDuration="audioDuration"></audio-track>
                    <subtitle-track v-show="subtitleShow" :sWidth="sWidth" :x="x" :audioDuration="audioDuration" :paused="paused" :currentTime="currentTime" @clear="clear"></subtitle-track>
                    <!-- 滤镜轨道  暂时隐藏 -->
                    <!-- <filter-track v-show="captionsShow" :sWidth="sWidth" :audioDuration="audioDuration" :paused="paused" :currentTime="currentTime" @clear="clear"></filter-track> -->
                    <material-track ref="materialtrack" v-if="show" :sWidth="sWidth" :scrollLeft="scrollLeft"></material-track>
                    <!-- 滚动的线 -->
                    <draggable-resizable v-if="show" :x="x" :y="0" :w="1" :h="180" axis="x" :z="9" :parent="true" @dragging="lineOnDrag" :handles="[]" class="move_line_content">
                        <div class="iconfont icon-arrow-b_fill"></div>
                        <div class="move_box"></div>
                        <div class="move_line"></div>
                    </draggable-resizable>
                </div>
            </div>
        </div>

        <!-- 底部 -->
        <div class="c-a-b">
            <span class="pdl30">页面停留</span>
            <div class="input"><el-input class="audio" size="mini" v-model="time" placeholder=""></el-input></div>
            <span>秒</span>
            <el-tooltip effect="dark" placement="top">
                <div slot="content">表示数字人讲解结束后，当前页面停留的时长；<br />若讲解内容为空，当前页的时长以停留时长为准；</div>
                <img src="@/assets/images/editor/icon2.png" alt="">
            </el-tooltip>
        </div>

        <!-- 提示框 -->
        <point-tip ref='pointTip' @confirm='confirmPoin' :text='tip_text' :cancelShow='true' btnText='确 定'></point-tip>
    </div>
</template>

<script>
import { mapState } from "vuex"
import DraggableResizable from '../../components/draggableResizable/index'  //拖动
import TimeLine from '../../components/timeLine/index'  //时间轴
import AudioTrack from '../../components/audioTrack/index' //音频轨道
import VideoTrack from '../../components/videoTrack/index'  //视频轨道
import SubtitleTrack from '../../components/subtitleTrack/index'  //字幕轨道
import FilterTrack from '../../components/filterTrack/index'  //滤镜轨道
import MaterialTrack from '../../components/materialTrack/index'  //素材轨道
import PointTip from '@/components/point_out/point_tip.vue'//提示弹框
import VoicePopup from '../../components/voicePopup/index.vue'
export default {
    components: {
        DraggableResizable,
        TimeLine,
        AudioTrack,
        VideoTrack,
        SubtitleTrack,
        FilterTrack,
        MaterialTrack,
        PointTip,
        VoicePopup
    },
    props: {
        type: {
            type: String,
            default: '',
        },
        url: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            loading: false,
            // audioUrl: 'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/file/20231006/ZNkDjcZrbfsG-yVqAi.mp3',
            audioUrl: '',
            firstFrame: false, //首帧
            secWidth: 4,  //缩放
            scrollLeft: 0, //滚动条滚动距离
            second: 0, //取整时间  计算刻度尺宽度
            setleft: 0,//距离屏幕左侧距离
            time: 0,//页面停留时间

            x: 0, //进度条x轴
            duration: "00:00",//音频时间
            audio: "",//音频
            paused: true,   //是否暂停
            sWidth: 80,  //每秒宽度 px
            audioDuration: 0,//音频总时长秒
            currentTime: 0,
            tip_text: '解析音频后将替换当前字幕',

            show: false,//是否显示刻度尺指针
        };
    },
    created() {

    },
    computed: {
        ...mapState({
            sceneShow: state => state.videoCreate.sceneShow,
            clickTime: state => state.videoData.clickTime,//当前播放时间
            sceneList: state => state.videoData.sceneList,
            sceneIndex: state => state.videoData.sceneIndex,
            sizeRatio: state => state.videoCreate.sizeRatio, // 宽高比例

            isFull: state => state.videoCreate.isFull, //是否全屏
            templateFrom: state => state.videoCreate.template_from,//1视频 2名片
        }),
        info() {
            return this.sceneList[this.sceneIndex].data
        },
        // 是否展示字幕
        subtitleShow: {
            get() {
                return this.sceneList[this.sceneIndex].data.subtitleShow
                // return this.$store.state.videoData.subtitleShow
            },
            set(val) {
                this.$store.commit('videoData/SET_SUBTITLE_SHOW', val)
            }
        },
        videoShow() {
            let arr = this.sceneList[this.sceneIndex].scenes_conf || []
            let a = arr.find(item => item.type == 'dthuman')
            if (a) {
                return true
            } else {
                return false
            }
        },
    },
    mounted() {
        this.audioUrl = this.info.ttsAudio
        // 监听滚动事件
        this.getScrollLeft()
        this.getClickLeft()
    },
    watch: {

        info(val) {
            this.audioUrl = val.ttsAudio
        },
        // 点击素材标签 修改时间 同步当前播放时间 刻度
        clickTime(val) {
            this.currentTime = val
            this.audio.currentTime = val
            this.x = val * this.sWidth
        },
        // 开启关闭全屏 修改setleft
        isFull() {
            this.getClickLeft()
        },
        // 左边窗口打开关闭   修改setleft
        sceneShow() {
            this.getClickLeft()
        },
        // 宽度改变时候 重新计算滚动距离
        secWidth(val) {
            var dom = document.querySelector(".c-a-main-r");
            this.scrollLeft = dom.scrollLeft
            this.sWidth = 40 + (val * 10)

            // 新的每格秒钟的 宽度
            let wid = 40 + (val * 10)
            // 根据时间重新计算宽度赋值坐标
            this.x = wid * this.audio.currentTime
        },
    },
    methods: {
        // 是否显示字幕
        subtitleChange(val) {
            this.$store.commit('videoData/SET_SUBTITLE_SHOW', val)
            if (val) {
                this.confirmPoin()
            }
        },
        // 获取鼠标点击位置距离屏幕左侧距离
        getClickLeft() {
            setTimeout(() => {
                // 获取dom距离屏幕左侧的距离
                var dom = document.getElementById('toolbox');
                // 点击刻度尺需要减去父元素左侧的距离
                this.setleft = this.offsetLeft(dom) + 16; //toolbox有个padding 16 
            }, 300)
        },
        // 获取元素距离屏幕左边的距离 x轴
        offsetLeft(elements) {
            var left = elements.offsetLeft;
            var parent = elements.offsetParent;
            while (parent != null) {
                left += parent.offsetLeft;
                parent = parent.offsetParent;
            }
            return left;
        },
        shearAudio() {
            this.$refs.audiotrack.shearAudio(this.currentTime)
        },
        delAudio() {
            this.$refs.audiotrack.delAudio()
        },

        // 当前播放时间进度
        updateTime(e) {
            this.x = e.target.currentTime * this.sWidth
            this.currentTime = e.target.currentTime
            this.$store.commit('videoData/SET_CURRENT_TIME', this.currentTime)
        },
        // 结束播放
        ended() {
            this.currentTime = 0;
            this.$store.commit('videoData/SET_CURRENT_TIME', this.currentTime)
            this.paused = true;
            this.$store.commit('videoData/SET_PAUSED', this.paused)
            this.x = 0;
        },
        //视频在可以播放时触发
        getDuration() {
            this.audioDuration = this.$refs.audio.duration
            // 音频发生改变 时候关闭字幕
            // this.$store.commit('videoData/SET_SUBTITLE_SHOW', false)
            // 修改时长
            this.$store.commit('videoData/SET_DURATION', this.audioDuration)
            this.duration = this.timeFormat(this.$refs.audio.duration);
            this.audio = this.$refs.audio;
            this.audio.volume = 0.2
            // 延迟指针出现
            this.$nextTick(() => {
                this.show = true
            })
        },
        //将单位为秒的的时间转换成mm:ss的形式
        timeFormat(number) {
            this.second = Math.ceil(number);
            let minute = parseInt(number / 60);
            let second = parseInt(number % 60);
            minute = minute >= 10 ? minute : "0" + minute;
            second = second >= 10 ? second : "0" + second;
            return minute + ":" + second;
        },
        //暂停或播放
        handlePauseOrPlay() {
            this.audio.paused ? this.audio.play() : this.audio.pause();
            this.paused = !this.paused;

            this.$store.commit('videoData/SET_PAUSED', this.paused)
        },









        // 禁止鼠标右键
        showContextMenu(event) {
            event.preventDefault();
        },

        // 返回语音页面
        back() {
            let obj = {
                current: 1,
            }
            this.$emit('confirm', obj)
        },
        reUpload() {

        },

        // 监听滚动条滚动距离
        getScrollLeft() {
            var that = this
            var dom = document.querySelector(".c-a-main-r");
            dom.addEventListener("scroll", function () {
                that.scrollLeft = dom.scrollLeft
            });
        },

        // 刻度放大缩小
        reduce() {
            this.secWidth--
        },
        plus() {
            this.secWidth++
        },
        // 标线拖拽事件
        lineOnDrag(x) {
            this.x = x;
            // 当前时间
            this.audio.currentTime = this.getTime(x)
            this.clear()
        },

        // 刻度尺点击事件
        rulerClick(e) {
            this.x = e.x - this.setleft + this.scrollLeft;
            // 当前时间
            let time = this.getTime(this.x)
            this.audio.currentTime = time
            // 点击刻度尺更改时间  调用此方法 改变素材音频的时间
            this.$store.commit('videoData/SET_CLICK_TIME', time)
            this.clear()
        },

        // 计算当前时间
        getTime(x) {
            // 每格秒钟的 宽度
            let wid = 40 + (this.secWidth * 10)
            // 当前时间/秒
            let sec = x / wid
            return sec
        },
        // 清空素材轨道选中状态
        clear() {
            this.$refs.materialtrack.clear()
            // this.$refs.filtertrack.clear()
        },
        // 打开素材
        openMaterial() {
            let item = {
                label: '我的素材',
                value: 'material',
                navFlag: true,
                icon: require('@/assets/images/icon/video_nav_1.png'),
            }
            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', item)
        },
        // 解析音频
        analysis() {
            this.$refs.pointTip.openDialog()
        },
        // 确定解析
        confirmPoin() {
            let obj = {
                url: this.audioUrl
            }
            this.loading = true
            this.$audioApi.voiceToSubtitle(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    let obj = res.result.subtitleList
                    this.$store.commit('videoData/SET_SUBTITLE_LIST', obj)
                    this.addSubtitle()
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 插入字幕
        addSubtitle() {
            var that = this
            // 默认
            let str = {
                type: 'subtitle',
                name: '字幕',
                url: '',
                style: {
                    left: 480,
                    top: 888,
                    width: 960,
                    height: 120,
                },
                textStyle: {
                    color: "#000000",
                    fontSize: 60,
                    fontStyle: "normal",
                    textAlign: "center",
                    fontFamily: "SourceHanSerifCN-Regular",
                    fontWeight: "normal",
                    lineHeight: 1,
                    letterSpacing: 1,
                    strokeColor: "",
                    strokeWidth: 0,
                    content: '这里是字幕',
                }
            }
            // 默认16:9  9比16时候位置更换
            if (this.sizeRatio == 2) {
                str.style.left = 67
                str.style.top = 1644
            }
            var arr = JSON.parse(JSON.stringify(that.sceneList))
            var scenes = arr[that.sceneIndex].scenes_conf || []
            let i = scenes.findIndex(ele => ele.type == 'subtitle')

            if (i == -1) {
                str.id = new Date().getTime();
                // 没有时候添加
                scenes.push(str)
                var params = {
                    type: 'conf',
                    info: scenes
                }
                that.$store.dispatch('videoData/modifyProduct', params)
            }
        },
        // 重新上传
        uploadSuccess(item) {
            var that = this
            let acceptList = ['audio/wav', 'audio/mp3', 'audio/m4a', 'audio/x-m4a', 'audio/mpeg']
            // 限制图片格式
            const isType = acceptList.includes(item.file.type)
            // 限制图片大小
            const isLt50M = item.file.size / 1024 / 1024 <= 50;
            if (!isType) {
                that.$errMsg('上传音频只能是 MP3 , m4a, mav 格式!');
            }
            if (!isLt50M) {
                that.$errMsg('上传音频大小不能超过 50MB!');
            }
            if (isType && isLt50M) {
                let newFile = new FormData()
                newFile.append('type', 5);
                newFile.append('file', item.file);
                that.loading = true
                that.$publicApi.uploadImg(newFile).then(res => {
                    that.loading = false
                    if (res.code == 1000) {
                        let obj = {
                            ttsAudio: res.result.url,
                            name: item.file.name,
                        }
                        this.$store.commit('videoData/SET_DATA_INFO', obj)
                        // 清空字幕数据
                        this.$store.commit('videoData/SET_SUBTITLE_LIST', [])
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
    },

};
</script>

<style scoped lang="scss">
.creat-video {
    width: calc(100% - 60px);
    background: #1b1d1f;
    padding: 0 20px;
    .c-a-t {
        width: 100%;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .c-a-t-l {
            display: flex;
            align-items: center;
            span {
                font-size: 15px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                padding-right: 20px;
            }
            .btn {
                width: 64px;
                height: 26px;
                background: rgba(48, 77, 242, 0.5);
                border-radius: 4px;
                border: 1px solid #2e4bf2;
                font-size: 12px;
                color: #ffffff;
                text-align: center;
                line-height: 24px;
                cursor: pointer;
            }
        }
        .c-a-t-r {
            display: flex;
            align-items: center;
            .close {
                width: 26px;
                height: 26px;
            }
            .shear-box {
                display: flex;
                align-items: center;
                .shear-icon {
                    width: 18px;
                    height: 18px;
                    margin-left: 20px;
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .a-btn {
                display: inline-block;
                padding: 0 10px;
                height: 26px;
                background: rgba(48, 77, 242, 0.5);
                border-radius: 4px;
                border: 1px solid #2e4bf2;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: #ffffff;
                cursor: pointer;
                img {
                    margin-right: 4px;
                    width: 15px;
                    height: 14px;
                }
            }
            span {
                font-size: 14px;
                color: #ffffff;
            }
            .line {
                width: 1px;
                height: 29px;
                background: #333333;
                margin: 0 20px;
            }
            .img {
                width: 12px;
                height: 12px;
            }
            .icon {
                width: 16px;
                height: 16px;
                cursor: pointer;
            }
            .slider {
                padding: 0 10px;
                width: 128px;
            }
        }
    }
    .c-a-main {
        width: 100%;
        min-height: 188px;
        background: #0d1011;
        border-radius: 6px;
        display: flex;
        .c-a-main-l {
            width: 100px;
            border-right: 1px solid #252727;
            padding-top: 20px;
            .play {
                width: 18px;
                height: 20px;
                margin: 0 auto;
                cursor: pointer;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
            .time {
                width: 60px;
                height: 32px;
                background: #232930;
                border-radius: 4px;
                margin: 18px auto 0;
                text-align: center;
                line-height: 32px;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
            }
            .operate {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 80px;
                margin: 12px auto 0;
                img {
                    width: 20px;
                    height: 20px;
                    margin: 10px;
                    cursor: pointer;
                }
            }
        }
        .c-a-main-r {
            width: calc(100% - 100px);
            min-height: 188px;
            overflow-x: auto;
            overflow-y: hidden;
            .c-a-main-r-c {
                position: relative;
                height: 100%;
            }
        }
    }

    // 滚动的线
    .move_line_content.vdr {
        border: none !important;
    }
    .move_line_content {
        cursor: -webkit-grab;
        cursor: grab;
        z-index: 999;
        position: absolute;
        top: 0px;
        left: 16px;
        // transition: linear 0.2s;

        .move_box {
            width: 5px;
            height: 8px;
            background: #ffffff;
            border-radius: 1px;
            position: absolute;
            left: -2px;
            top: 0;
        }
        .move_line {
            width: 1px;
            background: #fff;
            // height: 280px;
            height: 100%;
        }
    }

    .c-a-main-r::-webkit-scrollbar {
        /*滚动条整体样式*/
        height: 6px;
    }
    .c-a-main-r::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
        background: #636363;
    }

    .c-a-b {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        span {
            color: #ffffff;
            font-size: 14px;
        }
        img {
            width: 12px;
            height: 12px;
            margin-left: 4px;
            margin-right: 12px;
        }
        .input {
            margin: 0 6px;
            width: 36px;
        }
    }
}
.pd {
    padding: 0 12px 0 20px;
}
.mg {
    margin: 0 12px 0 4px;
}
::v-deep .el-slider__button {
    background: url(~@/assets/images/editor/icon5.png) no-repeat 100% / cover;
    border: none;
    width: 8px;
    height: 14px;
    border-radius: 0;
}

::v-deep .el-slider__button-wrapper {
    z-index: 10;
}

::v-deep .audio .el-input__inner {
    padding: 0 5px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #919191;
    background: #1b1d1f;
    font-size: 14px;
    color: #ffffff;
}
.vdr.active:before {
    outline: none;
}
</style>

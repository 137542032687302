<template>
    <div class="">
        <div class="video_table" :class="!navInfo.navFlag ? 'video_table_close' : ''">
            <!-- 标题 -->
            <div class="table_title">
                <i class="el-icon-close" @click="close"></i>
                <div class="title">图片</div>
            </div>
            <div class="table_content">
                <!-- 下方的筛选项目连带搜索 -->
                <div class="search-box">
                    <div class="search_inp">
                        <el-input placeholder="请输入图片名称" prefix-icon="el-icon-search" size="small" v-model="name" clearable @clear="expandClose" @keyup.enter.native="reset">
                        </el-input>
                    </div>
                </div>
                <!-- 列表 -->
                <div class="piclist">
                    <!-- 列表-更多 -->
                    <div class="contentbox scroll_bar" v-if="category" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="">
                            <div class="list-title">
                                <div class="title">{{ categoryInfo.name }}</div>
                                <div class="icon" @click="expandClose()">
                                    <i class="el-icon-arrow-left"></i>
                                </div>
                            </div>
                            <el-checkbox-group v-model="checkList">
                                <div class="item openHover" v-for="(item, x) in searchlist" :key="x">
                                    <div class="item-pic">
                                        <div class="picbox">
                                            <el-image style="width: 96px; height: 70px" :src='item.source_url' fit="contain">
                                                <div slot="error" class="image-slot">
                                                    <img src="@/assets/images/icon/img.png" alt="">
                                                </div>
                                            </el-image>
                                        </div>
                                        <!-- 左上角的眼睛 -->
                                        <div class="preview" @click="look(item)">
                                            <i class="el-icon-view color"></i>
                                        </div>
                                        <!-- 下方的插入按钮 -->
                                        <div class="insert">
                                            <div class="button" @click="selectMaterials(item)">插入</div>
                                        </div>
                                        <!-- 左边的选中框 -->
                                        <!-- <div class="selectCheck">
                      <el-checkbox :label="item.id"><br /></el-checkbox>
                    </div> -->
                                    </div>
                                </div>
                            </el-checkbox-group>
                            <!-- 没有更多 -->
                            <Loading :loading="loading" :list="searchlist" :page="page" :totalPage="last_page">
                            </Loading>
                        </div>
                    </div>
                    <!-- 列表-带搜索 -->
                    <div class="contentbox scroll_bar" v-else-if="name" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="">
                            <el-checkbox-group v-model="checkList">
                                <div class="item openHover" v-for="(item, x) in searchlist" :key="x">
                                    <div class="item-pic">
                                        <div class="picbox">
                                            <el-image style="width: 96px; height: 70px" :src='item.source_url' fit="contain">
                                                <div slot="error" class="image-slot">
                                                    <img src="@/assets/images/icon/img.png" alt="">
                                                </div>
                                            </el-image>
                                        </div>
                                        <!-- 左上角的眼睛 -->
                                        <div class="preview" @click="look(item)">
                                            <i class="el-icon-view color"></i>
                                        </div>
                                        <!-- 下方的插入按钮 -->
                                        <div class="insert">
                                            <div class="button" @click="selectMaterials(item)">插入</div>
                                        </div>
                                        <!-- 左边的选中框 -->
                                        <!-- <div class="selectCheck">
                      <el-checkbox :label="item.id"><br /></el-checkbox>
                    </div> -->
                                    </div>
                                </div>
                            </el-checkbox-group>
                            <!-- 没有更多 -->
                            <Loading :loading="loading" :list="searchlist" :page="page" :totalPage="last_page">
                            </Loading>
                        </div>
                    </div>
                    <!-- 列表-带分类 -->
                    <div class="contentbox scroll_bar" v-else v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div class="" v-for="(ele, i) in list" :key="i">

                            <div class="list-title">
                                <div class="title">{{ ele.name }}</div>
                                <div class="icon" @click="moreBtn(ele)">
                                    <span>更多<i class="el-icon-arrow-right"></i></span>
                                    <!-- <span v-if="!ele.expand">更多<i class="el-icon-arrow-right"></i></span>
                  <i class="el-icon-arrow-left" v-else></i> -->
                                </div>
                            </div>
                            <el-checkbox-group v-model="checkList">
                                <div class="item openHover" v-for="(item, x) in ele.materialImage" :key="x">
                                    <div class="item-pic">
                                        <div class="picbox">
                                            <el-image style="width: 96px; height: 70px" :src='item.source_url' fit="contain">
                                                <div slot="error" class="image-slot">
                                                    <img src="@/assets/images/icon/img.png" alt="">
                                                </div>
                                            </el-image>
                                        </div>
                                        <!-- 左上角的眼睛 -->
                                        <div class="preview" @click="look(item)">
                                            <i class="el-icon-view color"></i>
                                        </div>
                                        <!-- 下方的插入按钮 -->
                                        <div class="insert">
                                            <div class="button" @click="selectMaterials(item)">插入</div>
                                        </div>
                                        <!-- 左边的选中框 -->
                                        <!-- <div class="selectCheck">
                      <el-checkbox :label="item.id"><br /></el-checkbox>
                    </div> -->
                                    </div>
                                </div>
                            </el-checkbox-group>
                        </div>
                        <!-- 没有更多 -->
                        <Loading :loading="loading" :list="list">
                        </Loading>
                    </div>
                </div>
            </div>
        </div>
        <!-- 预览图片 -->
        <ImgPreview ref="ImgPreview"></ImgPreview>
    </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    components: {
        ImgPreview: resolve => require(['@/components/img_preview/index'], resolve),
        Loading: (resolve) => require(['@/components/loading/index'], resolve)
    },
    props: {
        navInfo: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            name: '',
            category: '',
            loading: false,
            page: 0,
            last_page: 1, // 总页数
            limit: 20,
            list: [],
            checkList: [],
            categoryInfo: {},
            searchlist: [],
        }
    },
    created() {
        this.getList()

    },
    computed: {
        ...mapState({
            currentTime: state => state.videoData.currentTime,//当前播放时间
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            duration: state => state.videoData.duration, //音频总时长
            sizeRatio: state => state.videoCreate.sizeRatio,//宽高比2=9:16 1=16:9
        }),
        searchForm() {
            return {
                page: this.page,
                limit: this.limit,
                category_id: this.category,
                name: this.name
            }
        },
    },
    methods: {
        // 关闭
        close() {
            let obj = {
                ...this.navInfo,
                navFlag: false,
            }
            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', obj)
        },
        //
        inputBtn() {
        },
        // 加载更多
        load() {
            if (this.loading || this.page === this.last_page || this.last_page === 0) return
            this.page++
            this.getSearchList()
        },
        // 重置
        reset() {
            console.log(this.name)
            this.category = ''
            this.searchlist = []
            this.page = 1
            this.getSearchList()
        },
        // 列表-带分类
        getList() {
            this.loading = true
            this.$workbenApi.image_cate().then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 列表-带搜索
        getSearchList() {
            this.loading = true
            this.$workbenApi.image_list({
                ...this.searchForm
            }).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.searchlist = [...this.searchlist, ...res?.result?.data] || []
                    this.last_page = res?.result?.last_page
                    this.page = res?.result?.current_page ?? 1
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 更多
        moreBtn(ele) {
            this.categoryInfo = ele
            this.category = ele.id
            this.getSearchList()
        },
        // 收起
        expandClose() {
            this.category = ''
            this.searchlist = []
        },
        // 插入素材
        selectMaterials(info) {
            var that = this
            var arr = JSON.parse(JSON.stringify(that.sceneList))
            var idx = that.sceneIndex
            var scenes = arr[idx].scenes_conf || []
            var obj = {
                width: 0,
                height: 0,
                left: 0,
                top: 0,
                rotation: 0
            }
            let imgObj = new Image()
            imgObj.src = info.image
            imgObj.onload = function () {
                if (that.sizeRatio == 1) {//16:9
                    if (imgObj.width < 1920 && imgObj.height < 1080) {
                        obj.width = imgObj.width
                        obj.height = imgObj.height
                        obj.left = Math.floor((1920 - imgObj.width) / 2)
                        obj.top = Math.floor((1080 - imgObj.height) / 2)
                    } else if (imgObj.width > 1920 && imgObj.height < 1080) {
                        var ratio = (1920 / imgObj.width).toFixed(5)
                        obj.width = 1920
                        obj.height = Math.floor(imgObj.height * ratio)
                        obj.left = 0
                        obj.top = Math.floor((1080 - obj.height) / 2)
                    } else if (imgObj.width < 1920 && imgObj.height > 1080) {
                        var ratio = (1080 / imgObj.height).toFixed(5)
                        obj.width = Math.floor(imgObj.width * ratio)
                        obj.height = 1080
                        obj.left = Math.floor((1920 - obj.width) / 2)
                        obj.top = 0
                    } else {
                        var ratio = ''
                        var ratio1 = (1920 / imgObj.width).toFixed(5)
                        var ratio2 = (1080 / imgObj.height).toFixed(5)
                        if ((ratio1 * 100) > (ratio2 * 100)) {
                            ratio = ratio2
                        } else {
                            ratio = ratio1
                        }
                        obj.width = Math.floor(imgObj.width * ratio)
                        obj.height = Math.floor(imgObj.height * ratio)
                        obj.left = Math.floor((1920 - obj.width) / 2)
                        obj.top = Math.floor((1080 - obj.height) / 2)
                    }
                }
                if (that.sizeRatio == 2) {//9:16
                    if (imgObj.width < 1080 && imgObj.height < 1920) {
                        obj.width = imgObj.width
                        obj.height = imgObj.height
                        obj.left = Math.floor((1080 - imgObj.width) / 2)
                        obj.top = Math.floor((1920 - imgObj.height) / 2)
                    } else if (imgObj.width > 1080 && imgObj.height < 1920) {
                        var ratio = (1080 / imgObj.width).toFixed(5)
                        obj.width = 1080
                        obj.height = Math.floor(imgObj.height * ratio)
                        obj.left = 0
                        obj.top = Math.floor((1920 - obj.height) / 2)
                    } else if (imgObj.width < 1080 && imgObj.height > 1920) {
                        var ratio = (1920 / imgObj.height).toFixed(5)
                        obj.width = Math.floor(imgObj.width * ratio)
                        obj.height = 1920
                        obj.left = Math.floor((1080 - obj.width) / 2)
                        obj.top = 0
                    } else {
                        var ratio = ''
                        var ratio1 = (1080 / imgObj.width).toFixed(5)
                        var ratio2 = (1920 / imgObj.height).toFixed(5)
                        if ((ratio1 * 100) > (ratio2 * 100)) {
                            ratio = ratio2
                        } else {
                            ratio = ratio1
                        }
                        obj.width = Math.floor(imgObj.width * ratio)
                        obj.height = Math.floor(imgObj.height * ratio)
                        obj.left = Math.floor((1080 - obj.width) / 2)
                        obj.top = Math.floor((1920 - obj.height) / 2)
                    }
                }
                // if (that.duration == 0) {
                //     return
                // }
                var str = {
                    id: new Date().getTime(),
                    type: 'image',
                    name: info.name,
                    url: info.image,
                    start: that.currentTime * 1000,
                    end: that.duration * 1000,
                    lock: false,
                    style: obj
                }
                scenes.push(str)
                var params = {
                    type: 'conf',
                    info: scenes
                }
                that.$store.dispatch('videoData/modifyProduct', params)
                // 插入后选中
                that.$store.commit('videoData/SET_SHOW_ID', str.id)
            }
        },
        // 预览
        look(item) {
            // 图片
            this.$refs.ImgPreview.openDialog(item.source_url)
        },

    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";

.search-box {
    padding-top: 0;
    padding-bottom: 15px;
}

.contentbox {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 320px);
    margin-right: -8px;
}
</style>
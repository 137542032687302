<!-- @Author: Yu_Bo 2023-10-12 14:49:41-->
<template>
    <div class="video_scene">
        <div class="scene_title">
            <img src="@/assets/images/createvideo/scene_icon.png" alt="">
            <span>场景</span>
        </div>
        <div class="scene_content scroll_bar" :class="sceneShow?'':'scene_content_scroll'">
            <div class="scene_main">
                <div class="scene_upload" :style="[listStyle]">
                    <template v-if="coverImg">
                        <el-image class="cover_img" :src="coverImg" fit="contain"></el-image>
                        <div class="cover_btn" v-if="sceneShow">
                            <div class="img_del"><i class="el-icon-delete"></i></div>
                            <div class="cover_txt">
                                设置封面
                                <el-tooltip class="item" effect="dark" content="请上传png\jpg\jpeg格式文件，文件大小不超过50M" placement="top">
                                    <img class="txt_icon" src="@/assets/images/createvideo/scene_tip.png" alt="">
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="upload_img" :class="sceneShow?'':'upload_img_top'">
                            <img class="upload_icon" src="@/assets/images/createvideo/scene_add.png" alt="">
                        </div>
                        <div class="upload_txt" v-if="sceneShow">
                            上传封面
                            <el-tooltip class="item" effect="dark" content="请上传png\jpg\jpeg格式文件，文件大小不超过50M" placement="top">
                                <img class="txt_icon" src="@/assets/images/createvideo/scene_tip.png" alt="">
                            </el-tooltip>
                        </div>
                    </template>
                </div>
            </div>
            <!--  -->
            <div class="scene_info" :class="sceneShow?'':'scene_info_top'" v-for="(item,index) in sceneList" :key="index">
                <div class="info_title" v-if="sceneShow">场景{{ index+1 }}</div>
                <div class="info_con">
                    <div class="con_data" :class="index==sceneIndex?'con_data_active':''" :style="[listStyle]" @click="changeIndex(index)">
                        <scene-info :key="index" :scenesInfo="item"></scene-info>
                        <template v-if="sceneShow">
                            <!-- 选择 -->
                            <div class="data_check" v-if="operateShow" @click.stop="check(item)">
                                <img v-if="ids.find(ite => ite == item.id)" class="icon" src="@/assets/images/creation/creationMng_(72).png" alt="">
                            </div>
                            <!-- 删除/复制 -->
                            <el-tooltip popper-class="tooltip_btn" effect="dark" placement="right">
                                <div slot="content">
                                    <div class="t_btn" @click="delBtn(index)">删除</div>
                                    <div class="t_btn" @click="sceneCopy(index)">复制</div>
                                </div>
                                <div class="data_btn" v-show="!operateShow">
                                    <i class="el-icon-more"></i>
                                </div>
                            </el-tooltip>
                        </template>
                    </div>
                </div>
                <div class="info_btn" v-if="sceneShow">
                    <div class="cut_to" v-if="index!=(sceneList.length-1)" @click="tranBtn(index)">
                        <img src="@/assets/images/createvideo/cut_to.png" alt="">
                        <span>{{ item.transition?item.transition.name:'添加转场' }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="scene_bottom" v-if="sceneShow">
            <div class="left">
                <div class="left_input" v-if="operateShow">
                    <el-checkbox v-model="allChecked" @change="checkAll"></el-checkbox>全选
                    <el-button class="btnBgColor_blue" size="small" @click="delAll">删除</el-button>
                </div>
                <div class="left_btn" v-else>
                    <el-button class="btnBgColor_blue" size="small" icon="el-icon-circle-plus-outline" @click="addSceneBtn">添 加</el-button>
                </div>
            </div>
            <div class="right" @click="operate">
                <img src="@/assets/images/createvideo/bot_set.png" alt="">
            </div>
        </div>
        <!-- 转场动画 -->
        <div class="tran_box" v-if="tranShow" @click="tranBtn"></div>
        <div class="transition_animation" v-if="tranShow">
            <div class="title">转场动画</div>
            <div class="name">推荐</div>
            <div class="list">
                <div class="list_info" v-for="(item,index) in tranList" :key="index" @click="addTransition(item)">
                    <img :src="item.img" alt="">
                    <span>{{ item.name }}</span>
                </div>
            </div>
        </div>
        <!-- 删除 -->
        <point-out ref="point" @close='confirmBtn' dialogTitle="提示" dialogWidth='380px' type="works" iconColor="#FF9665" borderRadius='10px' :iconShow="true" :affirmShow="true" affirmText='确 定'>
            <div class="zc_dialog_tip_content">
                <div class="text">删除后不可恢复，请确认删除？</div>
            </div>
        </point-out>
    </div>
</template>

<script>
import { mapState } from "vuex"
import SceneInfo from './scene_info'
import PointOut from '@/components/point_out/point_out.vue'
export default {
    components: { SceneInfo, PointOut },
    props: {},
    data() {
        return {
            // 封面
            coverImg: '',
            // 全选
            allChecked: false,
            operateShow: false,
            // 转场动画
            tranShow: false,
            tranList: [
                {
                    name: "无效果",
                    img: require('@/assets/images/createvideo/tran_no.png'),
                    type: "none",
                    duration: 1500
                },
                {
                    name: "向左擦除",
                    img: require('@/assets/images/createvideo/tran_left.png'),
                    type: "wipeLeft",
                    duration: 1500
                },
                {
                    name: "向右擦除",
                    img: require('@/assets/images/createvideo/tran_right.png'),
                    type: "wipeRight",
                    duration: 1500
                },
                {
                    name: "从下移入",
                    img: require('@/assets/images/createvideo/tran_top.png'),
                    type: "wipeDown",
                    duration: 1500
                },
                {
                    name: "从上移入",
                    img: require('@/assets/images/createvideo/tran_bot.png'),
                    type: "wipeUp",
                    duration: 1500
                },
            ],
            delIndex: null,
            ids: [],
            transitionIndex: null,
        };
    },

    computed: {
        ...mapState({
            sceneShow: state => state.videoCreate.sceneShow,
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
        }),
        listStyle() {
            if (this.sceneShow) {
                return {
                    height: '90px'
                }
            } else {
                return {
                    height: '35px'
                }
            }
        },
    },

    watch: {
    },

    created() { },

    mounted() {

    },

    methods: {
        // 全选
        checkAll(val) {
            let arr = []
            this.sceneList.forEach(ele => {
                if (val) {
                    arr.push(ele.id)
                } else {
                    arr = []
                }
            })
            this.ids = arr
        },
        // 单选
        check(val) {
            let arr = JSON.parse(JSON.stringify(this.ids))
            let idx = arr.findIndex(item => item == val.id)
            if (idx == -1) {
                this.ids.push(val.id)
            } else {
                this.ids.splice(idx, 1)
            }
            this.changeCheckAll()
        },
        changeCheckAll() {
            if (this.sceneList.length == this.ids.length) {
                this.allChecked = true
            } else {
                this.allChecked = false
            }
        },
        // 转场动画
        tranBtn(val) {
            this.tranShow = !this.tranShow
            this.transitionIndex = val
        },
        // 添加专场
        addTransition(val) {
            let arr = JSON.parse(JSON.stringify(this.sceneList))
            if (val.type == 'none') {
                arr[this.transitionIndex].transition = null
            } else {
                arr[this.transitionIndex].transition = {
                    type: val.type,
                    duration: val.duration,
                    name: val.name,
                }
            }
            var params = {
                type: 'scen',
                info: arr
            }
            this.$store.dispatch('videoData/modifyProduct', params)
            this.tranShow = false
        },
        // 底部
        operate() {
            this.operateShow = !this.operateShow
            this.ids = []
        },
        // 批量删除
        delAll() {
            let list = JSON.parse(JSON.stringify(this.sceneList))
            let arr = JSON.parse(JSON.stringify(this.ids))
            let scenes = list.filter(item => !arr.includes(item.id))
            var params = {
                type: 'scen',
                info: scenes
            }
            this.$store.dispatch('videoData/modifyProduct', params)
            this.ids = []
            this.operateShow = false
        },
        // 删除
        delBtn(val) {
            this.delIndex = val
            this.$refs.point.openDialogBtn()
        },
        // 删除回调
        confirmBtn() {
            var arr = JSON.parse(JSON.stringify(this.sceneList))
            arr.splice(this.delIndex, 1);
            var params = {
                type: 'scen',
                info: arr
            }
            this.$store.dispatch('videoData/modifyProduct', params)
            if (this.delIndex == this.sceneIndex) {
                this.$store.commit('videoData/SET_SCENE_INDEX', 0)
            }
        },
        changeIndex(index) {
            this.$store.commit('videoData/SET_SCENE_INDEX', index)
        },
        // 复制场景
        sceneCopy(index) {
            var arr = JSON.parse(JSON.stringify(this.sceneList))
            var str = JSON.parse(JSON.stringify(arr[index]))
            str.id = new Date().getTime();
            arr.splice(index, 0, str);
            var params = {
                type: 'scen',
                info: arr
            }
            this.$store.dispatch('videoData/modifyProduct', params)
        },
        // 添加场景
        addSceneBtn() {
            var arr = JSON.parse(JSON.stringify(this.sceneList))
            let obj = {
                id: new Date().getTime(),
                data: {
                    content: "",
                    ttsAudio: ""
                },
                background: {
                    url: "",
                    type: "color",
                    color: "",
                    objectFit: "cover"
                },
                scenes_conf: []
            }
            if (this.sceneIndex == 0) {
                arr.push(obj)
            } else {
                arr.splice(this.sceneIndex + 1, 0, obj);
            }
            var params = {
                type: 'scen',
                info: arr
            }
            this.$store.dispatch('videoData/modifyProduct', params)
        },
    }
}
</script>
<style lang='scss' scoped>
.video_scene {
    position: relative;
    width: 100%;
    height: 100%;
    .scene_title {
        width: 100%;
        height: 60px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        img {
            display: block;
            width: 18px;
            height: 18px;
        }
        span {
            padding-left: 10px;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
        }
    }
    .scene_content {
        width: 100%;
        height: calc(100% - 130px);
        padding: 0 20px;
        overflow-y: auto;
    }
    .scene_content_scroll {
        height: calc(100% - 60px);
    }
    .scene_main {
        width: 100%;
        padding: 0 10px;
        .scene_upload {
            cursor: pointer;
            position: relative;
            width: 100%;
            background: #232930;
            border-radius: 4px;
            .cover_img {
                width: 100%;
                height: 100%;
            }
            .cover_btn {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($color: #000000, $alpha: 0.5);
                .img_del {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    width: 15px;
                    height: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .el-icon-delete {
                        color: #b7c1f3;
                    }
                }
                .cover_txt {
                    position: absolute;
                    left: 50%;
                    bottom: 12px;
                    width: 80px;
                    height: 26px;
                    margin-left: -40px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    background: linear-gradient(
                        90deg,
                        #4875ff 0%,
                        #2b4afe 100%
                    );
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .txt_icon {
                        display: block;
                        width: 12px;
                        height: 12px;
                        margin-left: 4px;
                    }
                }
            }
            .upload_img {
                padding-top: 14px;
                .upload_icon {
                    display: block;
                    width: 36px;
                    height: 36px;
                    margin: auto;
                }
            }
            .upload_img_top {
                padding-top: 5px;
                .upload_icon{
                    width: 25px;
                    height: 25px;
                }
            }
            .upload_txt {
                padding-top: 5px;
                width: 100%;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                .txt_icon {
                    display: block;
                    width: 12px;
                    height: 12px;
                    margin-left: 4px;
                }
            }
        }
        .scene_upload:hover {
            .cover_btn {
                display: block;
            }
        }
    }
    .scene_info_top {
        margin-top: 20px;
    }
    .scene_info {
        cursor: pointer;
        width: 100%;
        .info_title {
            width: 100%;
            padding: 15px 0 8px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
        }
        .info_con {
            width: 100%;
            padding: 0 10px;
            .con_data {
                position: relative;
                width: 100%;
                margin-top: 5px;
                background: url(~@/assets/images/createvideo/scene_background.png)
                    no-repeat;
                background-size: 100% 100%;
                border-radius: 6px;
                border: 2px solid #1b1d1f;
                overflow: hidden;
                .data_check {
                    position: absolute;
                    top: 6px;
                    right: 6px;
                    width: 14px;
                    height: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #979797;
                    background: #fff;
                    // ::v-deep .el-checkbox__inner {
                    //     border: 1px solid ;
                    // }
                    cursor: pointer;
                    .icon {
                        width: 14px;
                        height: 14px;
                    }
                }
                .data_btn {
                    cursor: pointer;
                    position: absolute;
                    top: 6px;
                    right: 6px;
                    width: 20px;
                    height: 20px;
                    background: rgba(0, 0, 0, 0.6);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .el-icon-more {
                        color: #fff;
                        font-size: 14px;
                    }
                }
            }
            .con_data_active {
                border: 2px solid #2b4afe;
            }
        }
        .info_btn {
            width: 100%;
            padding-top: 12px;
            display: flex;
            justify-content: center;
            .cut_to {
                cursor: pointer;
                padding: 0 10px;
                height: 28px;
                background: #353535;
                border-radius: 4px;
                align-items: center;
                display: flex;
                justify-content: center;
                img {
                    display: block;
                    width: 18px;
                    height: 18px;
                }
                span {
                    padding-left: 5px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                }
            }
        }
    }
    .scene_bottom {
        width: 100%;
        height: 70px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            display: flex;
            .left_btn {
                width: 130px;
                .btnBgColor_blue {
                    width: 100%;
                }
            }
            .left_input {
                width: 130px;
                color: #fff;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                display: flex;
                align-items: center;
                .el-checkbox {
                    margin-right: 5px;
                }
                .el-button {
                    margin-left: 15px;
                }
            }
        }
        .right {
            cursor: pointer;
            width: 30px;
            height: 30px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.tran_box {
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
}
.transition_animation {
    position: absolute;
    top: 50%;
    right: -265px;
    z-index: 12;
    width: 248px;
    height: 300px;
    padding: 0 20px;
    margin-top: -150px;
    background: #2b3036;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    border-radius: 10px;
    border: 1px solid #6e757e;
    .title {
        width: 100%;
        padding: 20px 0;
        text-align: center;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
    }
    .name {
        width: 100%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
    }
    .list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .list_info {
            cursor: pointer;
            margin-top: 17px;
            margin-right: 18px;
            img {
                display: block;
                width: 56px;
                height: 56px;
            }
            span {
                display: block;
                padding-top: 8px;
                width: 100%;
                text-align: center;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }
        }
        .list_info:nth-child(3n) {
            margin-right: 0;
        }
    }
}
</style>
<style lang="scss">
.tooltip_btn {
    padding: 5px 0;
    background: #fff !important;
    .popper__arrow {
        border-right-color: #fff !important;
    }
    .popper__arrow::after {
        border-right-color: #fff !important;
    }
    .t_btn {
        cursor: pointer;
        width: 60px;
        line-height: 25px;
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
    }
    .t_btn:hover {
        color: #2e4bf2;
    }
}
</style>
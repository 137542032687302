<template>
    <div class="">
        <div class="video_table" :class="!navInfo.navFlag ? 'video_table_close' : ''">
            <!-- 标题 -->
            <div class="table_title">
                <i class="el-icon-close" @click="close"></i>
                <div class="title">名片</div>
            </div>
            <div class="table_content">
                <div class="businesslist">
                    <div class="contentbox scroll_bar" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                        <div v-for="(ele, i) in list" :key="i">
                            <div class="list-title">
                                <div class="title">{{ ele.name }}</div>
                            </div>
                            <div class="list">
                                <div class="item" v-for="(item, x) in ele.materialCard" :key="x" @click="insert(item)">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapState
} from "vuex"
export default {
    components: {
        Loading: (resolve) => require(['@/components/loading/index'], resolve)
    },
    props: {
        navInfo: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            loading: false,
            list: [
                {
                    name: '个人信息',
                    materialCard: [
                        {
                            name: '插入姓名',
                            label: null,
                            value: {
                                "type": "text",
                                "value": "#1~姓名",
                                "content": "姓名",
                                "lock": false,
                                "style": {
                                    "left": 87,
                                    "top": 130,
                                    "width": 1121,
                                    "height": 121,
                                    "rotation": 0
                                },
                                "textStyle": {
                                    "fontFamily": "SourceHanSerifCN-Regular",
                                    "fontSize": 80,
                                    "color": "#FFFFFF",
                                    "fontWeight": "normal",
                                    "fontStyle": "normal",
                                    "textAlign": "left",
                                    "lineHeight": 1.5,
                                    "letterSpacing": 0
                                }
                            },
                        },
                    ]
                },
                {
                    name: '联系信息',
                    materialCard: [
                        {
                            name: '插入手机',
                            label: {
                                "type": "text",
                                "value": "手机",
                                "content": "手机：",
                                "lock": false,
                                "style": {
                                    "left": 87,
                                    "top": 650,
                                    "width": 200,
                                    "height": 90,
                                    "rotation": 0
                                },
                                "textStyle": {
                                    "fontFamily": "fangzhengheiti",
                                    "fontSize": 60,
                                    "color": "#FFFFFF",
                                    "fontWeight": "normal",
                                    "fontStyle": "normal",
                                    "textAlign": "left",
                                    "lineHeight": 1.5,
                                    "letterSpacing": 0
                                }
                            },
                            value: {
                                "type": "text",
                                "value": "#2~手机",
                                "content": "手机号",
                                "lock": false,
                                "style": {
                                    "left": 270,
                                    "top": 650,
                                    "width": 1007,
                                    "height": 90,
                                    "rotation": 0
                                },
                                "textStyle": {
                                    "fontFamily": "fangzhengheiti",
                                    "fontSize": 60,
                                    "color": "#FFFFFF",
                                    "fontWeight": "normal",
                                    "fontStyle": "normal",
                                    "textAlign": "left",
                                    "lineHeight": 1.5,
                                    "letterSpacing": 0
                                }
                            }
                        },
                        {
                            name: '插入电话',
                            label: {
                                "type": "text",
                                "value": "电话",
                                "content": "电话：",
                                "lock": false,
                                "style": {
                                    "left": 87,
                                    "top": 734,
                                    "width": 200,
                                    "height": 90,
                                    "rotation": 0
                                },
                                "textStyle": {
                                    "fontFamily": "fangzhengheiti",
                                    "fontSize": 60,
                                    "color": "#FFFFFF",
                                    "fontWeight": "normal",
                                    "fontStyle": "normal",
                                    "textAlign": "left",
                                    "lineHeight": 1.5,
                                    "letterSpacing": 0
                                }
                            },
                            value: {
                                "type": "text",
                                "value": "#2~电话",
                                "content": "电话号",
                                "lock": false,
                                "style": {
                                    "left": 270,
                                    "top": 734,
                                    "width": 1007,
                                    "height": 90,
                                    "rotation": 0
                                },
                                "textStyle": {
                                    "fontFamily": "fangzhengheiti",
                                    "fontSize": 60,
                                    "color": "#FFFFFF",
                                    "fontWeight": "normal",
                                    "fontStyle": "normal",
                                    "textAlign": "left",
                                    "lineHeight": 1.5,
                                    "letterSpacing": 0
                                }
                            }
                        },
                        {
                            name: '插入邮箱',
                            label: {
                                "type": "text",
                                "value": "邮箱",
                                "content": "邮箱：",
                                "lock": false,
                                "style": {
                                    "left": 87,
                                    "top": 820,
                                    "width": 202,
                                    "height": 90,
                                    "rotation": 0
                                },
                                "textStyle": {
                                    "fontFamily": "fangzhengheiti",
                                    "fontSize": 60,
                                    "color": "#FFFFFF",
                                    "fontWeight": "normal",
                                    "fontStyle": "normal",
                                    "textAlign": "left",
                                    "lineHeight": 1.5,
                                    "letterSpacing": 0
                                }
                            },
                            value: {
                                "type": "text",
                                "value": "#2~邮箱",
                                "content": "邮箱",
                                "lock": false,
                                "style": {
                                    "left": 270,
                                    "top": 820,
                                    "width": 1122,
                                    "height": 90,
                                    "rotation": 0
                                },
                                "textStyle": {
                                    "fontFamily": "fangzhengheiti",
                                    "fontSize": 60,
                                    "color": "#FFFFFF",
                                    "fontWeight": "normal",
                                    "fontStyle": "normal",
                                    "textAlign": "left",
                                    "lineHeight": 1.5,
                                    "letterSpacing": 0
                                }
                            }
                        },
                    ]
                },
                {
                    name: '公司信息',
                    materialCard: [
                        {
                            name: '插入公司',
                            label: null,
                            value: {
                                "type": "text",
                                "value": "#3~公司",
                                "content": "公司名称",
                                "lock": false,
                                "style": {
                                    "left": 87,
                                    "top": 285,
                                    "width": 1165,
                                    "height": 90,
                                    "rotation": 0
                                },
                                "textStyle": {
                                    "fontFamily": "SourceHanSerifCN-Regular",
                                    "fontSize": 60,
                                    "color": "#FFFFFF",
                                    "fontWeight": "normal",
                                    "fontStyle": "normal",
                                    "textAlign": "left",
                                    "lineHeight": 1.5,
                                    "letterSpacing": 0
                                }
                            }
                        },
                        {
                            name: '插入地址',
                            label: {
                                "type": "text",
                                "value": "地址",
                                "content": "地址：",
                                "lock": false,
                                "style": {
                                    "left": 87,
                                    "top": 905,
                                    "width": 202,
                                    "height": 90,
                                    "rotation": 0
                                },
                                "textStyle": {
                                    "fontFamily": "fangzhengheiti",
                                    "fontSize": 60,
                                    "color": "#FFFFFF",
                                    "fontWeight": "normal",
                                    "fontStyle": "normal",
                                    "textAlign": "left",
                                    "lineHeight": 1.5,
                                    "letterSpacing": 0
                                }
                            },
                            value: {
                                "type": "text",
                                "value": "#3~地址",
                                "content": "公司地址",
                                "lock": false,
                                "style": {
                                    "left": 260,
                                    "top": 905,
                                    "width": 1258,
                                    "height": 90,
                                    "rotation": 0
                                },
                                "textStyle": {
                                    "fontFamily": "fangzhengheiti",
                                    "fontSize": 60,
                                    "color": "#FFFFFF",
                                    "fontWeight": "normal",
                                    "fontStyle": "normal",
                                    "textAlign": "left",
                                    "lineHeight": 1.5,
                                    "letterSpacing": 0
                                }
                            }
                        },
                        {
                            name: '插入Logo',
                            label: null,
                            value: {
                                "name": "#3~3~logo.png",
                                "value": "#3~logo",
                                "type": "image",
                                "url": "https://yc-digital.oss-cn-shenzhen.aliyuncs.com/image/20231114/gTmWotOqtsup-JSgFf.png",
                                "style": {
                                    "left": 1214,
                                    "top": 840,
                                    "width": 565,
                                    "height": 180,
                                    "rotation": 0
                                },
                                "file": "",
                                "lock": false
                            }
                        },
                        {
                            name: '插入职位',
                            label: null,
                            value: {
                                "type": "text",
                                "value": "#3~职位",
                                "content": "职位",
                                "lock": false,
                                "style": {
                                    "left": 87,
                                    "top": 383,
                                    "width": 1204,
                                    "height": 90,
                                    "rotation": 0
                                },
                                "textStyle": {
                                    "fontFamily": "SourceHanSerifCN-Regular",
                                    "fontSize": 60,
                                    "color": "#FFFFFF",
                                    "fontWeight": "normal",
                                    "fontStyle": "normal",
                                    "textAlign": "left",
                                    "lineHeight": 1.5,
                                    "letterSpacing": 0
                                }
                            }
                        },
                    ]
                },
            ],
        }
    },
    created() {

    },
    computed: {
        ...mapState({
            currentTime: state => state.videoData.currentTime,//当前播放时间
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            duration: state => state.videoData.duration, //音频总时长
        }),
    },
    methods: {
        insert(val) {
            var that = this
            var arr = JSON.parse(JSON.stringify(that.sceneList))
            var idx = that.sceneIndex
            var scenes = arr[idx].scenes_conf || []

            let value = val.value
            value.id = new Date().getTime()
            value.start = 0
            value.end = that.duration * 1000
            scenes.push(value)

            if (val.label) {
                let label = val.label
                label.id = new Date().getTime() + 10
                label.start = 0
                label.end = that.duration * 1000
                scenes.push(label)
            }

            var params = {
                type: 'conf',
                info: scenes
            }
            that.$store.dispatch('videoData/modifyProduct', params)
            // 插入后选中
            that.$store.commit('videoData/SET_SHOW_ID', value.id)
        },
        // 关闭
        close() {
            let obj = {
                ...this.navInfo,
                navFlag: false,
            }

            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', obj)

        },


    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";

.contentbox {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 320px);
    margin-right: -8px;
}

.add-btn {
    width: 306px;
    height: 38px;
    background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
    text-align: center;
    cursor: pointer;
}
</style>
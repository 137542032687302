<template>
    <div class="editor_video">
        <create-audio v-if="current==1" @confirm="confirm"></create-audio>
        <create-video v-if="current==2" @confirm="confirm"></create-video>
    </div>
</template>

<script>
import CreateAudio from './editTool/createAudio/index'  //底部创建音频
import CreateVideo from './editTool/createVideo/index'  //底部编辑视频
import { mapState } from "vuex"
export default {
    components: {
        CreateAudio,
        CreateVideo
    },
    props: {

    },
    data() {
        return {

        };
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,
            sceneIndex: state => state.videoData.sceneIndex,
            default_tts: state => state.voiceInfo.default_tts,
            ttsType: state => state.voiceInfo.ttsType,
            current: state => state.voiceInfo.current,
        }),
        info() {
            return this.sceneList[this.sceneIndex]?.data
        },
    },
    created() {
        this.getParams()
    },
    mounted() {

    },
    watch: {
        info(val) {
            if (val.ttsAudio) {
                this.$store.commit('voiceInfo/SET_CURRENT', 2)
            } else {
                this.$store.commit('voiceInfo/SET_CURRENT', 1)
            }
        },
        ttsType(val) {
            if (this.current == 2) {
                if (val == 1) {
                    this.$store.commit('voiceInfo/SET_CURRENT', 1)
                }
                if (val == 2 && this.info.url != '') {
                    this.$store.commit('voiceInfo/SET_CURRENT', 2)
                }
            }
        },
    },
    methods: {
        confirm(val) {
            this.$store.commit('voiceInfo/SET_CURRENT', val.current)
            if (val.current == 2) {
                let obj = {
                    ttsAudio: val.url,
                    duration: val.duration,
                    content: val.content,
                    subtitleList: [],
                    label: this.default_tts.label,
                    speed_rate: this.default_tts.speed,
                    volume: this.default_tts.volume,
                    pitch: this.default_tts.intonation,
                    sample_rate: this.default_tts.rate,
                    id: this.default_tts.id,
                    name: val.name ? val.name : this.default_tts.name,
                    ttsType: this.ttsType
                }
                this.$store.commit('videoData/SET_SECNE_DATA', obj)
            }
        },
        // 获取配置
        getParams() {
            let obj = {
                id: 128,
            }
            this.$digitalApi.getTtsParams(obj).then(res => {
                if (res.code == 1000) {
                    let arr = res.result.tts_list
                    arr.forEach(ele => {
                        ele.show = false
                    })
                    this.$store.commit('voiceInfo/SET_DEFAULT_TTS', res.result.default_tts)
                    this.$store.commit('voiceInfo/SET_SAMPLE_RATE', res.result.sample_rate)
                    this.$store.commit('voiceInfo/SET_LABELS', res.result.labels)
                    this.$store.commit('voiceInfo/SET_TTS_LIST', arr)
                }
            })
        },
    },

};
</script>

<style scoped lang="scss">
.editor_video {
    width: 100%;
    height: 100%;
    background: #101314;
    border-radius: 10px;
    user-select: none;
}
</style>

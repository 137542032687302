<!-- @Author: Yu_Bo 2023-10-12 14:51:02-->
<template>
    <div class="video_top">
        <!-- 撤销/返回 -->
        <div class="top_retreat">
            <img src="@/assets/images/createvideo/top_left_no.png" alt="" v-if="stepList.length==0 || stepList.length==1 || step==1">
            <img src="@/assets/images/createvideo/top_left.png" alt="" v-else @click="prev">
            <img src="@/assets/images/createvideo/top_right_no.png" alt="" v-if="stepList.length == step || stepList.length==0 || stepList.length==1">
            <img src="@/assets/images/createvideo/top_right.png" alt="" v-else @click="next">
        </div>
        <!-- 缩放 -->
        <el-popover popper-class="scale_popover" placement="bottom" width="100" trigger="click">
            <div class="scale_box">
                <div class="box_btn">
                    <img @click="scaleReduce" src="@/assets/images/createvideo/scale_jian.png" alt="">
                    <span>{{ scale + '%' }}</span>
                    <img @click="scaleAdd" src="@/assets/images/createvideo/scale_jia.png" alt="">
                </div>
                <div class="box_txt" @click="adaptiveScreen">
                    <img src="@/assets/images/createvideo/scale_icon1.png" alt="">
                    <span>适应屏幕</span>
                </div>
                <div class="box_txt" @click="scaleOriginal">
                    <img src="@/assets/images/createvideo/scale_icon2.png" alt="">
                    <span>原始尺寸</span>
                </div>
            </div>
            <div class="top_scale" slot="reference">{{ scale + '%' }}</div>
        </el-popover>
        <!-- 音频 -->
        <div class="top_audio" v-show="sounds.url">
            <!-- 开关 -->
            <img class="img" v-if="paused" src="@/assets/images/createvideo/top_play.png" alt="" @click="handlePauseOrPlay">
            <img class="img" v-else src="@/assets/images/createvideo/top_pause.png" alt="" @click="handlePauseOrPlay">
            <!-- 音量 -->
            <el-popover popper-class="suond_popover" placement="bottom" width="24" trigger="click">
                <div class="suond_box">
                    <el-slider v-model="sounds.volume" vertical height="100px" :show-tooltip="false" :step="0.01" :max="1" @input="changeVolume"></el-slider>
                </div>
                <img class="suond" slot="reference" src="@/assets/images/createvideo/top_soud.png" alt="">
            </el-popover>
            <!-- 分割线 -->
            <el-divider direction="vertical"></el-divider>
            <!-- 音频名称 -->
            <span class="span one-txt-cut">{{sounds.name}}</span>
            <!-- 删除 -->
            <img class="close" src="@/assets/images/createvideo/top_close.png" alt="" @click="delMusic">
        </div>
        <!-- 图标 -->
        <div class="top_retreat">
            <!-- 滤镜 -->
            <!-- <img src="@/assets/images/createvideo/top_filter.png" alt=""> -->
            <!-- 镜像 -->
            <img v-if="jxShow" src="@/assets/images/createvideo/top_mirror.png" alt="" @click="addFlipSections">
        </div>
        <audio @canplay="getDuration" controls loop v-show="false" ref="audio" :src="sounds.url" />

        <!-- 提示框 -->
        <point-tip ref='pointTip' @confirm='confirmPoin' :text='tip_text' :cancelShow='true' btnText='确 定'></point-tip>
    </div>
</template>

<script>
import { mapState } from "vuex"
import PointTip from '@/components/point_out/point_tip.vue'//提示弹框
export default {
    components: {
        PointTip,
    },
    props: {},
    data() {
        return {
            step: null,
            audio: null,
            paused: true,   //是否暂停
            tip_text: '确定要删除背景音乐吗?',
        };
    },

    computed: {
        ...mapState({
            scaleRatio: state => state.videoCreate.scaleRatio,
            stepList: state => state.videoData.stepList || [],  //保存步骤 最多5步

            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            duration: state => state.videoData.duration,
            current: state => state.voiceInfo.current,
        }),
        scale() {
            if (this.scaleRatio) {
                var num = Math.ceil(this.scaleRatio * 100)
                return num
            } else {
                return 0
            }
        },
        sounds() {
            if (this.sceneList.length) {
                return this.sceneList[this.sceneIndex].sounds || {}
            } else {
                return {}
            }
        },
        // 有模特才能添加镜像
        jxShow() {
            let arr = this.sceneList[this.sceneIndex].scenes_conf || []
            let a = arr.find(item => item.type == 'dthuman')
            if (a) {
                return true
            } else {
                return false
            }
        },
    },

    watch: {
        stepList(val) {
            this.step = val.length
        },
        sounds() {
            this.paused = true
        },
    },

    created() { },

    mounted() { },

    methods: {
        // 添加镜像效果
        addFlipSections() {
            console.log(this.current)
            if (this.current == 1) {
                return
            }
            let arr = JSON.parse(JSON.stringify(this.sceneList))
            let str = arr[this.sceneIndex]
            let flipSections = []
            if (!str.flipSections || str.flipSections.length == 0) {
                flipSections.push({
                    start: 0,
                    end: 2,
                })
            } else {
                flipSections = str.flipSections
                let obj = str.flipSections[str.flipSections.length - 1]
                let end = obj.end
                if (end >= this.duration) {
                    return
                }
                flipSections.push({
                    start: end,
                    end: end + 2,
                })
            }
            arr[this.sceneIndex].flipSections = flipSections
            var params = {
                type: 'scen',
                info: arr
            }
            // 修改数据
            this.$store.dispatch('videoData/modifyProduct', params)
            this.$succMsg('镜像效果添加成功')
        },
        // 删除背景音乐
        delMusic() {
            this.$refs.pointTip.openDialog()
        },
        // 确认删除
        confirmPoin() {
            let num = this.sounds.volume
            let obj = {
                name: '',
                url: '',
                volume: num,
                duration: '',
            }
            var params = {
                type: 'sounds',
                info: obj
            }
            // 修改数据
            this.$store.dispatch('videoData/modifyProduct', params)
        },
        //暂停或播放
        handlePauseOrPlay() {
            this.audio.paused ? this.audio.play() : this.audio.pause();
            this.paused = !this.paused;
        },
        //视频在可以播放时触发
        getDuration() {
            this.audio = this.$refs.audio;
            this.audio.volume = this.sounds.volume
        },
        // 修改声音
        changeVolume(val) {
            if (this.audio) {
                this.audio.volume = val
            }
        },
        // 上一步
        prev() {
            this.step--
            let scenes = this.stepList[this.step - 1]
            var params = {
                type: 'conf',
                info: scenes,
                disable: true
            }
            this.$store.dispatch('videoData/modifyProduct', params)
            this.$store.commit('videoData/SET_SHOW_ID', null)
        },
        // 下一步
        next() {
            this.step++
            let scenes = this.stepList[this.step - 1]
            var params = {
                type: 'conf',
                info: scenes,
                disable: true
            }
            this.$store.dispatch('videoData/modifyProduct', params)
        },
        // 缩放比例 -
        scaleReduce() {
            var num = (this.scale - 5) / 100
            this.$store.commit('videoCreate/SET_SCALE_RATIO', num)
            setTimeout(() => {
                this.$emit('scroll')
            }, 10)
        },
        // 缩放比例 +
        scaleAdd() {
            var num = (this.scale + 5) / 100
            this.$store.commit('videoCreate/SET_SCALE_RATIO', num)
            setTimeout(() => {
                this.$emit('scroll')
            }, 10)
        },
        // 自适应屏幕
        adaptiveScreen() {
            this.$emit('self')
        },
        // 原始尺寸
        scaleOriginal() {
            this.$store.commit('videoCreate/SET_SCALE_RATIO', 1)
            setTimeout(() => {
                this.$emit('scroll')
            }, 10)
        },
    }
}
</script>
<style lang='scss' scoped>
.video_top {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .top_ratio {
        cursor: pointer;
        position: relative;
        padding: 0 10px;
        height: 32px;
        background: #2b3036;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            display: block;
            width: 16px;
            height: 16px;
        }
        span {
            padding-left: 5px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
        }
    }
    .top_retreat {
        display: flex;
        align-items: center;
        img {
            cursor: pointer;
            display: block;
            width: 32px;
            height: 32px;
            margin-left: 10px;
        }
    }
    .top_scale {
        cursor: pointer;
        padding: 0 10px;
        height: 32px;
        margin-left: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        background: #2b3036;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .top_audio {
        position: relative;
        margin-left: 10px;
        max-width: 200px;
        height: 32px;
        padding: 0 12px;
        background: #2b3036;
        border-radius: 4px;
        display: flex;
        align-items: center;
        .img {
            cursor: pointer;
            display: block;
            width: 14px;
            height: 14px;
        }
        .suond {
            cursor: pointer;
            display: block;
            width: 14px;
            height: 14px;
            margin-left: 12px;
        }
        .el-divider {
            margin: 0 12px;
            background-color: #666;
        }
        .span {
            display: block;
            max-width: 110px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
        }
        .close {
            position: absolute;
            top: -5px;
            right: -5px;
            cursor: pointer;
            display: block;
            width: 12px;
            height: 12px;
        }
    }
}
</style>
<style lang="scss">
.top_popover {
    min-width: 70px;
    padding: 0;
    background: #2b3036;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    border: 1px solid #2b3036;
    .popper__arrow {
        border-bottom-color: #2b3036 !important;
    }
    .popper__arrow::after {
        border-bottom-color: #2b3036 !important;
    }
    .ratio_box {
        width: 100%;
        padding: 5px 0;
        .box_ratio {
            cursor: pointer;
            position: relative;
            width: 100%;
            height: 32px;
            background: #2b3036;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                display: block;
                width: 16px;
                height: 16px;
            }
            span {
                padding-left: 5px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }
        }
    }
}
.scale_popover {
    min-width: 100px;
    padding: 0;
    background: #2b3036;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    border: 1px solid #2b3036;
    .popper__arrow {
        border-bottom-color: #2b3036 !important;
    }
    .popper__arrow::after {
        border-bottom-color: #2b3036 !important;
    }
    .scale_box {
        width: 100%;
        padding: 5px 0;
        .box_btn {
            position: relative;
            width: 100%;
            height: 32px;
            background: #2b3036;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                cursor: pointer;
                display: block;
                width: 14px;
                height: 14px;
            }
            span {
                display: block;
                width: 45px;
                margin: 0 5px;
                text-align: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }
        }
        .box_txt {
            cursor: pointer;
            position: relative;
            width: 100%;
            height: 32px;
            background: #2b3036;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                display: block;
                width: 14px;
                height: 14px;
            }
            span {
                padding-left: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }
        }
        .box_txt:hover {
            background: #3c4148;
            border-radius: 0;
        }
    }
}
.suond_popover {
    min-width: 24px;
    padding: 0;
    background: #2b3036;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    border: 1px solid #2b3036;
    .popper__arrow {
        left: 5px !important;
        border-bottom-color: #2b3036 !important;
    }
    .popper__arrow::after {
        border-bottom-color: #2b3036 !important;
    }
    .suond_box {
        width: 100%;
        height: 120px;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-slider {
            .el-slider__runway {
                margin: 0;
            }
        }
    }
}
</style>
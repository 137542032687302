<template>
    <div class="">
        <div class="video_table" :class="!navInfo.navFlag ? 'video_table_close' : ''">
            <!-- 标题 -->
            <div class="table_title">
                <i class="el-icon-close" @click="close"></i>
                <div class="title">设置</div>
            </div>

            <!-- 内容 -->
            <SetCon1 v-if="layerName == 'background'"></SetCon1>
            <SetCon2 v-else :type="layerName"></SetCon2>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    components: {
        SetCon1: resolve => require(['@/components/video_right/components/setCon1.vue'], resolve),
        SetCon2: resolve => require(['@/components/video_right/components/setCon2.vue'], resolve),
    },
    props: {
        navInfo: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            layerName: 'background',
            // layerName: 'text',
            // layerName: 'video',
            // layerName: 'image',
            // layerName: 'subtitle',
        }
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            showId: state => state.videoData.showId, //选中素材id
        }),
    },
    watch: {
        showId(val) {
            this.getName(val)
        },
    },
    mounted() {
        this.getName(this.showId)
    },
    methods: {
        getName(val) {
            let arr = this.sceneList[this.sceneIndex].scenes_conf
            let ind = arr.findIndex(ele => ele.id == val)
            if (ind == -1) {
                this.layerName = 'background'
            } else {
                let name = this.sceneList[this.sceneIndex].scenes_conf[ind].type
                if (name == 'audio') {
                    this.layerName = 'background'
                } else {
                    this.layerName = name
                }
            }
        },
        // 关闭
        close() {
            let obj = {
                ...this.navInfo,
                navFlag: false,
            }

            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', obj)
        },
    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>